<template>
    <div>
        <div v-loading.fullscreen.lock="loading">
            <el-row style="padding: 16px">
                <el-col :span="18" style="padding-left: 16px">
                    <el-card style="width:100%">
                        <div>
                            <TextWithChoises v-bind:corzina="cartTransfer" @input="updateData($event)"/>
                        </div>
                    </el-card>
                </el-col>
                <el-col :span="6">
                    <el-card style="width:100%; text-align: start; padding-left: 16px ">
                        <h4>
                            {{ $t("Завершить кейс и отправить ответ") }}
                        </h4>
                        <hr/>
                        <el-row style="margin-top: 8px">
                            <el-button type="success" @click="finishCase">{{ $t("Отправить") }}<i
                                    class="fas fa-check"
                                    style="margin-left: 4px"></i>
                            </el-button>
                        </el-row>
                    </el-card>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
    import CaseSessionController from "../../controllers/caseSession.controller";
    import TextWithChoises from "./TextWithChoises";
    import OntologyController from "../../controllers/ontology.controller";
    import CashController from "../../controllers/cash.controller";

    export default {
        name: 'NewCase',
        components: {
            TextWithChoises
        },
        props: {},
        data() {
            return {
                pickedNodes: [],
                answerContent: '',
                cartTransfer: [],
                form: {
                    aims: {},
                    name: ''
                },
                ontology: null,
                addFromCartDialog: false,
                loading: false,
                aims : {},
                caseSessionController: null,
                ontologyController: null,
                cashController: null
            }
        },
        computed: {},
        beforeMount() {

            this.caseSessionController = new CaseSessionController()
            this.ontologyController = new OntologyController()
            this.cashController = new CashController()
            let cash = this.cashController.getCashStore()
            console.log('кэш в ответе', this.cartTransfer)
            this.aims = cash.aims



            this.cartTransfer = cash.cart
            console.log('корзина в овтете, структура', this.cartTransfer)
            this.ontology = this.ontologyController.getOntology()

        },
        mounted() {
        },
        methods: {
            updateData(event) {
                this.aims = event
                let cash = this.cashController.getCashStore()
                cash.aims = event
                this.cashController.setCashStore(cash)
            },
            async finishCase() {
                console.log('отправил ответ')
                this.caseSessionController.addLogStore('answer', 'Отправил ответ')
                this.caseSessionController.addAnswer({html: this.answerContent, aims: this.aims});
                let currentSession = this.caseSessionController.getCurrentSessionStore();
                this.caseSessionController.updateCaseSession(currentSession);
                //
                this.caseSessionController.deleteSessionByIdStore(currentSession._id);
                this.caseSessionController.setCurrentSessionStore(null);
                //
                this.$emit('login');
                this.$router.push('/');
            }
        },
    };
</script>

<style scoped>
* >>> .el-transfer-panel {
        width: 300px;
    }


</style>
