import Scheme from '../models/scheme'
import {store} from '../store/index'
import axios from "axios";
import config from "../../config";
import authHeader from "./auth-header";

const API_URL = config.API+'/shemes/';

class SchemeService {
    addScheme(data) {
        if (store.getters['sessionStore/getCurrentSession'].adminSession){
           data['adminStatus'] = store.state.auth.user.roles.includes('ROLE_ADMIN')
        }
        data['sessionId'] = store.getters['sessionStore/getCurrentSession']._id
        data['caseId'] = store.getters['sessionStore/getCurrentSession'].caseId
        data['ontologyId'] = store.getters['ontologyStore/getOntologyStore'].id
        data['caseNumberToHide'] = store.getters['sessionStore/getCurrentSession'].caseNumberToHide

        return axios.post(API_URL + 'addScheme', data, { headers: authHeader() });
    }

    changeScheme(data) {
        data['sessionId'] = store.getters['sessionStore/getCurrentSession']._id
        data['caseId'] = store.getters['sessionStore/getCurrentSession'].caseId
        data['ontologyId'] = store.getters['ontologyStore/getOntologyStore'].id
      data['caseNumberToHide'] = store.getters['sessionStore/getCurrentSession'].caseNumberToHide

        return axios.post(API_URL + 'saveScheme', data, { headers: authHeader() });
    }

    visScheme(data) {
        data['sessionId'] = store.getters['sessionStore/getCurrentSession']._id
        data['caseId'] = store.getters['sessionStore/getCurrentSession'].caseId
        data['ontologyId'] = store.getters['ontologyStore/getOntologyStore'].id
        data['caseNumberToHide'] = store.getters['sessionStore/getCurrentSession'].caseNumberToHide

        return axios.post(API_URL + 'visScheme', data, { headers: authHeader() });
    }

    deleteScheme(data) {
        data['sessionId'] = store.getters['sessionStore/getCurrentSession']._id
        data['caseId'] = store.getters['sessionStore/getCurrentSession'].caseId
        data['ontologyId'] = store.getters['ontologyStore/getOntologyStore'].id

        return axios.post(API_URL + 'deleteScheme', data, { headers: authHeader() });
    }

    loadAllSchemes() {
        let data = {}
        data['adminStatus'] = store.state.auth.user.roles.includes('ROLE_ADMIN')
        data['sessionId'] = store.getters['sessionStore/getCurrentSession']._id
        data['caseId'] = store.getters['sessionStore/getCurrentSession'].caseId
        data['ontologyId'] = store.getters['ontologyStore/getOntologyStore'].id
        return axios.post(API_URL + 'loadSchemes',data, { headers: authHeader() });
    }


    createSchemeStore(links, nodes, attributes, values, operators, logics, type) {
        let scheme = new Scheme(links, nodes, attributes, values, operators, logics)
        if (type === 'constructor') store.commit('schemeStore/setConstructorScheme', scheme)
        else if (type === 'vis') store.commit('schemeStore/setVisScheme', scheme)
    }


    newScheme(links, nodes, attributes, values, operators, logics) {
        return new Scheme(links, nodes, attributes, values, operators, logics)
    }

    setSchemeStore(links, nodes, attributes, values, operators, logics, functions, type) {
        let scheme = this.getSchemeStore(type)
        scheme.links = links
        scheme.attributes = attributes
        scheme.values = values
        scheme.nodes = nodes
        scheme.operators = operators
        scheme.logics = logics
        scheme.functions = functions
    }

    getSchemeStore(type) {
        if (type === 'constructor') return store.getters['schemeStore/getConstructorScheme']
        else if (type === 'vis') return store.getters['schemeStore/getVisScheme']
    }

    loadLinks(links, nodes, attributes, values, operators, logics, functions) {
        let newLinks = []
        for (let link of links) {
            let l = {
                element: link.element,
                name: link.name,
                short: link.short,
                sameLink: link.sameLink,
                source: (function () {
                    if (link.element === 'link-attribute') {
                        for (let i = 0; i < links.length; i++) {
                            if (links[i].index === link.source.index) return links[i]
                        }
                    }
                    else if (link.source.element === 'ontology'){
                        for (let i = 0; i < nodes.length; i++) {
                            if (nodes[i].index === link.source.index) return nodes[i]
                        }
                    }
                    else if (link.source.element === 'attribute') {
                        for (let i = 0; i < attributes.length; i++) {
                            if (attributes[i].index === link.source.index) return attributes[i]
                        }
                    }
                    else if (link.source.element === 'value') {
                        for (let i = 0; i < values.length; i++) {
                            if (values[i].index === link.source.index) return values[i]
                        }
                    }
                    else if (link.source.element === 'operator'){
                        for (let i = 0; i < operators.length; i++) {
                            if (operators[i].index === link.source.index) return operators[i]
                        }
                    }
                    else if (link.source.element === 'logic') {
                        for (let i = 0; i < logics.length; i++) {
                            if (logics[i].index === link.source.index) return logics[i]
                        }
                    }
                    else if (link.source.element === 'function') {
                        for (let i = 0; i < functions.length; i++) {
                            if (functions[i].index === link.source.index) return functions[i]
                        }
                    }
                })(),
                target: (function () {
                    if (link.target.element === 'ontology'){
                        for (let i = 0; i < nodes.length; i++) {
                            if (nodes[i].index === link.target.index) return nodes[i]
                        }
                    }
                    else if (link.target.element === 'attribute') {
                        for (let i = 0; i < attributes.length; i++) {
                            if (attributes[i].index === link.target.index) return attributes[i]
                        }
                    }
                    else if (link.target.element === 'value') {
                        for (let i = 0; i < values.length; i++) {
                            if (values[i].index === link.target.index) return values[i]
                        }
                    }
                    else if (link.target.element === 'operator'){
                        for (let i = 0; i < operators.length; i++) {
                            if (operators[i].index === link.target.index) return operators[i]
                        }
                    }
                    else if (link.target.element === 'logic') {
                        for (let i = 0; i < logics.length; i++) {
                            if (logics[i].index === link.target.index) return logics[i]
                        }
                    }
                    else if (link.target.element === 'function') {
                        for (let i = 0; i < functions.length; i++) {
                            if (functions[i].index === link.target.index) return functions[i]
                        }
                    }
                })(),
                linkType: link.linkType,
                directed: link.directed,
                sourceD: link.sourceD,
                counter: link.counter,
                targetD: link.targetD,
                sourcePart: link.sourcePart,
                targetPart: link.targetPart,
                label: link.label,
                form: link.form
            }
            newLinks.push(l)
        }

        return newLinks
    }

    findValueByOperator (operator, links, scheme) {
        let values = []
        for (let link of links) {
            if (scheme.compareNodes(operator, link.source) &&
                link.target.element === 'value') {
                if (link.target.name === 'Number') values.push({value: link.target.value, type: 'value', name: 'Number'})
                else if (link.target.name === 'Date') values.push({value: link.target.value, type: 'value', name: 'Date'})
                else values.push({value: link.target.value.replace(/'/g, "\\'"), type: 'value', name: 'String'})
            }
            else if (scheme.compareNodes(operator, link.target) &&
                link.source.element === 'value') {
                if (link.source.name === 'Number') values.push({value: link.source.value, type: 'value', name: 'Number'})
                else if (link.source.name === 'Date') values.push({value: link.source.value, type: 'value', name: 'Date'})
                else values.push({value: link.source.value.replace(/'/g, "\\'"), type: 'value', name: 'String'})
            }
        }
        for (let link of links) {
            if (scheme.compareNodes(operator, link.source) && link.target.element === 'attribute' &&
                link.targetPart === 'in'){
                let attr = link.target
                let ontology = this.findNodeByAttribute(attr, links, scheme)
                values.push({value: ontology.short + '.' + attr.attribute.name, type: 'attribute', ontology: ontology})
            }
        }
        for (let link of links) {
            if (scheme.compareNodes(operator, link.source) && link.target.element === 'function' &&
                link.targetPart === 'out'){
                values.push({value: this.throughFunctions(link.target, scheme), type: 'function'})
            }
        }
        return values
    }

    findAttrByOperator (operator, links, scheme) {
        for (let link of links) {
            if (scheme.compareNodes(operator, link.target) && link.source.element === 'attribute') return link.source
        }
        return null
    }

    findFunctionByOperator (operator, links, scheme) {
        for (let link of links) {
            if (scheme.compareNodes(operator, link.target) && link.source.element === 'function') return link.source
        }
        return null
    }

    findNodeByAttribute (attr, links, scheme) {
        for (let link of links) {
            if (scheme.compareNodes(attr, link.source) && link.target.element === 'ontology') return link.target
            else if (scheme.compareNodes(attr, link.target) && link.source.element === 'ontology') return link.source
            else if (scheme.compareNodes(attr, link.source) && link.element === 'link-attribute') return link.target
            else if (scheme.compareNodes(attr, link.target) && link.element === 'link-attribute') return link.source
        }
        return null
    }

    notOperator (operator) {
        let pairs = [['=', '<>'], ['>', '<='], ['<', '>=']]
        for (let pair of pairs) {
            if (pair.includes(operator)) {
                if (pair.indexOf(operator) === 0) return pair[1]
                else return pair[0]
            }
        }
        return null
    }

    throughFunctions(func, scheme) {
        let array = []
        let nodes = []
        let links = []
        for (let link of scheme.links) {
            if (scheme.compareNodes(func, link.target)) {
                if (link.source.element === 'attribute') {
                    let ontology = this.findNodeByAttribute(link.source, scheme.links, scheme)
                    if (ontology.element === 'ontology') {
                        if (nodes.indexOf(ontology.index) < 0) nodes.push(ontology.index)
                    }
                    if (ontology.element === 'link') {
                        if (links.indexOf(ontology.index) < 0) links.push(ontology.index)
                    }
                    let data = ontology.short + '[\'' + link.source.attribute.name + '\']'
                    if (link.source.attribute.name === 'neoID') data = 'ID(' + ontology.short + ') '
                    array.push({type: 'attribute', input: link.targetPart, data: data})
                } else if (link.source.element === 'function') {
                    let full = this.throughFunctions(link.source, scheme)
                    for (let node of full.nodes) {
                        if (nodes.indexOf(node) < 0) nodes.push(node)
                    }
                    for (let link of full.links) {
                        if (links.indexOf(link) < 0) nodes.push(link)
                    }
                    array.push({type: 'function', input: link.targetPart, data: full.array})
                }
            }
        }
        return {
            expression: this.createSimpleFunction(func, array),
            nodes: nodes,
            links: links
        }
    }

    createSimpleFunction(func, array) {
        if (func.name === '+') {
            let output = []
            for (let n of array) {
                output.push('toFloat(' + n.data +  ')')
            }
            return '( ' + output.join(' + ') + ' )'
        } else if (func.name === '-') {
            let output = ['', '']
            for (let n of array) {
                if (n.input === 'inA') output[0] = 'toFloat(' + n.data +  ')'
                if (n.input === 'inB') output[1] = 'toFloat(' + n.data +  ')'
            }
            return '( ' + output.join(' - ') + ' )'
        } else if (func.name === '*') {
            let output = []
            for (let n of array) {
                output.push('toFloat(' + n.data +  ')')
            }
            return '( ' + output.join(' * ') + ' )'
        } else if (func.name === '/') {
            let output = ['', '']
            for (let n of array) {
                if (n.input === 'inA') output[0] = 'toFloat(' + n.data +  ')'
                if (n.input === 'inB') output[1] = 'toFloat(' + n.data +  ')'
            }
            return '( ' + output.join(' / ') + ' )'
        }
    }

    throughChain (chain) {
        let array = []
        let nodes = []
        let links = []
        for (let element of chain.input) {
            if ('logic' in element) {
                let where = this.throughChain(element)
                array.push(where.where)
                for (let node of where.nodes) {
                    if (!nodes.includes(node)) {
                        nodes.push(node)
                    }
                }
                for (let link of where.links) {
                    if (!links.includes(links)) {
                        links.push(link)
                    }
                }
            } else {
                let name = element.operator.name
                if (chain.logic === 0) name = this.notOperator(element.operator.name)
                if (name === null) array.push('(NOT ' +  this.createSimpleWhere(element.operator, element.expression, element.value, element.operator.name) + ')')
                else array.push(this.createSimpleWhere(element.operator, element.expression, element.value, name))

                if (element.ontology.element === 'ontology') nodes.push(element.ontology.index)
                else links.push(element.ontology.index)
            }
        }
        return {
            nodes: nodes,
            links: links,
            where: '(' + array.join(' ' + chain.logic + ' ') + ')'
        }
    }

    createSimpleWhere (operator, expression, value, name) {
        let where = ''
        if (operator !== null) {
            if (value[0].type === 'value') {
                if (['=', '>', '<', '>=', '<='].includes(operator.name)) {
                    if (value[0].name === 'Number') {
                        where = expression + name + ' ' + value[0].value
                    } else if (value[0].name === 'Boolean') {
                        where = expression
                        if (value[0].value === 'False') {
                            where = '(NOT' + expression + ')'
                        }
                    } else if (value[0].name === 'Date') {
                        let dateArray = value[0].value.split('-')
                        if (dateArray[1][0] === '0') dateArray[1] = dateArray[1].slice(1, 2)
                        if (dateArray[2][0] === '0') dateArray[2] = dateArray[2].slice(1, 2)
                        where = expression +
                            name + ' date({year: ' + dateArray[0] + ', month: ' + dateArray[1] + ', day: ' + dateArray[2] + '})'
                    } else {
                        where = expression + ' ' + name + ' \'' + value[0].value + '\''
                    }
                } else if (operator.name === '=~ IN'){
                    where = expression + ' =~ \'(?).*' + value[0].value + '.*\''
                } else if (operator.name === 'Array'){
                    let valueArray = []

                    for (let v of value) {
                        if (value[0].name === 'Number') {
                            valueArray.push(expression + ' = ' + v.value)
                        } else {
                            valueArray.push(expression + ' = \'' + v.value + '\'')
                        }
                    }
                    where = '(' + valueArray.join(' OR ') + ')'
                }
            } else {
                where = expression + ' ' + name + ' ' + value[0].value
            }
        } else {
            where = expression
        }
        return where
    }



    constructWhere (scheme) {
        let operators = scheme.operators
        let attributes = scheme.attributes
        let logics = scheme.logics
        let links = scheme.links

        let whereArray = []
        let complexOps = []
        for (let operator of operators) {
            let count = 0
            for (let link of links) {
                if (scheme.compareNodes(operator, link.source)) count++
                else if (scheme.compareNodes(operator, link.target)) count++
            }
            let value  = this.findValueByOperator (operator, links, scheme)
            let attr  = this.findAttrByOperator (operator, links, scheme)
            let func  = this.findFunctionByOperator (operator, links, scheme)
            let ontology = null
            let expression = ''
            if (attr !== null) {
                ontology = this.findNodeByAttribute (attr, links, scheme)
                expression = ontology.short + '[\'' + attr.attribute.name + '\']'
                if (attr.attribute.name === 'neoID') expression = 'ID(' + ontology.short + ') '
            }
            count = count - value.length + 1
            if (count === 2) {
                let links = []
                let nodes = []
                if (attr !== null && ontology !== null){
                    if (ontology.element === 'ontology') nodes.push(ontology.index)
                    else links.push(ontology.index)
                    for (let v of value) {
                        if (v.type === 'attribute') {
                            nodes.push(v.ontology.index)
                        }
                    }
                    whereArray.push({
                        nodes: nodes,
                        links: links,
                        where: this.createSimpleWhere(operator, expression, value, operator.name)
                    })

                } else if (func !== null) {
                    let through = this.throughFunctions(func, scheme)
                    expression = through.expression

                    whereArray.push({
                        nodes: through.nodes,
                        links: through.links,
                        where: this.createSimpleWhere(operator, expression, value, operator.name)
                    })
                }
            }
            else if (count > 2) {
                if ((attr !== null && ontology !== null) || func !== null) {
                    complexOps.push({
                        operator: operator,
                        value: value,
                        expression: expression,
                        ontology: ontology
                    })
                }
            }
        }

        for (let attr of attributes) {
            let expression = ''
            if (attr.name === 'Boolean') {
                let count = 0
                for (let link of links) {
                    if (scheme.compareNodes(attr, link.source)) count++
                    else if (scheme.compareNodes(attr, link.target)) count++

                    if (scheme.compareNodes(attr, link.source) && link.target.element === 'value') {
                        count = -1
                        break
                    } else if (scheme.compareNodes(attr, link.target) && link.source.element === 'value') {
                        count = -1
                        break
                    }
                }
                let ontology = this.findNodeByAttribute (attr, links, scheme)
                expression = ontology.short + '[\'' + attr.attribute.name + '\']'
                if (attr.attribute.name === 'neoID') expression = 'ID(' + ontology.short + ') '
                if (count === 1) {
                    if (ontology !== null) {
                        let links = []
                        let nodes = []
                        if (ontology.element === 'ontology') nodes.push(ontology.index)
                        else links.push(ontology.index)
                        whereArray.push({
                            nodes: nodes,
                            links: links,
                            where: ontology.short + '.' + attr.attribute.name
                        })
                    }
                }
                else if (count > 2) {
                    if (ontology !== null) {
                        complexOps.push({
                            operator: null,
                            value: null,
                            ontology: ontology,
                            expression: expression
                        })
                    }
                }
            }
        }

        let logicsChain = {}
        let logicsDone = []

        for (let operator of complexOps) {
            for (let link of links) {
                if (scheme.compareNodes(operator.operator, link.source) && link.target.element === 'logic') {
                    if ((link.target.index + '') in logicsChain) logicsChain[link.target.index + ''].input.push(operator)
                    else {
                        logicsChain[link.target.index + ''] = {
                            input: [operator],
                            logic: link.target.name
                        }
                    }
                }
                else if (scheme.compareNodes(operator.operator, link.target) && link.source.element === 'logic') {
                    if ((link.source.index + '') in logicsChain) logicsChain[link.source.index + ''].input.push(operator)
                    else {
                        logicsChain[link.source.index + ''] = {
                            input: [operator],
                            logic: link.source.name
                        }
                    }
                }
            }
        }
        let oldChain = Object.assign({}, logicsChain);
        let flag = true
        while (flag) {
            flag = false
            for (let logic of logics) {
                if (!logicsDone.includes(logic.index)) {
                    for (let link of links) {
                        if (scheme.compareNodes(logic, link.source) && link.sourcePart == 'in' && link.target.element === 'logic' && ((link.target.index + '') in oldChain)) {
                            if ((link.target.index + '') in logicsChain) delete logicsChain[link.target.index + '']
                            if ((link.source.index + '') in logicsChain) logicsChain[link.source.index + ''].input.push(oldChain[link.target.index + ''])
                            else {
                                logicsChain[link.source.index + ''] = {
                                    input: [oldChain[link.target.index + '']],
                                    logic: link.source.name
                                }
                            }
                            logicsDone.push(link.source.index)
                            flag = true
                        }
                        else if (scheme.compareNodes(logic, link.target) && link.targetPart == 'in' && link.source.element === 'logic' && ((link.source.index + '') in oldChain)) {
                            if ((link.source.index + '') in logicsChain) delete logicsChain[link.source.index + '']
                            if ((link.target.index + '') in logicsChain) logicsChain[link.target.index + ''].input.push(oldChain[link.source.index + ''])
                            else {
                                logicsChain[link.target.index + ''] = {
                                    input: [oldChain[link.source.index + '']],
                                    logic: link.target.name
                                }
                            }
                            logicsDone.push(link.target.index)
                            flag = true
                        }
                    }
                }
            }
            if (flag) {
                oldChain = Object.assign({}, logicsChain)
                logicsChain = {}
            }
        }

        for (let key of Object.keys(oldChain)) {
            whereArray.push(this.throughChain(oldChain[key]))
        }
        return whereArray
    }

    getMatch(path, scheme) {
        let match = ''
        for (let i = 0; i < path.length - 1; i++) {
            let link = scheme.getLink(path[i + 1].link)
            let node = scheme.getNode(path[i].node)
            match += '(' + node.short + ':' + node.name + ')'
            let linkName = ':' + link.name
            if (link.name.length === 0) linkName = ''
            if (!link.directed) match += '-[' + link.short + linkName + ']-'
            else {
                if (link.source.index === node.index) match += '-[' + link.short + linkName + ']->'
                else match += '<-[' + link.short + linkName + ']-'
            }

        }
        let node = scheme.getNode(path[path.length - 1].node)
        if (path.length > 0) {
            match += '(' + node.short + ':' + node.name + ')'
        }
        return match
    }

    getWhereArray(paths, whereObjects, done) {
        let whereArray = []
        let newDone = []
        for (let path of paths) {
            for (let where of whereObjects) {
                if (!done.includes(whereObjects.indexOf(where))){
                    let flag = true
                    for (let i = 0; i < where.nodes.length; i++) {
                        let flag2 = false
                        for (let p of path) {
                            if (p.node === where.nodes[i]) {
                                flag2 = true
                                break
                            }
                        }
                        if (!flag2) flag = false
                    }
                    for (let i = 0; i < where.links.length; i++) {
                        let flag2 = false
                        for (let p of path) {
                            if (p.link === where.links[i]) {
                                flag2 = true
                                break
                            }
                        }
                        if (!flag2) flag = false
                    }
                    if (flag) {
                        whereArray.push(where.where)
                        newDone.push(whereObjects.indexOf(where))
                    }
                }
            }
        }
        return {
            array: whereArray,
            done: done.concat(newDone)
        }
    }



    makeQuery (scheme) {
        let partQueries = []
        let subgraphQuery = []
        let queryFirst = []

        let wholeQuery = ''
        let whereObjects = this.constructWhere(scheme)
        let whereObjectsDone = []
        let allGraphParts = scheme.graphParts.negative
        let links = scheme.links
        let paths = scheme.paths
        let sameFamiliesLink = scheme.sameFamiliesLink
        for (let p of scheme.paths) {
            let part = p.part
            let duplicateNodes = p.duplicateNodes
            let callNegativeQuery = ''
            let current = null
            let alien = null
            if (allGraphParts.length > 1) {
                let anotherPart = (function () {
                    if (allGraphParts[0][0] === part[0]) return paths[1]
                    else return paths[0]
                })()
                let negativeLink = null
                for (let link of links) {
                    if (link.linkType === 'negative') {
                        negativeLink = link
                        break
                    }
                }
                if (part.includes(negativeLink.source.index)) {
                    current = negativeLink.source
                    alien = negativeLink.target
                } else {
                    current = negativeLink.target
                    alien = negativeLink.source
                }
                let whereArray = this.getWhereArray(anotherPart.paths, whereObjects, []).array
                let matchArray = []
                for (let path of anotherPart.paths) {
                    let match = this.getMatch(path, scheme)
                    if (path[path.length - 1] === alien.index) {
                        match = match + '-[' + negativeLink.short + ':' + negativeLink.name + ']-'
                        match += '(' + current.short + ':' + current.name + ')'
                    } else if (path[0] === alien.index) {
                        match = '-[' + negativeLink.short + ':' + negativeLink.name + ']-'
                        match = '(' + current.short + ':' + current.name + ')' + match
                    }
                    matchArray.push(match)
                }
                if (matchArray.length > 0) callNegativeQuery += '\'MATCH ' + matchArray.join(', ') + '\n'
                if (whereArray.length > 0) {
                    callNegativeQuery += 'WHERE ' + whereArray.join(' AND ') + ' AND ID(' + current.short + ') = \' + {0} + ' + '\'\n'
                } else {
                    callNegativeQuery += 'WHERE ID(' + current.short + ') = \' + {0} + ' + '\'\n'
                }
                callNegativeQuery += 'RETURN count(' + current.short + ') as count\''
            }

            let query = ''
            let nodesFirst = [] // узлы, ID которых переходит к остальной части
            let nodesDone = []
            let linksFirst = [] // связи, ID которых переходит к остальной части
            let linksDone = []
            let whereArray = []
            let nodesNext = [] // узлы, которые переходят полностью к остальной части
            let matchArray = []
            let pathCounter = 0

            let shortNext = []
            let whereNext = []

            let sortedPaths = []
            for (let s of scheme.sortedPaths) {
                if (part.includes(s.part[0])) {
                    sortedPaths = s.paths
                    break
                }
            }
            for (let s_path of sortedPaths) {
                let path = s_path.path
                let center = scheme.getNode(path[0].node)
                if (pathCounter === 0) {
                    whereArray = this.getWhereArray([path], whereObjects, []).array
                    whereObjectsDone = this.getWhereArray([path], whereObjects, []).done
                    for (let where of whereObjects) {
                        let next = false
                        let countWhereNodes = 0
                        let rest = []
                        for (let n of where.nodes) {
                            let flag = false
                            for (let p of path) {
                                if (p.node === n) {
                                    flag = true
                                    break
                                }
                            }
                            if (flag) {
                                countWhereNodes++
                                let node = scheme.getNode(n)
                                rest.push(node.short)
                            }
                        }
                        if (countWhereNodes !== where.nodes.length) {
                            next = true
                            shortNext = shortNext.concat(rest)
                        }

                        let countWhereLinks = 0
                        rest = []
                        for (let n of where.links) {
                            let flag = false
                            for (let p of path) {
                                if (p.link === n) {
                                    flag = true
                                    break
                                }
                            }
                            if (flag) {
                                countWhereLinks++
                                let link = scheme.getLink(n)
                                rest.push(link.short)
                            }
                        }
                        if (countWhereLinks !== where.links.length) {
                            next = true
                            shortNext = shortNext.concat(rest)
                        }

                        if (next) whereNext.push(where.where)
                    }
                    let match = this.getMatch(path, scheme)
                    for (let i = 0; i < path.length; i++) {
                        let node = scheme.getNode(path[i].node)
                        if (i < path.length - 1) {
                            let link = scheme.getLink(path[i + 1].link)
                            linksDone.push(link.short)
                            linksFirst.push(link.short)
                        }
                        for (let dup of duplicateNodes) {
                            if (dup.includes(node.index)) {
                                nodesFirst.push(node.index)
                                nodesDone.push(node.index)
                            }
                        }
                        if (!nodesDone.includes(node.index)) {
                            nodesFirst.push(node.index)
                            nodesDone.push(node.index)
                        }
                    }
                    for (let where of whereObjects) {
                        if (!whereObjectsDone.includes(whereObjects.indexOf(where))) {
                            for (let n of where.nodes) {
                                if (nodesDone.includes(n)) {
                                    nodesFirst.splice(nodesFirst.indexOf(n), 1)
                                    nodesNext.push(n)
                                }
                            }
                        }
                    }

                    query += 'MATCH ' + match + '\n'
                    if (whereArray.length > 0) query += 'WHERE ' + whereArray.join(' AND ') + '\n'
                    whereArray = []
                    let withArray = []
                    for (let index of nodesDone) {
                        let node = scheme.getNode(index)
                        if (nodesNext.includes(index)) withArray.push(node.short)
                        else withArray.push('ID(' + node.short + ') as ' + node.short + '_id')
                    }
                    for (let short of linksDone) {
                        withArray.push('ID(' + short + ') as ' + short + '_id')
                    }
                    for (let short of shortNext) {
                        withArray.push(short)
                    }
                    queryFirst = query + ' RETURN count(' + center.short + ')'
                    query += 'WITH ' + withArray.join(', ') + ' SKIP {0} LIMIT {1}\n'
                    withArray = []
                } else {
                    let match = this.getMatch(path, scheme)
                    whereArray = whereArray.concat(this.getWhereArray([path.concat(nodesNext)], whereObjects, whereObjectsDone).array)
                    whereObjectsDone = this.getWhereArray([path.concat(nodesNext)], whereObjects, whereObjectsDone).done
                    for (let i = 0; i < path.length; i++) {
                        let node = scheme.getNode(path[i].node)
                        if (i < path.length - 1) {
                            let link = scheme.getLink(path[i + 1].link)
                            linksDone.push(link.short)
                        }
                        for (let dup of duplicateNodes) {
                            if ( (nodesFirst.includes(dup[0]) && dup[1] === node.index) || (nodesFirst.includes(dup[1]) && dup[0] === node.index)) {
                                if (!whereArray.includes('ID(' + node.short + ') = ' + node.short + '_id')) whereArray.push('ID(' + node.short + ') = ' + node.short + '_id')
                                break
                            }
                        }
                        if (nodesFirst.includes(node.index)) {
                            if (!whereArray.includes('ID(' + node.short + ') = ' + node.short + '_id')) whereArray.push('ID(' + node.short + ') = ' + node.short + '_id')
                        }
                        if (!nodesDone.includes(node.index)) {
                            nodesDone.push(node.index)
                        }
                    }
                    matchArray.push(match)
                }
                pathCounter++
            }
            for (let i1 = 0; i1 < scheme.nodes.length - 1; i1++) {
                for (let i2 = i1 + 1; i2 < scheme.nodes.length; i2++) {
                    let node1 = scheme.nodes[i1]
                    let node2 = scheme.nodes[i2]
                    if (node1.element === 'ontology' && node2.element === 'ontology') {
                        let flag = true
                        for (let dup of duplicateNodes) {
                            if (dup.includes(node1.index) && dup.includes(node2.index)) flag = false
                        }
                        if (node1.name === node2.name && flag) {
                            let short1 = 'ID(' + node1.short + ')'
                            let short2 = 'ID(' + node2.short + ')'
                            if (nodesFirst.includes(node1.index)) short1 = node1.short + '_id'
                            if (nodesFirst.includes(node2.index)) short2 = node2.short + '_id'
                            whereArray.push(short1 + ' <> ' + short2)
                        }
                    }
                }
            }
            for (let link in sameFamiliesLink) {
                for (let i1 = 0; i1 < sameFamiliesLink[link].length - 1; i1++) {
                    for (let i2 = i1 + 1; i2 < sameFamiliesLink[link].length; i2++) {
                        let link1 = scheme.getLink(sameFamiliesLink[link][i1])
                        let link2 = scheme.getLink(sameFamiliesLink[link][i2])
                        let short1 = 'ID(' + link1.short + ')'
                        let short2 = 'ID(' + link2.short + ')'
                        if (linksFirst.includes(link1.index)) short1 = link1.short + '_id'
                        if (linksFirst.includes(link2.index)) short2 = link2.short + '_id'
                        whereArray.push(short1 + ' <> ' + short2)
                    }
                }
            }
            if (matchArray.length > 0) query += 'MATCH ' + matchArray.join(', ') + '\n'
            if (whereNext.length > 0) whereArray = whereArray.concat(whereNext)
            if (whereArray.length > 0) query += 'WHERE ' + whereArray.join(' AND ') + '\n'
            if (callNegativeQuery.length > 0) {
                let shortCurrent = 'ID(' + current.short + ')'
                if (nodesFirst.includes(current.index)) shortCurrent = current.short + '_id'
                query += 'CALL apoc.cypher.run(' + callNegativeQuery.replace('{0}', shortCurrent) + ', null) YIELD value\n'
                query += 'WHERE value.count = 0\n'
            }

            let withArray = []
            let withSubgraphArray = []
            let subgraphSeqPart = []
            let subgraphSeqLinkPart = []
            for (let index of nodesDone) {
                let node = scheme.getNode(index)
                let short = 'ID(' + node.short + ')'
                if (nodesFirst.includes(node.index)) short = node.short + '_id'
                let isDuplicate = false
                for (let dup of duplicateNodes) {
                    if (index === dup[1]) {
                        isDuplicate = true
                        break
                    }
                }
                if (!isDuplicate) {
                    withSubgraphArray.push(short + ' as ' + node.short + '_id')
                    subgraphSeqPart.push(node.short)
                    withArray.push('collect(' + short + ') as ' + node.short + '_array')
                }
            }
            for (let short of linksDone) {
                if (!linksFirst.includes(short)) withSubgraphArray.push('ID(' + short + ')' + ' as ' + short + '_id')
                else withSubgraphArray.push(short + '_id' + ' as ' + short + '_id')
                subgraphSeqLinkPart.push(short)
            }
            subgraphQuery.push({
                part: part,
                sequence: subgraphSeqPart,
                sequenceLink: subgraphSeqLinkPart,
                query: query + 'WITH ' + withSubgraphArray.join(', ') + '\n'
            })
            query += 'WITH ' + withArray.join(', ') + '\n'
            withArray = []
            nodesDone.sort()
            for (let index of nodesDone) {
                let node = scheme.getNode(index)
                let isDuplicate = false
                for (let dup of duplicateNodes) {
                    if (index === dup[1]) {
                        isDuplicate = true
                        break
                    }
                }
                if (!isDuplicate) withArray.push('apoc.coll.toSet(' + node.short + '_array) as ' + node.short + '_array')
            }
            query += 'WITH ' + withArray.join(', ') + '\n'
            wholeQuery = wholeQuery + query
            partQueries.push({
                part: part,
                query: query
            })
        }
        return {
            cypherQuery: partQueries,
            queryFirst: queryFirst,
            subgraphQuery: subgraphQuery
        }
    }

    checkScheme(data) {
        return axios.post(API_URL + 'checkScheme', data, { headers: authHeader() });
    }


}

export default new SchemeService();
