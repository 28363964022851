<template>
  <div class="hello" id="app" :style="{'background-color': 'white', overflow: 'hidden'}">
    <div :style="{'z-index': 2001, position: 'absolute', left: 0, top: (height + 4)/2 + 'px'}">
      <el-button style="opacity: 0.7; padding: 18px 8px" @click="$emit('move', 'vis')">
        <i class="fas fa-step-forward fa-lg" ></i>
      </el-button>
    </div>
    <el-row style="text-align: left;">
      <el-col>
        <el-radio-group size="small" v-model="componentValue" @change="visChange" style="margin-top: 4px; margin-bottom: 0px; margin-left: 16px">
          <el-radio-button label="NeoSearchSubgraph">{{ $t('Список') }}</el-radio-button>
          <el-radio-button label="GraphFull">{{ $t('Граф') }}</el-radio-button>
<!--          <el-radio-button label="GraphMap">Карта</el-radio-button>-->
        </el-radio-group>
      </el-col>
    </el-row>

    <el-dialog
            :title="$t('Выберите атрибуты')"
            v-model="dialogSubgraph"
            width="30%">

      <el-select v-model="attributesToShow"
                 multiple
                 :no-match-text="$t('Ничего не найдено')"
                 :placeholder="$t('Выберите аттрибут')">
        <el-option
                v-for="item in attributesAll"
                :key="item"
                :label="item"
                :value="item">
        </el-option>
      </el-select>
    </el-dialog>


    <div style="z-index: 10000; position: absolute; left: 32px; top: 54px">
      <div v-if="labelEditWindow.show"
           style="margin-top: 16px">
        <el-card  :style="{width: '100%'}" class="box-card">
          <template #header>
              <div :style="{display:'flex', justifyContent:'center'}">
                <span>{{labelEditWindow.label}}</span>
              </div>
                <el-button 
                  @click="labelEditWindow.show = false" 
                  :style="{position: 'absolute', top: '10px', right:'10px'}" 
                  class="button" 
                  type="text"
                >
                  <i class="el-dialog__close el-icon el-icon-close" style="color: #909399"></i>
                </el-button>
          </template>
          <div style="text-align: start; font-size: 14px; margin-bottom: 8px">
            {{ $t('Отображаемое название') }}
          </div>
          <div>
            <el-select v-model="labelEditWindow.picked"
                       @change="displayLabelChange"
                       style="width: 200px;display: inline-block"
                       filterable
                       :placeholder="$t('Атрибут')">

              <el-option
                      :label="'ID_'"
                      :value="''">
              </el-option>
              <el-option
                      v-for="item in labelEditWindow.data"
                      :key="item"
                      :label="item"
                      :value="item">
              </el-option>
            </el-select>
          </div>
        </el-card>
      </div>
    </div>

    <div v-if="showElementInfoWindow && elementInfoWindowPos === 'top'" style="z-index: 10000; position: absolute; left: 32px; top: 54px">
      <ElementInfo style="margin-top: 0px"  :element="elementInfo"></ElementInfo>
    </div>
<!--    <div v-if="showElementInfoWindow && elementInfoWindowPos === 'bottom'" style="z-index: 10000; position: absolute; left: 32px; bottom: 54px">-->
<!--      <ElementInfo style="margin-bottom: 16px"  :element="elementInfo"></ElementInfo>-->
<!--    </div>-->

    <el-row align-h="start" :style="{width:'100%', height: (height) + 'px', 'background-color': '#fff'}">
      <el-col style="padding-left:0px;background-color: white">
       <component
               @show-edit-label="labelEdit"
               @open-map="openMapGraph"
               @show-notification="showNotification($event)"
               @expand-vis="$emit('move', 'constructor')"
               @close-info="showElementInfoWindow = false" @show-info="showElementInfo" @subgraph-graph="createGraphFull" @add-to-cart="addToCart" v-if="componentVisible" :is="componentToShow" :data="toComponent" @show="showComponent" ref="visComponent"> </component>
      </el-col>
    </el-row>

    <el-dialog
            :title="$t('Построение связи')"
            v-model="dialogVGraphLink"
            :fullscreen="true"
            @open="createStartNodes"
            width="100%">

      <constructor-v-graph :data="vGraph"
                           @v-graph-element="updateVGraphElements"
                           ref="constructorVGraph"
                           :style="{height: (height - 200) + 'px'}"></constructor-v-graph>


      <template #footer>
        <span class="dialog-footer">
            <el-button @click="dialogVGraphLink = false">{{ $t('Отмена') }}</el-button>
            <el-button type="success" @click="createVGraph">{{ $t('Подтвердить') }}</el-button>
          </span>
      </template>

    </el-dialog>
  </div>
</template>

<script>
import SchemeWindow from './SchemeWindow'
import NeoSearchSubgraph from './NeoSearchSubgraph'
import vGraph from './GraphNew'
import GraphFull from './GraphFull'
import GraphMap from './GraphMap'
import ConstructorVGraph from './ConstructorVGraph'
import VisController from "../../controllers/vis.controller";
import CaseSessionController from "../../controllers/caseSession.controller";
import ElementInfo from "./ElementInfo";
import {ElNotification} from "element-plus";
import CashController from "../../controllers/cash.controller";
import OntologyController from "../../controllers/ontology.controller";

export default {
  components: {
    ElementInfo,
    SchemeWindow,
    vGraph,
    NeoSearchSubgraph,
    ConstructorVGraph,
    GraphFull,
    GraphMap
  },
  name: 'visualization',
  data() {
    return {
      toComponent: {
        query: null,
        form: {}
      },
      width: Math.max(
        document.documentElement.clientWidth,
        window.innerWidth || 0
      ),
      height: Math.max(
        document.documentElement.clientHeight,
        window.innerHeight || 0
      ) - 110 - 46 ,
      ontology: null,
      currentVisualization: 'scheme',
      vGraph: {
        currentNodeChoice: '1',
        selectedNodes: {
          first: null,
          second: null
        },
        elements: {
          nodes: [],
          links: []
        }
      },
      labelEditWindow: {
        show: false,
        data: [],
        label: '',
        picked: ''
      },
      componentVisible: false,
      dialogSubgraph: false,
      dialogVGraphLink: false,
      selectSchemeToLoad: {},
      presentSchemes: [],
      selectedItem: null,
      selectedFirstItem: null,
      selectedItemType: -1,
      realSelectedItem: null,
      schemeGraphType: '1',
      attributesAll: [],
      attributesToShow: [],
      componentToShow: '',
      componentValue: 'NeoSearchSubgraph',
      schemeController: [],
      elementInfo: {
        attributes: {}
      },
      showPickSchemeWindow: true,
      showElementInfoWindow: false,
      caseSessionController: null,
      cashController: null,
      notification: {
        expandDone: null,
        queryDone: null,
      },
      elementInfoWindowPos: 'top'
    }
  },
  methods: {
    visChange() {
      this.startVis(false)
    },
    startVis(play){

      let cash = this.cashController.getCashStore()
      if (play) {
        cash.graphSubgraph = null
        cash.listSubgraph = null
        cash.visualizator.window = this.componentValue
      }
      cash.visualizator.window = this.componentValue
      this.cashController.setCashStore(cash)
      if (this.componentValue === 'NeoSearchSubgraph') {
        this.showSubgraphList({isNew: play})
      } else if (this.componentValue === 'GraphFull'){
        this.createGraphFull({selection: null, isNew: play})
      } else if (this.componentValue === 'GraphMap'){
        this.openMapGraph({selection: null, isNew: play})
      }
    },
    displayLabelChange() {
      this.labelEditWindow.show = false
      let cash = this.cashController.getCashStore()
      cash.displayAttribute[this.labelEditWindow.label] = this.labelEditWindow.picked
      this.cashController.setCashStore(cash)
      this.$refs.visComponent.displayLabelChange({type: this.labelEditWindow.label, attribute: this.labelEditWindow.picked})
    },
    labelEdit (label) {
      // let cash = this.cashController.getCashStore()
      const commonAttribute = localStorage.getItem("displayAttribute")
       if (commonAttribute) {
        let checkAttributes = JSON.parse(commonAttribute)
        if (checkAttributes) {
          const index = checkAttributes.findIndex((attribute) => attribute.type === label)
          if(index === -1) {
          this.labelEditWindow.picked = ''
          } else {
            this.labelEditWindow.picked = checkAttributes[index].attribute
          }
        } else {
          console.log(".. labelEdit. JSON.parse error. commonAttribute = ", commonAttribute)
          this.labelEditWindow.picked = ''
        }
      } else {
          this.labelEditWindow.picked = ''
      } 
      // if (Object.keys(cash.displayAttribute).includes(label)) {
      //   this.labelEditWindow.picked = cash.displayAttribute[label]
      // } else {
      //   this.labelEditWindow.picked = ''
      // }
      this.labelEditWindow.show = false
      this.labelEditWindow.label = label
      this.labelEditWindow.data = Object.keys(this.ontology.nodes[label].properties)
      const vm = this
      setTimeout( function () {
        vm.labelEditWindow.show = true
      }, 150)
    },
    showComponent(component) {
       console.log(component)
    },
    addToCart (element) {
      // console.log('ADDD TO CART', element)
      this.$emit('add-to-cart', element)
    },
    showElementInfo (element) {
      // console.log('showElementInfo', element)
      this.elementInfo = element
      this.showElementInfoWindow = true
    },
    expandVis() {
      if (this.componentToShow === 'GraphMap') {
        this.componentToShow = ''
        const vm = this
        setTimeout(() => {    vm.toComponent = {
          scheme: vm.selectSchemeToLoad,
          item: vm.realSelectedItem,
          isNew: false
        }
          this.componentToShow = 'GraphMap'}, 50)

      }
    },
    openMapGraph(data) {
      for (let node of this.selectSchemeToLoad.nodes) {
        if (node.element === 'ontology') {
          this.realSelectedItem = node
          break
        }
      }
      if (this.componentToShow === 'GraphMap') {
        this.$refs.visComponent.clear()
        this.$refs.visComponent.init({
          scheme: this.selectSchemeToLoad,
          item: this.realSelectedItem,
          isNew: data.isNew
        })
      } else {
        this.toComponent = {
          scheme: this.selectSchemeToLoad,
          item: this.realSelectedItem,
          isNew: data.isNew
        }
        this.componentToShow = 'GraphMap'
      }
    },
    createGraphFull (data) {
      let cash = this.cashController.getCashStore()
      for (let node of this.selectSchemeToLoad.nodes) {
        if (node.element === 'ontology') {
          this.realSelectedItem = node
          break
        }
      }
      if (this.componentToShow === 'GraphFull') {
        if (cash.graphSubgraph === null) this.$refs.visComponent.init({
          scheme: this.selectSchemeToLoad,
          item: this.realSelectedItem,
          selection: data.selection,
          isNew: data.isNew
        })
        else this.$refs.visComponent.initCash()
      } else {
        this.toComponent = {
          scheme: this.selectSchemeToLoad,
          item: this.realSelectedItem,
          selection: data.selection,
          isNew: data.isNew
        }
        this.componentToShow = 'GraphFull'
      }

      this.componentVisible = true
    },
    showSubgraphList (data) {
      let cash = this.cashController.getCashStore()
      for (let node of this.selectSchemeToLoad.nodes) {
        if (node.element === 'ontology') {
          this.realSelectedItem = node
          break
        }
      }
      // console.log(this.realSelectedItem)
      if (this.componentToShow === 'NeoSearchSubgraph') {
        if (cash.listSubgraph === null)  this.$refs.visComponent.init({
          scheme: this.selectSchemeToLoad,
          item: this.realSelectedItem,
          attributes: this.attributesToShow,
          isNew: data.isNew
        })
        else this.$refs.visComponent.initCash()
      } else {
        this.toComponent = {
          scheme: this.selectSchemeToLoad,
          item: this.realSelectedItem,
          attributes: this.attributesToShow,
          isNew: data.isNew
        }
        this.componentToShow = 'NeoSearchSubgraph'
        this.componentVisible = true
      }
    },
    // changeVGraphChoice () {
    //   this.$refs.scheme_window.changeVGraph(this.vGraph)
    // },
    createStartNodes () {
      this.componentVisible = false
      if (typeof this.$refs.constructorVGraph !== 'undefined') {
        this.$refs.constructorVGraph.resetConstructor()
        this.$refs.constructorVGraph.createStartNodes(this.vGraph.selectedNodes)
      }
    },
    showNotification(obj){
      this.notification[obj.name](obj.agrs)
    },
  },
  mounted: function () {
    this.cashController = new CashController()
    this.ontologyController = new OntologyController()

    let cash = this.cashController.getCashStore()


    this.ontology = this.ontologyController.getOntology()
    this.caseSessionController = new CaseSessionController()
    this.visController = new VisController()

    this.notification.noSubGraphs =  () => {
      ElNotification({
        title: this.$t('Выполнено'),
        type: 'warning',
        duration: 3000,
        position: 'bottom-right',
        message: this.$t('Подграфы не найдены'),
      })
    }

    this.notification.expandDone =  (obj) => {
      ElNotification({
        title: this.$t('Выполнено'),
        type: 'success',
        duration: 3000,
        position: 'bottom-right',
        message: obj.lCount === 0 ? this.$t('Ничего не найдено') : this.$t('Выгружено') + ' ' + obj.nCount + ' ' + this.$t('узлов и') + ' ' + obj.lCount + ' ' + this.$t('связей'),
      })
    }

    this.notification.queryDone = (obj) => {
      ElNotification({
        title: this.$t('Выполнено'),
        type: 'success',
        duration: 3000,
        position: 'bottom-right',
        message: obj.lCount === 0 ? this.$t('Ничего не найдено') : this.$t('Выгружено') + ' ' + obj.nCount + ' ' + this.$t('узлов и') + ' ' + obj.lCount + ' ' + this.$t('связей'),
      })
    }

    if (cash.visualizator.window !== '' && cash.visualizator.scheme != null) {
      this.componentValue = cash.visualizator.window
      this.selectSchemeToLoad = cash.visualizator.scheme
      // console.log(cash.visualizator.scheme)

      this.startVis(false)
    }
  },
  watch: {
    currentVisualization: function (value) {
      if (value === 'subgraphs') {
        this.showSubgraphList()
      }
      this.vGraph= {
        currentNodeChoice: '1',
        selectedNodes: {
          first: null,
          second: null
        },
        elements: {
          nodes: [],
          links: []
        }
      }
      this.selectedItem = null
    }
  }
}
</script>
<style scoped src="../../assets/index.css"></style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  h1,
  h2 {
    font-weight: normal;
  }


  button.page-link {
    display: inline-block;
  }

  button.page-link {
    font-size: 20px;
    color: #29b3ed;
    font-weight: 500;
  }

  .cc-selector input {
    margin: 0;
    padding: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .cc-selector input:active + .drinkcard-cc {
    opacity: .9;
  }

  .cc-selector input:checked + .drinkcard-cc {
    -webkit-filter: none;
    -moz-filter: none;
    filter: none;
  }

  .drinkcard-cc {
    cursor: pointer;
    background-size: contain;
    background-repeat: no-repeat;
    display: inline-block;
    width: 42px;
    height: 42px;
    -webkit-transition: all 100ms ease-in;
    -moz-transition: all 100ms ease-in;
    transition: all 100ms ease-in;
    -webkit-filter: brightness(1.8) grayscale(1) opacity(.5);
    -moz-filter: brightness(1.8) grayscale(1) opacity(.5);
    filter: brightness(1.8) grayscale(1) opacity(.5);
  }

  .drinkcard-cc:hover {
    -webkit-filter: brightness(1.2) grayscale(.5) opacity(.9);
    -moz-filter: brightness(1.2) grayscale(.5) opacity(.9);
    filter: brightness(1.2) grayscale(.5) opacity(.9);
  }
</style>
