import * as d3 from 'd3'
import Utils from '../services/utils'
import {store} from "../store";
import arrowJSON from '../store/arrowJSON'

export default class D3Controller {
    constructor(svg, width, height) {
        this.ontology = store.getters['ontologyStore/getOntologyStore']
        // this.svg = svgSelection
        // this.graphSelection = graphSelection

        this.svg = d3.select(svg)
            .attr('width', width)
            .attr('height', height)
        this.graphSelection = this.svg.select('#gElement')
        //this.graphSelectionScale = this.svg.select('#gElementScale')
        this.height = height
        this.width = width
        this.graph = {
            nodes: [],
            links: [],
        }
        this.arrowJSON = arrowJSON

    }


    select(element) {
        return d3.select(element)
    }

    event() {
        return d3.event
    }

    mouse(element) {
        return d3.mouse(element)
    }

    updateGraph(graph) {
        this.graph.links = graph.links
        this.graph.nodes = graph.nodes
    }


    removeElements(className) {
        this.graphSelection.selectAll(className).remove()
    }

    createLinkLabel(name, x1, x2, y) {
        this.graphSelection.insert('text')
            .attr('x', (x1 + x2)/2)
            .attr("contentEditable", true)
            .attr('y', y - 16)
            .attr('style', 'pointer-events: none;-webkit-touch-callout: none;-webkit-user-select: none;-khtml-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;')
            .attr('font-size', '12px')
            .attr('fill', 'black')
            .attr('font-weight', '500')
            .attr('text-anchor', 'middle')
            .text(name)
    }

   createLabel(name, type) {
       const vm = this
       const size = 10

       this.graphSelection.insert('text')
           .attr('x', 24)
           .attr("contentEditable", true)
           .attr('y', 24)
           .attr('style', 'pointer-events: none;-webkit-touch-callout: none;-webkit-user-select: none;-khtml-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;')
           .attr('font-size', '14px')
           .attr('fill', 'black')
           .attr('font-weight', '500')
           .attr('text-anchor', 'middle')
           .text('Связь с')


       this.graphSelection.insert('text')
           .attr('class', 'text-label-test')
           .attr('x', 0)
           .attr("contentEditable", true)
           .attr('y', '.31em')
           .attr('style', 'pointer-events: none;-webkit-touch-callout: none;-webkit-user-select: none;-khtml-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;')
           .attr('font-size', '13px')
           .attr('fill', 'white')
           .attr('font-weight', '500')
           .attr('text-anchor', 'middle')
           .text('')

       let gLabel = this.graphSelection.insert('g').attr("transform", function() { return "translate(" + 70 + "," + 20 + ")"; })

       let text = vm.graphSelection.select('.text-label-test').text(name)
       let lengthText = text._groups[0][0].getComputedTextLength()
       let startSizeFont = 13
       while (lengthText > 220) {
           startSizeFont = startSizeFont - 1
           text = vm.graphSelection.select('.text-label-test').text(name).attr('font-size', (startSizeFont) + 'px')
           lengthText = text._groups[0][0].getComputedTextLength()
           if (startSizeFont < 11) break
       }
       let lengthCut = 0
       let nameCut = name
       while (lengthText > 220) {
           lengthCut = lengthCut + 2
           nameCut = Utils.cutString(name, name.length - lengthCut)
           text = vm.graphSelection.select('.text-label-test').text(nameCut).attr('font-size', (startSizeFont) + 'px')
           lengthText = text._groups[0][0].getComputedTextLength()
       }


       gLabel.insert('path')
           .attr('d', function () {
               let r = 15
               let length = lengthText + (size) + 12
               return 'M' + 0 + ',' + 0  + ' ' +
                   'm' + '0, ' + (-r) + ' ' +
                   'a' + r + "," + r + " 0 1,0 " + 0  + ","  + r*2 + ' '  +
                   'l' + length + ', ' + 0 + ' ' +
                   'l' + 0 + ', ' + -r*2 + ' ' +
                   'l' + -length + ', ' + 0 + ' '
           })
           .attr('style', 'fill: #202020')

       gLabel.insert('text')
           .attr('class', 'text-label')
           .attr('x', function () {
               return (size) + lengthText/2 + 5
           })
           .attr("contentEditable", true)
           .attr('y', '.31em')
           .attr('style', 'pointer-events: none;-webkit-touch-callout: none;-webkit-user-select: none;-khtml-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;')
           .attr('font-size', function () {
               return startSizeFont + 'px'
           })
           .attr('fill', 'white')
           .attr('font-weight', '500')
           .attr('text-anchor', 'middle')
           .text(nameCut)

       gLabel.insert('circle')
           .attr('style', function () {
               if (type === 'second')  return 'pointer-events: fill; fill:#AC3B61; stroke:none;'
               if (type === 'first')  return 'pointer-events: fill; fill:#3BAC61; stroke:none;'
           })
           .attr('r', function () {
               if (type === 'second') return 11
               if (type === 'first') return 11
               return 0
           })


       gLabel.insert('text')
           .attr('style', 'pointer-events: none;-webkit-touch-callout: none;-webkit-user-select: none;-khtml-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;')
           .attr('font-size', '11px')
           .attr('fill', 'white')
           .attr('font-weight', 'bold')
           .attr('text-anchor', 'middle')
           .attr('x', -1)
           .attr('y', 3)
           .text(function () {
               if (type === 'second') return '1'
               if (type === 'first') return '2'
               else return ''
           })


       this.graphSelection.selectAll('.text-label-test').remove()
        // if (type === 'first') {
        //
        // }
   }

    moveMarkers() {
        const arrowJSON = this.arrowJSON
        this.graphSelection.selectAll('.marker').attr('points', '14,0 -6,6 -6,-6')
            .attr('style', 'fill:#999')
            .attr('transform', d => {
                let angle = Math.PI / 2
                let diffX = d.source.x  - d.target.x
                let diffY = d.source.y - d.target.y
                if (diffX !== 0) angle = Math.atan(diffY / diffX)
                if (diffX > 0) angle = Math.PI + angle
                let shift = 20
                let number = (d.sameLink.all - 2 * d.sameLink.current)
                if (diffX > 0) number = number * (-1)
                let shiftQ = number * 22
                let L = Math.sqrt(diffX * diffX + diffY * diffY)
                let t1 = (L - 25) / L
                if (L < 700) t1 = arrowJSON['' + Math.ceil(Math.abs(number))][Math.ceil(L*2 - 170)]
                let t2 = t1 - 0.1
                let x1 = d.source.x
                let y1 = d.source.y
                let x2 = (d.source.x + d.target.x) / 2 - shiftQ * Math.sin(angle)
                let y2 = (d.source.y + d.target.y) / 2 + shiftQ * Math.cos(angle)
                let x3 = d.target.x
                let y3 = d.target.y
                let Bx1 = (1 - t1) * (1 - t1) * x1 + 2 * t1 * (1 - t1) * x2 + t1 * t1 * x3
                let By1 = (1 - t1) * (1 - t1) * y1 + 2 * t1 * (1 - t1) * y2 + t1 * t1 * y3
                let Bx2 = (1 - t2) * (1 - t2) * x1 + 2 * t2 * (1 - t2) * x2 + t2 * t2 * x3
                let By2 = (1 - t2) * (1 - t2) * y1 + 2 * t2 * (1 - t2) * y2 + t2 * t2 * y3
                let dopAngle = Math.PI / 2
                if (Bx1 - Bx2 !== 0) dopAngle = Math.atan((By1 - By2) / (Bx1 - Bx2))
                if (diffX > 0) dopAngle = Math.PI + dopAngle
                let dop = (angle - dopAngle)
                let x = Bx1 - shift * Math.cos(angle - dop)
                let y = By1 - shift * Math.sin(angle - dop)
                return 'translate(' + x + ',' +
                    y + ') ' +
                    'rotate(' + 180 / Math.PI * (angle - dop) +
                    ')'
            })
    }

    createLinks() {
        const link = this.graphSelection.selectAll('.link').data(this.graph.links)
        link.enter().insert('g').attr('class', 'marker').append('polygon').attr('points', '14,0 -6,6 -6,-6')
            .attr('style', 'fill:#999')

        link.enter().insert('path')
            .attr('class', 'link')
            .attr('x1', d => d.source.x)
            .attr('y1', d => d.source.y)
            .attr('x2', d => d.target.x)
            .attr('style', 'stroke:#999;stroke-width:4px;fill: none;')
            .attr('d', d => {
                return  'M' + (d.source.x) + ',' + (d.source.y) + ' L'
                    + (d.target.x) + ',' + (d.target.y)
            })


        link.exit().remove()
    }

    createNodes(mouseenter, mouseleave) {
        const node = this.graphSelection.selectAll('.node').data(this.graph.nodes)
        const vm = this

        const gNode = node.enter().insert('g').attr('class', 'node')
            .attr("transform", function(d) { return "translate(" + d.x + "," + d.y + ")"; })
            .on('mouseenter', mouseenter)
            .on('mouseleave', mouseleave)

        this.graphSelection.append('text')
            .attr('class', 'test-icon')
            .attr("font-family", "'Font Awesome 5 Free'")
            .attr("font-weight", 800)
            .attr("fill","black")
            .attr('style', 'pointer-events: none;-webkit-touch-callout: none;-webkit-user-select: none;-khtml-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;')
            .attr('font-size',  25 + 'px')
            .text('');

        gNode.insert('circle')
            .attr('class', 'stroke')
            .attr('style', d => {
                d.fx = d.x
                d.fy = d.y
                if (d.element === 'between') return 'pointer-events: fill; fill:#f2f2f2; stroke:white;stroke-width:4px'
                if (d.index === 0) {
                    return 'pointer-events: fill; fill:' + vm.ontology.nodes[d.name].color + '; stroke:#AC3B61;stroke-width:4px'
                } else {
                    return 'pointer-events: fill; fill:' + vm.ontology.nodes[d.name].color + '; stroke:white;stroke-width:4px'
                }

            })
            .attr('r', d=> {
                if (d.element === 'between') return 20
                return 28
            })

        gNode.append('text')
            .attr("font-family", "'Font Awesome 5 Free'")
            .attr("font-weight", 800)
            .attr('x', (d) => {
                if (d.element === 'between') return 0
                let text = vm.graphSelection.select('.test-icon').text(String.fromCharCode(parseInt(vm.ontology.nodes[d.name].icon.unicode)))
                let size = text.node().getBBox()
                return -size.width/2
            })
            .attr('y', (d) => {
                if (d.element === 'between') return 0
                // console.log(String.fromCharCode(parseInt(vm.ontology.nodes[d.name].icon.unicode)))
                let text = vm.graphSelection.select('.test-icon').text(String.fromCharCode(parseInt(vm.ontology.nodes[d.name].icon.unicode)))
                let size = text.node().getBBox()
                return -(size.height)/2 - size.y - 1
            })
            .attr("fill","white")
            .attr('style', 'pointer-events: none;-webkit-touch-callout: none;-webkit-user-select: none;-khtml-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;')
            .attr('font-size', function(d) {    if (vm.ontology.nodes[d.name].icon.unicode.length === 0) return 0
            else return 25 + 'px'} )
            .text(function(d) {
                if (d.element === 'between') return ''
                if (vm.ontology.nodes[d.name].icon.unicode.length === 0) return ''
                else return String.fromCharCode(parseInt(vm.ontology.nodes[d.name].icon.unicode))
            });

        this.graphSelection.selectAll('.test-icon').remove()

        gNode.insert('text')
            .attr('class', 'text-value')
            .attr('x', 0)
            .attr("contentEditable", true)
            .attr('y', '.31em')
            .attr('style', 'pointer-events: none;-webkit-touch-callout: none;-webkit-user-select: none;-khtml-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;')
            .attr('font-size',d => {
                if (d.element === 'between') return '18px'
                return '11px'})
            .attr('fill', d => {
                if (d.element === 'between') return 'black'
                return 'white'})
            .attr('font-weight', 'bold')
            .attr('text-anchor', 'middle')
            .text(d => {
                if (d.element === 'between') return '...'
                if (vm.ontology.nodes[d.name].icon.unicode.length === 0) {
                    return Utils.cutString(d.name, 6)
                }
                else return ''
            })


        gNode.insert('circle')
            .attr('style', d => {
                if (d.element === 'first')  return 'pointer-events: fill; fill:#AC3B61; stroke:none;'
                if (d.element === 'second')  return 'pointer-events: fill; fill:#3BAC61; stroke:none;'
            })
            .attr('r', d => {
                if (d.element === 'first') return 11
                if (d.element === 'second') return 11
                return 0
            })
            .attr('cx', 56 / 2 * Math.cos(Math.PI / 4))
            .attr('cy', 56 / 2* Math.cos(Math.PI / 4))

        gNode.insert('text')
            .attr('style', 'pointer-events: none;-webkit-touch-callout: none;-webkit-user-select: none;-khtml-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;')
            .attr('font-size', '11px')
            .attr('fill', 'white')
            .attr('font-weight', 'bold')
            .attr('text-anchor', 'middle')
            .attr('x', 56 / 2* Math.cos(Math.PI / 4))
            .attr('y', 56 / 2* Math.cos(Math.PI / 4) + 3)
            .text(d => {
                if (d.element === 'first') return '1'
                if (d.element === 'second') return '2'
                else return ''
            })

        return gNode
    }
}