<template>
  <div class="container">
      {{options.atributes}}
      <el-select v-model="valueInput"
                 size="medium"
                 @change="changeSelect($event)"
                 :style="{width: currentWidth + 'px'}"
                 :multiple="multi"
                 :multiple-limit="right.length"
                 :placeholder="$t('Выберите значение')">
        <el-option
                style="width: 100%;"
                v-for="(option, index) in options"
                :key="index"
                :label="'text' in option ? option.name+' '+option.text : option.name"
                :value="typeof option.id !== 'undefined' ? '' + option.id : option.name">
        </el-option>
      </el-select>
    </div>
</template>

<script>
export default {
  name: "SelectList",
  props: ["label", "value", "options", "multi", "right"],
  emits: ["input"],
  data () {
    return {
      valueInput: this.value,
      currentWidth: 160
    }
  },
    mounted() {
      console.log('опции в ответе',this.options);
      if (typeof this.value !== "undefined") {
        if (this.multi) {
          if (this.value.length < 4) {
            this.currentWidth = this.value.length * 70 + 160
          }
        }
      }
    },
    methods: {
    changeSelect(value) {
      this.$emit('input', value)
      if (this.multi) {
        if (value.length < 4) {
          this.currentWidth = value.length * 70 + 160
        }
      }
    }
  }
};
</script>

<style scoped>
/*.container {*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  width: 20rem;*/
/*}*/
/*.container label {*/
/*  font-weight: 800;*/
/*}*/
/*.container select {*/
/*  height: 45px;*/
/*  padding: 10px;*/
/*  background: #f5f7f9;*/
/*  border-radius: 10px;*/
/*}*/
</style>