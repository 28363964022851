<template>
    <!--        {{answerJson}}-->
    <DynamicRenderer
            v-bind:typeSchema="typeSchema"
            :data="data"
            @input="updateData($event)"
    />

</template>

<script>
    import DynamicRenderer from "./DynamicRenderer";
    import CaseController from "../../controllers/case.controller";
    import CaseSessionController from "../../controllers/caseSession.controller";
    import CashController from "../../controllers/cash.controller";

    const rawCaseText = ''

    export default {
        name: "TextWithChoises",
        components: {
            DynamicRenderer
        },
        emits: ["input"],
        props: ['corzina'],
        data() {
            return {
                html: rawCaseText,
                editedHtml: rawCaseText,
                typeSchema: [],
                data: {},
                answerJson: {},
                caseController: null,
                caseSessionController: null,
                cashController: null

            }
        },
        beforeMount() {
            // console.log('корзина в тексте ответа', this.corzina)
            this.cashController = new CashController()
            let cash = this.cashController.getCashStore()

            if (cash.answerState !== undefined) {
                if (cash.answerState !== this.data) {
                    console.log('answerState = ', cash.answerState)
                    this.data = cash.answerState
                }
            }
        },
        mounted() {
            this.cashController = new CashController()
            let cash = this.cashController.getCashStore()
            if (cash.answerState) {
                this.data = cash.answerState
            }

            this.caseSessionController = new CaseSessionController()
            this.caseController = new CaseController()
            const session = this.caseSessionController.getCurrentSessionStore()
            if (!session.adminSession) {
                const caseId = session.caseId
                this.caseController.caseById(caseId).then((response) => {
                    this.answerJson = JSON.parse(response.data.answerJson)
                    console.log('instr data content=', this.answerJson)
                    this.typeSchema = this.answerJson
                    this.putCorzinaToSchema(this.typeSchema)
                })
            }

        },
        methods: {
            updateData({state, value}) {
                this.data[state] = value;
                console.log('кэш к текстчойзе=', this.data)
                let cash = this.cashController.getCashStore()
                if (cash.answerState !== this.data) {
                    cash.answerState = this.data
                }
                this.cashController.setCashStore(cash)
                this.$emit("input", this.data);
            },
            putCorzinaToSchema(schema) {
                schema.map((paragraph) => {
                    paragraph.map((el) => {
                        // eslint-disable-next-line no-prototype-builtins
                        if (el.hasOwnProperty('options')) {
                            if (el.options === 'this.corzina') {
                                el.options = this.corzina
                            }
                        }
                    })
                })
                // return resultSchema
            }

        }
    }
</script>

<style scoped>
    .text-block {
        white-space: pre-line;
    }
</style>
