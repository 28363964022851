<template>
  <div style="padding: 16px" v-loading="waitForSearch">

    <el-row>
      <a>
        <el-select v-model="neoSearch.label"
                   :no-match-text="$t('Ничего не найдено')"
                   multiple
                   :placeholder="$t('Любой тип')">
          <el-option
              v-for="item in avaliableLabels"
              :key="item"
              :label="ontology.nodes[item].label"
              :value="item">
          </el-option>
        </el-select>

        <el-select v-model="neoSearch.property"
                   multiple
                   style="margin-left: 8px"
                   value-key="attribute"
                   v-if="neoSearch.label !== [] && neoSearch.type === 'noID'"
                   :no-match-text="$t('Ничего не найдено')"
                   :placeholder="$t('Любой атрибут')">
          <el-option
                  v-for="item in createAttributes"
                  :key="item.attribute"
                  :label="item.attribute"
                  :value="item">
            <span style="float: left">{{ item.attribute }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.label }}</span>
          </el-option>
        </el-select>
      </a>
      <a style="margin-left: 8px">
        <el-radio-group v-model="neoSearch.type">
          <el-radio-button label="noID">{{ $t('Общий') }}</el-radio-button>
          <el-radio-button label="ID">{{ $t('По ID') }}</el-radio-button>
        </el-radio-group>
      </a>
    </el-row>

    <el-row>
      <el-col :span="21">
        <el-input placeholder="..." v-model="neoSearch.query"></el-input>
      </el-col>
      <el-col :span="3">
        <el-button @click="startSearchFromNeo" type="primary">{{ $t('Поиск') }}</el-button>
      </el-col>
    </el-row>
    <div  :style="{width: '100%', height: (height - 250) + 'px', overflow: 'auto', 'margin-top': '8px'}">
      <el-table
              :data="tableData"
              border
              @sort-change = "sortChange"
              @expand-change = "expandChange"
              :style="{width: '100%'}"
      >
        <el-table-column type="expand">
          <template #default="props">
            <subgraph-row @add-to-cart="addToCart" :ref="'subgraph_row' + props.row.number">
            </subgraph-row>
          </template>
        </el-table-column>
        <el-table-column
                prop="number"
                label="">
          <template #default="props">
            Подграф {{props.row.number + 1}}
          </template>
        </el-table-column>
      </el-table>
    </div>



    <el-pagination
        background
        class="mt-3"
        type="primary"
        layout="prev, pager, next"
        :total="totalItems"
        :current-page = "page"
        :page-size= "perPage"
        @current-change = "pageChange">

    </el-pagination>
  </div>
  <div  v-if="waitForSearch" :style="{ 'z-index': 2000, position: 'absolute',  left: '0px', top: height/2+'px' , width:'100%', height: 200 + 'px', 'text-align': 'center'}">

    <p style="font-size:16px; text-align: center;margin-top: 0px">
      {{socketProgress}}
    </p>
  </div>
</template>

<script>
  import VisController from '../../controllers/vis.controller'
  import SubgraphRow from "./SubgraphRow";
  import CaseSessionController from "../../controllers/caseSession.controller";
  import OntologyController from "../../controllers/ontology.controller";
  import CashController from "../../controllers/cash.controller";

  export default {
  name: 'NeoSearchSubgraph',
    components: {
      SubgraphRow
    },
  data() {
    return {
      height: Math.max(
              document.documentElement.clientHeight,
              window.innerHeight || 0
      ) - 150,
      neoSearch: {
        label: [],
        property: [],
        type: 'noID',
        query: ''
      },
      tableData: [],
      ontology: {
        nodes: {},
        links: {}
      },
      elementCard: {
        name: '',
        attributes: []
      },
      avaliableLabels: [],
      subgraphElements: {nodes: {}, links: {}},
      currentScheme: null,
      caseSessionController: null,
      cashController: null,
      visController: null,
      ontologyController: null,
      waitForSearch: false,
      selectedItem: null,
      page: 1,
      start: 0,
      end: 0,
      perPage: 25,
      totalItems: 0,
      sessionController: null
    }
  },
    props: {
      data: {
        type: Object,
        default: function () {
          return {}
        }
      }
    },
  computed: {
    createAttributes () {
      let attrs = []
      for (let label of this.neoSearch.label) {
        for (let key of Object.keys(this.ontology.nodes[label].properties)) {
          attrs.push({
            attribute: key,
            label: label
          })
        }
      }
      return attrs
    }
  },
    beforeMount() {
      this.ontologyController = new OntologyController()
      this.caseSessionController = new CaseSessionController()
      this.cashController = new CashController()
      this.ontology = this.ontologyController.getOntology()
    },
    beforeUnmount: function() {
      let cash = this.cashController.getCashStore()
      cash.listSubgraph = {}
      cash.listSubgraph.neoSearch = this.neoSearch
      cash.listSubgraph.page = this.page
      cash.listSubgraph.data = this.data
      this.cashController.setCashStore(cash)
    },
    mounted() {
    this.page = 1
      this.visController = new VisController()
      let cash = this.cashController.getCashStore()
      if (cash.listSubgraph === null) {
        this.init(this.data)
      } else {
        this.cashList()
      }
  },
  methods: {
    cashList() {
      let cash = this.cashController.getCashStore()
      this.neoSearch = cash.listSubgraph.neoSearch
      this.page = cash.listSubgraph.page
      let scheme = cash.listSubgraph.data
      this.avaliableLabels = []
      for (let node of scheme.scheme.nodes) {
        if (!this.avaliableLabels.includes(node.name)) {
          this.avaliableLabels.push(node.name)
        }
      }
      this.currentScheme = scheme
      this.totalItems = 0
      this.columnSort = ''
      this.orderSort = ''
      this.getRows(this.page, this.columnSort, this.orderSort, false, false)
    },
    init (scheme) {
      this.avaliableLabels = []
      for (let node of scheme.scheme.nodes) {
        if (!this.avaliableLabels.includes(node.name)) {
          this.avaliableLabels.push(node.name)
        }
      }
      this.currentScheme = scheme
      this.totalItems = 0
      this.page = 1
      this.columnSort = ''
      this.orderSort = ''
      this.getRows(this.page, this.columnSort, this.orderSort, scheme.isNew, false)
    },
    startSearchFromNeo () {
      const vm = this
      if (this.neoSearch.query.length > 0) {
        vm.tableData = []
        this.getRows(this.page, this.columnSort, this.orderSort, false, true)
      }
    },
    getRows(page, column, order, isNew, isSearch) {
      //console.log(page, column, order, isNew, isSearch)
      const vm = this
      this.waitForSearch = true
      let cash = this.cashController.getCashStore()
      let session = this.caseSessionController.getCurrentSessionStore()
      const socket = this.$io
      //console.log(socket.connected)
      socket.on('subgraph-list-message', (data) => {
        if (data.sessiodId === session._id) {
          vm.socketProgress = data.message
        }
      })
      this.visController.searchSubgraphByString({
        data: this.currentScheme,
        revealed: cash.revealed.elements,
        ontology: this.ontology,
        dataSearch: this.neoSearch,
        isNew: isNew,
        sessionId: session._id,
        isSearch: isSearch,
        sort: {
          column: column,
          order: order
        },
        pagination: {
          page: page - 1,
          perPage: 25
        },
        type: 'scheme'
      }).then((response) => {
        const data = response.data
        // console.log(data)
        if (data.total === 0) vm.$emit('show-notification', {name: 'noSubGraphs', args: {}})
        vm.totalItems = data.total
        vm.waitForSearch = false
        vm.tableData = data.rows
        vm.$emit('show', 'NeoSearchSubgraph')
        socket.off('subgraph-list-message')
      })
    },
    expandChange(row) {
      // console.log('row', row)
      // console.log(this.$refs)
      // console.log(this.currentScheme)
      const vm = this
      setTimeout(function () {
        vm.$refs['subgraph_row' + row.number].init(row, vm.currentScheme)
      }, 100)
    },
    sortChange (sort){
      // console.log(sort.column, sort.prop, sort.order)
      if (sort.column !== null) {
        if (sort.order === 'ascending') this.orderSort = 'ASC'
        else this.orderSort = 'DESC'
        this.columnSort = sort.prop
        this.getRows(this.page, this.columnSort, this.orderSort, false, false)
      }
    },
    pageChange (page) {
      if (page !== null) {
        this.page = page
        // console.log(page)
        this.getRows(this.page, this.columnSort, this.orderSort, false, false)
      }
    },
    addToCart (obj) {
      let attrName = ''
      for (let attr of obj.element.attributes) {
        if (attr.name.includes('name') || attr.name.includes('Name')) {
          attrName = attr.name + ': ' + attr.value
        }
      }
      this.$emit('add-to-cart', {
        name: obj.element.name,
        id: obj.element.id,
        type: obj.node.name,
        text: attrName,
        class:'element-cart-card'
      })
    }
  },
  watch: {
    selectedItem: function (newValue) {
      this.elementCard.attributes = []
      let element = this.subgraphElements.nodes[newValue.short]
      this.elementCard.name = newValue.name + ' : ' + element._id
      for (let key of Object.keys(element)) {
        if (key !== '_id') {
          this.elementCard.attributes.push({
            name: key,
            value: element[key]
          })
        }
      }
    }
  }
};
</script>

<style scoped>

  >>> .el-transfer-panel{
    width: 300px;
  }


</style>
