import Neo4jService from '../services/neo4j.service'
import SchemeService from '../services/scheme.service'
import basic from '../store/basic'
import {store} from "../store";
import CaseSessionController from "./caseSession.controller";

export default class SchemeController {
    constructor(type) {
        this.type = type
        this.ontology = store.getters['ontologyStore/getOntologyStore']
        this.caseSessionController = new CaseSessionController()
    }

    addScheme(data) {
        return SchemeService.addScheme(data).then((response) => {
                store.commit('sessionStore/sessionAddShemeById', response.data.id)
                store.commit('sessionStore/sessionAddLog', {action: 'addScheme',
                    name: 'Добавление схемы', time: Date.now()})
                this.caseSessionController.updateCaseSession(store.getters['sessionStore/getCurrentSession'])
            return response
            }
        ).catch( (err) => { return err.response})
    }

    visScheme(data) {
        store.commit('sessionStore/sessionAddLog', {action: 'visScheme',
            name: 'Графовый запрос', time: Date.now()})
        store.commit('sessionStore/sessionAddLogQuery')
        this.caseSessionController.updateCaseSession(store.getters['sessionStore/getCurrentSession'])
        return SchemeService.visScheme(data)
    }

    changeScheme(data) {
        return SchemeService.changeScheme(data).then((response) => {
            store.commit('sessionStore/sessionAddLog', {action: 'changeScheme',
                name: 'Сохранение схемы', time: Date.now()})
            this.caseSessionController.updateCaseSession(store.getters['sessionStore/getCurrentSession'])
            return response
        }).catch( (err) => { return err.response})
    }

    deleteScheme(data) {
        return SchemeService.deleteScheme(data).then((response) => {
            store.commit('sessionStore/SessiondeleteShemebyId', response.data.id)
            store.commit('sessionStore/sessionAddLog', {action: 'deleteScheme',
                name: 'Удаление схемы', time: Date.now()})
            this.caseSessionController.updateCaseSession(store.getters['sessionStore/getCurrentSession'])
            return response
        }).catch( (err) => { return err.response})
    }

    loadAllSchemes() {
        return SchemeService.loadAllSchemes()
    }

    createScheme() {
        SchemeService.createSchemeStore([], [], [], [], [], [], this.type)
    }

    getTypeLink (mousedown, mouseup) {
        let linkSource = mousedown
        let linkTarget = mouseup
        if (linkSource.part !== null || linkTarget.part !== null) {
            if (linkSource.name + ':' + linkSource.part + ':' + linkTarget.name + ':'
                + linkTarget.part in basic.ruleList) {
                return {
                    source: linkSource,
                    target: linkTarget,
                    libType: 'basic',
                    links: [basic.ruleList[linkSource.name + ':' + linkSource.part + ':' + linkTarget.name + ':'
                    + linkTarget.part]]
                }
            } else if (linkTarget.name + ':' + linkTarget.part + ':' + linkSource.name + ':'
                + linkSource.part in basic.ruleList) {
                let copy = linkSource
                linkSource = linkTarget
                linkTarget = copy
                return {
                    source: linkSource,
                    target: linkTarget,
                    libType: 'basic',
                    links: [basic.ruleList[linkSource.name + ':' + linkSource.part + ':' + linkTarget.name + ':'
                    + linkTarget.part]]
                }
            }
        } else {
            if (linkSource.name + ':' + linkTarget.name in this.ontology.ruleList) {
                return {
                    source: linkSource,
                    target: linkTarget,
                    libType: 'ontology',
                    links: this.ontology.ruleList[linkSource.name + ':' + linkTarget.name]
                }
            } else if (linkTarget.name + ':' + linkSource.name in this.ontology.ruleList) {
                let copy = linkSource
                linkSource = linkTarget
                linkTarget = copy
                return {
                    source: linkSource,
                    target: linkTarget,
                    libType: 'ontology',
                    links: this.ontology.ruleList[linkSource.name + ':' + linkTarget.name]
                }
            }
        }
        return null
    }

    testRule(mousedown, mouseup, linksInput, linkInfo) {
        let linkSource = mousedown
        let linkTarget = mouseup
        let scheme = SchemeService.getSchemeStore('constructor')
        // let currentLinkInfo = null
        let countLinks = 0
        let links = linksInput
        let idLink = linkInfo.type
        if (linksInput === null) links = scheme.links
        if (linkInfo.maxST > 0) {
            for (let l of links) {
                if (l.sourcePart === linkSource.part && l.source.element === linkSource.element && l.source.index === linkSource.index
                    && l.source.name === linkSource.name
                    && l.target.name === linkTarget.name && l.target.part === linkTarget.part
                    && l.name == idLink) {
                    countLinks++
                }
                if (countLinks === linkInfo.maxST) {
                    return null
                }
            }
        }
        countLinks = 0
        if (linkInfo.maxSameST > 0) {
            for (let l of links) {
                if (l.sourcePart === linkSource.part && l.source.element === linkSource.element && l.source.index === linkSource.index
                    && l.source.name === linkSource.name
                    && l.target.name === linkTarget.name && l.target.part === linkTarget.part && l.target.index === linkTarget.index
                    && l.name == idLink) {
                    countLinks++
                }
                if (countLinks === linkInfo.maxSameST) {
                    return null
                }
            }
        }
        countLinks = 0
        if (linkInfo.maxTS > 0) {
            for (let l of links) {
                if (l.targetPart === linkTarget.part && l.target.element === linkTarget.element && l.target.index === linkTarget.index
                    && l.target.name === linkTarget.name
                    && l.source.name === linkSource.name && l.sourcePart === linkSource.part
                    && l.name == idLink) {
                    countLinks++
                }
                if (countLinks === linkInfo.maxTS) {
                    return null
                }
            }
        }
        countLinks = 0
        if (linkInfo.maxSameTS > 0) {
            for (let l of links) {
                if (l.targetPart === linkTarget.part && l.target.element === linkTarget.element && l.target.index === linkTarget.index
                    && l.target.name === linkTarget.name
                    && l.source.name === linkSource.name && l.sourcePart === linkSource.part && l.source.index === linkSource.index
                    && l.name == idLink) {
                    countLinks++
                }
                if (countLinks === linkInfo.maxSameTS) {
                    return null
                }
            }
        }
        return {
            source: linkSource,
            target: linkTarget,
            link: idLink
        }

    }

    getPathCount(data) {
        return Neo4jService.getPathCount(data)
    }


    newScheme(links, nodes, attributes, values, operators, logics) {
        return SchemeService.newScheme(links, nodes, attributes, values, operators, logics)
    }


    getSchemeStore() {
        return SchemeService.getSchemeStore(this.type)
    }

    setSchemeStore(links, nodes, attributes, values, operators, logics, functions) {
        SchemeService.setSchemeStore(links, nodes, attributes, values, operators, logics, functions, this.type)
    }

    getNodeCount(data) {
        return Neo4jService.getNodeCount(data)
    }

    getMatch(path, scheme) {
        return SchemeService.getMatch(path, scheme)
    }

    loadLinks(links, nodes, attributes, values, operators, logics, functions) {
        return SchemeService.loadLinks(links, nodes, attributes, values, operators, logics, functions)
    }

    checkScheme(data) {
        return SchemeService.checkScheme(data)
    }
}