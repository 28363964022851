import SchemeService from '../services/scheme.service'
import Neo4jService from '../services/neo4j.service'

export default class ConstructorController {
    constructor() {
    }

    async counterPath(paths, scheme) {
        let sortedPaths = []
        let whereObjects = SchemeService.constructWhere(scheme)
        for (let path of paths) {
            let query = ''
            let whereArray = SchemeService.getWhereArray([path], whereObjects, []).array
            let match = SchemeService.getMatch(path, scheme)
            let center = scheme.getNode(path[0].node)
            query += 'MATCH ' + match + '\n'
            if (whereArray.length > 0) query += 'WHERE ' + whereArray.join(' AND ') + '\n'
            query += 'WITH ' + center.short + ' LIMIT 400000' + '\n'
            query += 'RETURN count(' + center.short + ')'
            await Neo4jService.getPathCount({
                query: query,
                path: path
            }).then(function (response) {
                // handle success
                sortedPaths.push({
                    path: response.data.path,
                    count: response.data.count
                })
            })
        }
        await sortedPaths.sort(function (a, b) {
            if (a.count > b.count) {
                return 1
            }
            if (a.count < b.count) {
                return -1
            }
            return 0
        })
        return sortedPaths
    }
    makeQuery(scheme) {
        return SchemeService.makeQuery(scheme)
    }
}