<template>
    <div :style="{width: '100%', textAlign: 'left'}">
        <div class="el-menu-left">
            <el-menu default-active="instruction" width="64px" :collapse="true" @select="changeMenu" ref="sessionMenu">
                <el-menu-item index="instruction" v-if="!adminSession">
                    <div v-if="menuIndex === 'instruction'" >
                        <i class="icon-active fas fa-file-alt fa-2x"></i>
                    </div>
                    <div v-else>
                        <i class="icon-inactive fas fa-file-alt fa-lg"></i>
                    </div>
                    <template #title>{{ $t('Описание') }}</template>
                </el-menu-item>
                <el-menu-item index="neosearch">
                    <div v-if="menuIndex === 'neosearch'">
                        <i class="icon-active fas fa-search fa-2x"></i>
                    </div>
                    <div v-else>
                        <i class="icon-inactive fas fa-search fa-lg"></i>
                    </div>
                    <template #title>{{ $t('Поиск по базе') }}</template>
                </el-menu-item>
                <el-menu-item index="merged">
                    <div v-if="menuIndex === 'merged'">
                        <i class="icon-active fas fa-arrows-alt fa-2x"></i>
                    </div>
                    <div v-else>
                        <i class="icon-inactive fas fa-arrows-alt fa-lg"></i>
                    </div>
                    <template #title>{{ $t('Конструктор') }}</template>
                </el-menu-item>
                <el-menu-item index="answer" v-if="!adminSession">
                    <div v-if="menuIndex === 'answer'">
                        <i class="icon-active fas fa-check-circle fa-2x"></i>
                    </div>
                    <div v-else>
                        <i class="icon-inactive fas fa-check-circle fa-lg"></i>
                    </div>
                    <template #title>{{ $t('Ответ') }}</template>
                </el-menu-item>
                <!--            <el-menu-item index="chat" @click="goToTg">-->
                <!--              <div v-if="menuIndex === 'chat'">-->
                <!--                <i class="icon-active fas fa-comments fa-2x"></i>-->
                <!--              </div>-->
                <!--              <div v-else>-->
                <!--                <i class="icon-inactive fas fa-comments fa-lg"></i>-->
                <!--              </div>-->
                <!--              <template #title>Чат</template>-->
                <!--            </el-menu-item>-->
            </el-menu>
        </div>
        <div>
            <div :style="{'margin-left': '64px', width: 'auto'}">
                <el-collapse v-model="activeNames" @change="handleChange">
                    <el-collapse-item :title="$t('Избранное')" name="cart" id="favorites">
                        <el-carousel
                                v-if="elements.length > 0 && activeNames.includes('cart')"
                                class="carousel-cart"
                                :autoplay="false"
                                :height="(heightCart + 38) + 'px'"
                                :style="{width: '100%'}">
                            <el-carousel-item v-for="row in getCarousels()" :key="row">
                                <el-row>
                                    <el-col v-for="element in getElementsPart(row - 1)" :key="element.name" :span="4"
                                            :style="{padding: '1px'}">

                                        <el-popover
                                                placement="bottom-start"
                                                width="200"
                                                trigger="hover">
                                            <ElementInfo
                                                    :element="{id: element.id, name: element.name.split(' : ')[0], element: 'ontology'}"></ElementInfo>
                                            <template #reference>

                                                <ElementCart @change="changeClass" @delete="deleteElement"
                                                             :element="element"
                                                             :icon="ontology.nodes[element.name.split(' : ')[0]].icon.name"> </ElementCart>
<!--                                                <el-card-->

<!--                                                        :class="element.class + ' card'"-->
<!--                                                        :id="element.name"-->
<!--                                                        @click="changeClass(element)"-->
<!--                                                        :style="{width: (width/6-24) + 'px', heightCart: heightCart + 'px'}">-->
<!--                                                    <template #header style="">-->
<!--                                                        <div class="clearfix">-->
<!--                                                            <span class="text">{{element.name}}</span>-->
<!--                                                            <el-button @click="deleteElement(element)"-->
<!--                                                                       style="float: right; padding: 3px 0" type="text">-->
<!--                                                                ✕-->
<!--                                                            </el-button>-->
<!--                                                        </div>-->
<!--                                                    </template>-->

<!--                                                    <div class="text">-->
<!--                                                        {{element.text}}-->
<!--                                                    </div>-->
<!--                                                </el-card>-->

                                            </template>
                                        </el-popover>

                                    </el-col>
                                </el-row>
                            </el-carousel-item>
                        </el-carousel>
                    </el-collapse-item>
                </el-collapse>

                <Instruction @drop-cart-select="dropSelect" @add-to-cart="addElement" v-if="component === 'instruction'"
                             ref="component"></Instruction>
                <NeoSearch style="margin: 16px" @drop-cart-select="dropSelect" @add-to-cart="addElement"
                           v-else-if="component === 'neosearch'" ref="component"></NeoSearch>
                <Construalisator @drop-cart-select="dropSelect" @add-to-cart="addElement"
                                 v-else-if="component === 'merged'" ref="component"/>
                <Answer @drop-cart-select="dropSelect" @add-to-cart="addElement" @login="$emit('login')"
                        v-else-if="component === 'answer'" ref="component"></Answer>
                <!--                    <ExternaLink @drop-cart-select="dropSelect" @add-to-cart="addElement" v-else-if="component === 'chat'" ref="component"></ExternaLink>-->
                <!--        <component @drop-cart-select="dropSelect" @add-to-cart="addElement" :is="component" ref="component"></component>-->
            </div>
        </div>


    </div>
</template>

<script>

    import Instruction from './Help/Instruction'
    import Answer from './Answer/Answer'
    import NeoSearch from "./NeoSearch/NeoSearch";
    import CaseSessionController from "../controllers/caseSession.controller";
    import SchemeController from "../controllers/scheme.controller";
    import ElementInfo from "./Constructor/ElementInfo";
    import ElementCart from "./Constructor/ElementCart";
    import Construalisator from "./Constructor/Construalisator";
    import {ref} from 'vue'
    import VisController from "../controllers/vis.controller";
    import OntologyController from "../controllers/ontology.controller";
    import CashController from "../controllers/cash.controller";

    export default {
        name: "main-component",
        data() {
            return {
                width: Math.max(
                    document.documentElement.clientWidth,
                    window.innerWidth || 0
                ),
                adminSession: false,
                activeNames: ref(['']),
                heightCart: 100,
                classCard: 'element-cart-card',
                elements: [],
                menuIndex: [],
                component: 'instruction',
                caseSessionController: null,
                schemeController: null,
                visController: null,
                ontologyController: null,
                cashController: null,
                ontology: null,
                backColor: '#aabb44',
            }
        },
        components: {
            Construalisator,
            ElementInfo,
            ElementCart,
            NeoSearch,
            Instruction,
            Answer
        },
        mounted() {
            this.caseSessionController = new CaseSessionController()
            this.cashController = new CashController()
            this.schemeController = new SchemeController('constructor')
            this.visController = new VisController()
            this.ontologyController = new OntologyController()

            this.adminSession = this.caseSessionController.getCurrentSessionStore().adminSession
            let cash = this.cashController.getCashStore()

            this.ontology = this.ontologyController.getOntology()


            this.component = cash.component
            this.elements = cash.cart
            this.menuIndex = this.component
            this.$refs.sessionMenu.open(this.component)

            const vm = this
            this.schemeController.loadAllSchemes()
                .then(function (response) {
                    vm.presentSchemes = response.data
                })
        },
        methods: {
            goToTg() {
                let session = this.caseSessionController.getCurrentSessionStore()
                // session.chatLink
                window.open(session.chatLink)
            },
            changeMenu(value) {
                this.menuIndex = value
                this.component = value
                let cash = this.cashController.getCashStore()
                if (cash.component !== value) {
                    cash.component = value
                }
                this.cashController.setCashStore(cash)
            },
            changeClass(el) {
                for (let i = 0; i < this.elements.length; i++){
                    if (el.name === this.elements[i].name) {
                        let oldClass = this.elements[i].class
                        for (let el of this.elements) {
                            el.class = 'element-cart-card'
                        }
                        if (oldClass === 'element-cart-card') {
                            this.elements[i].class = 'element-cart-card element-cart-card-selected'
                            if (this.component === 'merged') {
                                this.$refs.component.cartElementSelected(this.elements[i])
                            }
                        } else {
                            this.elements[i].class = 'element-cart-card'
                            if (this.component === 'merged') {
                                this.$refs.component.cartElementSelected(null)
                            }
                        }
                    }
                }
            },
            dropSelect() {
                for (let el of this.elements) {
                    el.class = 'element-cart-card'
                }
                if (this.component === 'constructor') {
                    this.$refs.component.cartElementSelected(null)
                }
            },
            getCarousels() {
                return Math.ceil(this.elements.length / 6)
            },
            getElementsPart(i) {
                if ((i + 1) * 6 > this.elements.length) {
                    // console.log(this.elements.slice(i * 6, this.elements.length))
                    return this.elements.slice(i * 6, this.elements.length)
                } else {
                    // console.log(this.elements.slice(i * 6, (i + 1) * 6))
                    return this.elements.slice(i * 6, (i + 1) * 6)
                }
            },
            deleteElement(element) {
                this.caseSessionController.addLogStore('deleteCartElement', 'Удаление из избранного')
                this.elements.splice(this.elements.indexOf(element), 1)
                this.cashController.setCartStore(this.elements)
                this.caseSessionController.addLogCartStore(this.elements.length)
            },
            async addElement(newElement) {
                let alreadyAdded = false
                for (let element of this.elements) {
                    if (element.name === newElement.name) {
                        alreadyAdded = true
                    }
                }

                if (!alreadyAdded) {
                    this.activeNames = ref(['cart'])
                    let element = {
                        id: parseInt(newElement.id),
                        label: newElement.name.split(' : ')[0],
                        element: newElement.element
                    }
                    let response = await this.visController.getNode({element: element})
                    newElement.attributes = response.data.element.attributes
                    // newElement.name = this.ontology.nodes[response.data.element.label].label
                    newElement.text = this.ontology.nodes[response.data.element.label].label
                    if (newElement.attributes["Имя"] !== undefined){
                        newElement.text =newElement.text + ': '+newElement.attributes["Имя"]
                    }
                    if (newElement.attributes["Имя (Name)"] !== undefined) {
                        newElement.text =newElement.text + ': '+newElement.attributes["Имя (Name)"]
                    }
                    if (newElement.attributes["Наименование (Name)"] !== undefined) {
                        newElement.text =newElement.text + ': '+newElement.attributes["Наименование (Name)"]
                    }
                    this.caseSessionController.addLogStore('addCartElement', 'Добавление в избранное')
                    this.elements.push(newElement)
                    this.cashController.setCartStore(this.elements)
                    this.caseSessionController.addLogCartStore(this.elements.length)
                }
            }
        }
    }
</script>

<style scoped>


    .carousel-cart {
        background-color: #b6c6dc;
    }

    >>> .icon-inactive path{
        fill: #b6c6dc;
    }

    >>> .icon-active path{
        fill:  #123C69;
    }

    .text {
        font-size: 14px;
    }

    >>> .el-collapse-item__header{
      font-size: 16px;
      padding-left: 8px;
      margin-left: 0px;
      background-color: #b6c6dc
    }
    .el-menu-left {
      float: left;
      left: 0;
      top: 62px;
      position: sticky !important;
      z-index: 2003;
    }
    >>>.el-collapse-item__content {
        padding-bottom: 0px;
        font-size: 13px;
        color: #303133;
        line-height: 1.769230769230769
    }
    >>>.el-carousel__indicator--horizontal{
        padding: 0px 4px;
    }

</style>