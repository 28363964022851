<template>
  <div>
    <el-card>
<!--      <dvi v-if="type === 'first'">-->
<!--        Связей 1 c {{items.anotherLabel}}: {{items.first.length}}-->
<!--      </dvi>-->
<!--      <dvi v-else>-->
<!--        Связей 2 c {{items.anotherLabel}}: {{items.second.length}}-->
<!--      </dvi>-->
      <svg :style="{ left:0, right: 0}" height="120px" width="100%" xmlns="http://www.w3.org/2000/svg">
        <g id="gElement">

        </g>
      </svg>

      <div style="margin-top: 8px">
        <el-row type="flex" class="row-bg" justify="space-between">
          <el-col :span="12"><div class="grid-content bg-purple" style="text-align: left; font-size: 14px">
            <div v-if="type === 'first'" >
              {{ $t('Связей') }}: {{items.first.length}}
            </div>
            <div v-else>
              {{ $t('Связей') }}: {{items.second.length}}
            </div>
          </div></el-col>
          <el-col :span="12"><div class="grid-content bg-purple" style="text-align: right">
           <el-button @click="goToSubgraphs">{{ $t('Подробнее') }}</el-button>
          </div></el-col>
        </el-row>
      </div>

    </el-card>


  </div>
</template>

<script>
  import D3Controller from "../../controllers/d3.edges.window.controller";
  import OntologyController from "../../controllers/ontology.controller";

  export default {
  name: 'EdgesWindowElement',
  data() {
    return {
      height: 120,
      width: 300,
      graph: {
        nodes: [],
        links: []
      },
      d3Controller: null,
        showElementInfo: false,
        showElementInfoTimeout: null,
        ontologyController: null
    }
  },
    props: {
      items: {
        type: Object,
        default: function () {
          return {}
        }
      },
      path: {
        type: Object,
        default: function () {
          return {}
        }
      },
      type: {
        type: String,
        default: function () {
          return ''
        }
      },
    id: {
        type: String,
        default: function () {
            return ''
        }
    },
    selected: {
        type: String,
        default: function () {
            return ''
        }
    }
    },
  computed: {
  },
  mounted() {
    this.d3Controller = new D3Controller(this.$el.querySelector('svg'), this.width, this.height)
    this.ontologyController = new OntologyController()
    this.ontology = this.ontologyController.getOntology()
    this.createGraphic()

  },
  methods: {
      goToSubgraphs () {
        this.$emit('subgraphs', {
            type: this.type,
            anotherID: this.id
        })
      },
    createGraphic () {
      let x1 = 52
      let x2 = 242
      let y1 = 74
      let y2 = 74

        let another = ''
        if (this.type === 'first') another = 'second'
        else another = 'first'

      let name1 = ''
      let name2 = ''
      for (let node of this.path.nodes) {
        if (node.short === this.type) name1 = node.name
        if (node.short === another) name2 = node.name
      }


        if (this.path.ontologyLinks.length > 1) {


            this.graph.nodes.push({
                element: this.type,
                name: name1,
                x: x1,
                id: this.selected,
                index: 0,
                y: y1,
                icon: '',
            })
            this.graph.nodes.push({
                element: 'between',
                name: '',
                x: (x1 + x2)/2,
                y: (y1 + y2)/2,
                index: 1,
                icon: '',
            })
            this.graph.nodes.push({
                element: another,
                name: name2,
                id: this.id,
                x: x2,
                y: y2,
                index: 2,
                icon: '',
            })
            for (let link of this.path.ontologyLinks) {
                if (link.source.short === 'first') {
                    this.graph.links.push({
                        source: this.graph.nodes[0],
                        target: this.graph.nodes[1],
                        sameLink: {all: 0, current: 0}
                    })
                } else if (link.target.short === 'first') {
                    this.graph.links.push({
                        target: this.graph.nodes[0],
                        source: this.graph.nodes[1],
                        sameLink: {all: 0, current: 0}
                    })
                } else if (link.source.short === 'second') {
                    this.graph.links.push({
                        target: this.graph.nodes[1],
                        source: this.graph.nodes[2],
                        sameLink: {all: 0, current: 0}
                    })
                } else if (link.target.short === 'second') {
                    this.graph.links.push({
                        source: this.graph.nodes[1],
                        target: this.graph.nodes[2],
                        sameLink: {all: 0, current: 0}
                    })
                }
            }
        } else {
            this.graph.nodes.push({
                element: this.type,
                name: name1,
                id: this.selected,
                x: x1,
                y: y1,
                index: 0,
                icon: '',
            })
            this.graph.nodes.push({
                element: another,
                name: name2,
                id: this.id,
                x: x2,
                y: y2,
                index: 1,
                icon: '',
            })
            if (this.path.ontologyLinks[0].source.short === 'first') {
                this.graph.links.push({
                    source: this.graph.nodes[0],
                    target: this.graph.nodes[1],
                    sameLink: {all: 0, current: 0}
                })
            } else if (this.path.ontologyLinks[0].target.short === 'first') {
                this.graph.links.push({
                    target: this.graph.nodes[0],
                    source: this.graph.nodes[1],
                    sameLink: {all: 0, current: 0}
                })
            }
      }

        const vm = this
        function ontologyMouseEnter(d) {
            if (d.element !== 'between') {
                vm.d3Controller.event().preventDefault();
                d.showElementInfo = true
                vm.showElementInfoTimeout = setTimeout(function () {
                    if (d.showElementInfo) {
                        vm.$emit('show-info', d)
                    }
                }, 600)
            }
        }
        function ontologyMouseLeave(d) {
            vm.d3Controller.event().preventDefault();
            clearTimeout(vm.showElementInfoTimeout);
            d.showElementInfo = false
            vm.$emit('close-info', d)
        }


        this.d3Controller.updateGraph(this.graph)
      this.d3Controller.createLabel(this.items.anotherLabel, this.type)
      this.d3Controller.createLinks()
      this.d3Controller.createNodes(ontologyMouseEnter, ontologyMouseLeave)
      this.d3Controller.moveMarkers()

    }
  },
};
</script>

<style scoped>

  >>> .el-card__body {
    padding: 8px;
  }
  .vl {
    margin-left: 16px;
    margin-right: 16px;
    border-left: 1px solid #e2e2e2;
    height: 100px;
    display: inline-block;
  }

  >>> .spark {
    fill: none;
    stroke: steelblue;
    stroke-width: 1.5px;
  }

  >>> .point {
    fill: steelblue;
    stroke: #fff;
    stroke-width: 3px;
  }

  >>> .label,
  >>> .change,
  >>> .axis,
  >>> .main {
    text-anchor: middle;
    alignment-baseline: middle;
    fill: #aaa;
  }

  >>> .change,
  >>> .main {
    text-anchor: middle;
    alignment-baseline: middle;
    fill: #333;
  }

  >>> line.axis {
    stroke: #aaa;
    stroke-width: 1.5px;
  }



</style>
