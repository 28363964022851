<template>

  <div :style="{'z-index': 200, position: 'absolute', left: 16 + 'px', top: 56 + 'px'}">
    <el-tooltip class="item" effect="dark" :content="$t('Добавить узел')" placement="bottom">
      <el-button round @click="addNode()" type="primary">
        <i class="fas fa-plus"></i>
      </el-button>
    </el-tooltip>

    <el-tooltip v-if="!isCheckScheme" class="item" effect="dark" :content="$t('Граф не связан')" placement="bottom">
      <el-button round style="margin-left: 8px" type="info">
        <i class="fas fa-play"></i>
      </el-button>
    </el-tooltip>

     <el-tooltip v-else class="item" effect="dark" :content="$t('Визуализировать')" placement="bottom">
      <el-button round style="margin-left: 8px" @click="$emit('visualize-scheme')" type="primary">
        <i class="fas fa-play"></i>
      </el-button>
    </el-tooltip>
  </div>
  <svg oncontextmenu="return false;" id="svgContainer"
       ref="svgContainer" width="100%" height="100%"
       xmlns="http://www.w3.org/2000/svg">
    <g height="100%" width="100%" id="gElement">
      <rect :x="0" :y="0" height="100%" width="100%" style="fill: white"></rect> 

      <foreignObject
          :style="{'display': displayEditElement('value', 'Number', false) || displayEditElement('value', 'String', false) && !editableValue.list.isList ? 'block' : 'none', height: 40 + 'px', width: (120 + additionalValueWidth)  + 'px'}"
          id="valueEditObject" ref="valueEditObject">
        <el-input
            :style="{'background-color':'#f0f0f0',height: 40 + 'px', width: (120 + additionalValueWidth)  + 'px', 'z-index':100000}"
            @change="valueChange($event)" @input="valueInput($event)" id="valueEdit"
            ref="valueEdit" v-model="inputValue"/>
      </foreignObject>
      <foreignObject
          :style="{'display': displayEditElement('value', 'Boolean', false) ? 'block' : 'none', height: 40 + 'px', width: (120)  + 'px'}"
          id="valueBoolEditObject" ref="valueBoolEditObject">
        <el-radio-group
            :style="{height: 40 + 'px', width: (120)  + 'px'}"
            @change="valueBoolChange($event)"
            size="small"
            v-model="inputValue">
          <el-radio-button label="True"></el-radio-button>
          <el-radio-button label="False"></el-radio-button>
        </el-radio-group>
      </foreignObject>

      <foreignObject
          :style="{'display': displayEditElement('value', 'Date', false) ? 'block' : 'none', height: 40 + 'px', width: (150)  + 'px'}"
          id="valueDateEditObject" ref="valueDateEditObject">
        <el-input
            @change="valueDateChange($event)"
            id="valueDateEdit"
            size="small"
            type="date"
            v-model="inputValue">
        </el-input>
      </foreignObject>

      <foreignObject
          :style="{'display': displayEditElement('value', 'String', true) ? 'block' : 'none', height: 500 + 'px', width: (200)  + 'px'}"
          id="valueListEditObject" ref="valueListEditObject">
        <el-select v-model="inputValue"
                   @change="valueInput"
                   :no-match-text="$t('Ничего не найдено')"
                   :no-data-text="$t('Нет данных')"
                   id="valueListEdit"
                   style="margin-top: 250px; width: 200px;"
                   :placeholder="$t('Выберите значение')">
          <el-option
              style="width: 90%;"
              v-for="item in valueList"
              :key="item"
              :label="item"
              :value="item">
          </el-option>
        </el-select>
      </foreignObject>

      <foreignObject
          :style="{'display': listMenu.show ? 'block' : 'none', height: 60 + 'px', width: (180)  + 'px'}"
          id="listMenuObject" ref="listMenuObject">
        <el-select v-model="listMenu.value"
                   @change="listMenuInput"
                   :no-match-text="$t('Ничего не найдено')"
                   :no-data-text="$t('Нет данных')"
                   id="valueListEdit"
                   style="width: 100%"
                   :placeholder="$t('Выберите значение')">
          <el-option
              style="width: 90%;"
              v-for="item in listMenu.array"
              :key="item"
              :label="item"
              :value="item">
          </el-option>
        </el-select>
      </foreignObject>

    </g>
  </svg>

  <!--    <svg width="1000" height="1000" xmlns="http://www.w3.org/2000/svg" :data="data" ></svg>-->
  <!--    <div id="svgContainer"></div>-->
</template>


<script>
import * as d3 from 'd3'
import d3Controller from '../../controllers/d3.pie.controller'
//import { ElNotification } from 'element-plus'
import basic from "../../store/basic";
//import { h } from 'vue'
import Utils from "../../services/utils";
import CaseSessionController from "../../controllers/caseSession.controller";
import OntologyController from "../../controllers/ontology.controller";
import SchemeController from "../../controllers/scheme.controller";

//import axios from "axios";

export default {
  data() {
    return {
      height: Math.max(
          document.documentElement.clientHeight,
          window.innerHeight || 0
      ) - 72 - 12 - 42,
      margin: Object,
      chartWidth: String,
      chartHeight: String,
      menuFirstOn: [],
      listMenu: {
        array: [],
        value: '',
        type: '',
        show: false
      },
      graph: {
        nodes: [],
        attributes: [],
        links: [],
        values: [],
        operators: [],
        logics: [],
        functions: []
      },
      nodesCounter: 0,
      linksCounter: 0,
      forceProperties: {
        center: {
          x: 0.5,
          y: 0.5
        },
        charge: {
          enabled: true,
          strength: -200,
          distanceMin: 100,
          distanceMax: 2000
        },
        collide: {
          enabled: true,
          strength: 0.1,
          iterations: 1,
          radius: 35
        },
        forceX: {
          enabled: true,
          strength: 0.05,
          x: 0.5
        },
        forceY: {
          enabled: true,
          strength: 0.05,
          y: 0.5
        },
        link: {
          enabled: true,
          distance: 100,
          iterations: 1
        }
      },
      clicks: 0,
      x0Node: 0,
      y0Node: 0,
      d3Controller: null,
      ontology: null,
      createLinkObj: {node: {}, link: '', nodeType: ''},
      arc: null,
      editValue: false,
      editableValue: null,
      additionalValueWidth: 0,
      valueList: [],
      inputValue: '',
      cartElement: {
        element: null,
        active: false
      },
      sessionController: null,
      ontologyController: null,
      schemeController: null,
      isCheckScheme: false,
    }
  },
  props: ['data'],
  mounted() {
    this.ontologyController = new OntologyController()
    this.ontology = this.ontologyController.getOntology()
    this.d3Controller = new d3Controller(this.$refs.svgContainer)
    this.sessionController = new CaseSessionController()
    this.bindKeyActions()
    this.init()
    this.bindMouseActions()
    this.schemeController = new SchemeController()

  },
  methods: {
     setCommonAttributes() {
      let attributes = localStorage.getItem("displayAttribute")
      if (attributes !== null) {
        if (attributes.trim().localeCompare("null") == 0) {
            attributes = null;
        }
      }
      if (attributes) {
        let checkAttributes = JSON.parse(attributes)
        this.graph.nodes.forEach((node) => { 
          // const currentAttr = checkAttributes.find((attr) => attr.type === node.name)
           const index = checkAttributes.findIndex((attribute) => attribute.type === node.name)
           if(index !== -1) {
              node.displayAttribute = checkAttributes[index].attribute
           } else {
             node.displayAttribute = ""
           }
        })
      } else {
         this.graph.nodes.forEach((node) => { 
          node.displayAttribute = ""
        })
      }
    },
    displayEditElement(element, type, list) {
      if (this.editableValue !== null) {
        if (element === 'value' && this.editableValue.element === element) {
          if (this.editableValue.list.isList && list) {
            return true
          } else if (!list && this.editableValue.name === type) {
            return true
          }
        }
      }
      return false
    },
    cartElementSelected(element) {
      if (element !== null) {
        this.cartElement.element = element
        this.cartElement.active = true
      } else {
        this.cartElement.element = null
        this.cartElement.active = false
      }
    },
    init() {
      this.d3Controller.setCounters(this.nodesCounter, this.linksCounter)
      this.d3Controller.bindSimulation(this.forceProperties)
      this.d3Controller.enableDrag()
    },
    loadGraph(scheme) {
      this.graph.links = scheme.links
      this.graph.nodes = scheme.nodes
      this.graph.attributes = scheme.attributes
      this.graph.values = scheme.values
      this.graph.operators = scheme.operators
      this.graph.logics = scheme.logics
      this.graph.functions = scheme.functions
      this.setCommonAttributes()
    },
    unbindZoom() {
      this.d3Controller.unbindZoom()
    },
    bindKeyActions() {
      const vm = this

      function keyup() {
        vm.unbindZoom()
        vm.d3Controller.enableDrag()
      }

      function keydown() {
        switch (vm.d3Controller.event().keyCode) {
          case 18: { // delete
            vm.d3Controller.bindZoom([1 / 4, 4])
            vm.d3Controller.disableDrag()
            break
          }
          case 13: {
            if (vm.editableValue !== null) {
              vm.editableValue = null
              vm.removeEditors()
            }
          }

        }
      }

      this.d3Controller.bindKeyDown(keydown)
      this.d3Controller.bindKeyUp(keyup)
    },
    bindMouseActions() {
      const vm = this

      function svgMouseMove() {
        
        if (vm.d3Controller.dragLineAction) {
          if (vm.d3Controller.selected_node) {
            vm.d3Controller.drag_line
                .attr('x1', vm.d3Controller.selected_node.x + +vm.d3Controller.selected_node.dx)
                .attr('y1', vm.d3Controller.selected_node.y + +vm.d3Controller.selected_node.dy)
                .attr('x2', vm.d3Controller.mouse(this)[0])
                .attr('y2', vm.d3Controller.mouse(this)[1])
          } else if (vm.d3Controller.selected_link) {
            vm.d3Controller.drag_line
                .attr('x1', (vm.d3Controller.selected_link.source.x + vm.d3Controller.selected_link.target.x) / 2)
                .attr('y1', (vm.d3Controller.selected_link.source.y + vm.d3Controller.selected_link.target.y) / 2)
                .attr('x2', vm.d3Controller.mouse(this)[0])
                .attr('y2', vm.d3Controller.mouse(this)[1])
          }
        }
      }

      function svgMouseDown() {
        // vm.d3Controller.selected_link = null
        // vm.d3Controller.selected_node = null
        if (vm.cartElement.active) {
          let alreadyExists = false
          for (let node of vm.graph.nodes) {
            if (node.neoID === parseInt(vm.cartElement.element.id)) {
              alreadyExists = true
              break
            }
          }
          if (!alreadyExists) {
            let xy0 = vm.d3Controller.mouse(this)
            vm.nodesCounter++
            vm.d3Controller.setCounters(vm.nodesCounter, vm.linksCounter)
            let newNode = {
              element: 'ontology',
              name: vm.cartElement.element.type,
              x: xy0[0],
              y: xy0[1],
              dxdy: {},
              dx: 0,
              dy: 0,
              label: vm.ontology.nodes[vm.cartElement.element.type].label,
              neoID: parseInt(vm.cartElement.element.id),
              part: null,
              counter: vm.nodesCounter,
              short: vm.ontology.nodes[vm.cartElement.element.type].short + vm.nodesCounter,
              color: vm.ontology.nodes[vm.cartElement.element.type].color,
            }
            vm.graph.nodes.push(newNode)

            vm.sessionController.addLogStore('addNode', 'Добавление узла')

            vm.restart('add', newNode)
            vm.cartElement.active = false
            vm.cartElement.element = null
            vm.$emit('drop-cart-select')
          } else {
            vm.$emit('show-notification', {name: 'cartElementAlready', agrs: {}})
          }
        }


        if (vm.d3Controller.dragLineAction) {
          let xy0 = vm.d3Controller.mouse(this)
          vm.nodesCounter++
          vm.d3Controller.setCounters(vm.nodesCounter, vm.linksCounter)
          let newNode = {}
          if (vm.createLinkObj.node.element === 'ontology') {
            newNode = {
              element: 'ontology',
              name: vm.createLinkObj.node.name,
              x: xy0[0],
              y: xy0[1],
              dxdy: {},
              dx: 0,
              dy: 0,
              label: vm.ontology.nodes[vm.createLinkObj.node.name].label,
              neoID: -1,
              part: null,
              counter: vm.nodesCounter,
              short: vm.ontology.nodes[vm.createLinkObj.node.name].short + vm.nodesCounter,
              color: vm.ontology.nodes[vm.createLinkObj.node.name].color,
            }
            vm.graph.nodes.push(newNode)
          } else if (vm.createLinkObj.node.element === 'attribute') {
            if (vm.createLinkObj.node.nodeType === 'node') {
              if (vm.d3Controller.selected_node.element !== 'operator') {
                newNode = {
                  element: 'attribute',
                  attribute: vm.createLinkObj.node.name,
                  name: 'attr' + vm.createLinkObj.node.name.type,
                  parent: vm.d3Controller.selected_node,
                  x: xy0[0],
                  part: null,
                  y: xy0[1],
                  counter: vm.nodesCounter,
                  additionalWidth: 0,
                  dxdy: {},
                  dx: -86 / 2,
                  dy: 0,
                  color: '#000000'
                }
              }
            } else {
              newNode = {
                element: 'attribute',
                attribute: vm.createLinkObj.node.name,
                name: 'attr' + vm.createLinkObj.node.name.type,
                parent: vm.d3Controller.selected_link,
                x: xy0[0],
                part: null,
                y: xy0[1],
                counter: vm.nodesCounter,
                additionalWidth: 0,
                dxdy: {},
                dx: -86 / 2,
                dy: 0,
                color: '#000000'
              }
            }

            vm.graph.attributes.push(newNode)
          } else if (vm.createLinkObj.node.element === 'operator') {
            newNode = {
              element: 'operator',
              name: vm.createLinkObj.node.name,
              x: xy0[0],
              part: null,
              additionalWidth: 0,
              counter: vm.nodesCounter,
              y: xy0[1],
              dxdy: {},
              dx: 0,
              dy: 0,
              color: '#000000',
            }
            vm.graph.operators.push(newNode)
          } else if (vm.createLinkObj.node.element === 'value') {
            let additionalWidth = 0
            if (vm.createLinkObj.node.name === 'Date') additionalWidth = 10
            newNode = {
              element: 'value',
              name: vm.createLinkObj.node.name,
              x: xy0[0],
              part: null,
              list: {
                isList: false,
                listName: ''
              },
              additionalWidth: additionalWidth,
              value: '',
              counter: vm.nodesCounter,
              y: xy0[1],
              dxdy: {},
              dx: 0,
              dy: 0,
              color: '#000000',
            }
            vm.graph.values.push(newNode)
          } else if (vm.createLinkObj.node.element === 'logic') {
            newNode = {
              element: 'logic',
              name: vm.createLinkObj.node.name,
              x: xy0[0],
              part: null,
              additionalWidth: 0,
              counter: vm.nodesCounter,
              y: xy0[1],
              dxdy: {},
              dx: 0,
              dy: 0,
              color: '#000000'
            }
            vm.graph.logics.push(newNode)
          }
          if (newNode !== {}) {
            vm.sessionController.addLogStore('addNode', 'Добавление узла')
            vm.d3Controller.dragLineAction = false
            vm.d3Controller.mousedown_node = newNode
            vm.restart('add', newNode)
            vm.createLink()
          }
        }
      }


      this.d3Controller.bindActions(null, svgMouseDown, null, svgMouseMove)
    },

    valueChange() {
      //console.log(e)
    },
    valueBoolChange() {
      //console.log(e)
      this.setTextValue()
    },
    valueDateChange() {
      //console.log(e)
      this.setTextValue()
    },
    setTextValue() {
      const vm = this
      this.d3Controller.graphSelection.selectAll('.value').selectAll('.text-value').text(d => {
        if (d === vm.editableValue) {
          d.value = vm.inputValue
          return d.value
        } else {
          return d.value
        }
      })
    },
    setWidthValue(w) {
      const vm = this
      let startW = 80
      this.additionalValueWidth = this.getAdditionalWidth(w, startW, 30, 300)
      this.editableValue.additionalWidth = this.additionalValueWidth
      this.d3Controller.selected_node.dxdy['in'] = [-(startW + this.additionalValueWidth) / 2, 0]
      this.d3Controller.graphSelection.selectAll('.value').selectAll('rect').attr('width', function (d) {
        return startW + d.additionalWidth
      }).attr('x', function (d) {
        return -(startW + d.additionalWidth) / 2
      })
      this.d3Controller.graphSelection.selectAll('.value').selectAll('.in-node').attr('transform', function (d) {
        return 'translate(' + (-(startW + d.additionalWidth) / 2) + ')'
      })
      this.d3Controller.graphSelection.select('#valueEditObject').attr('x', -(120 + this.additionalValueWidth) / 2)

      for (let i = 0; i < vm.graph.links.length; i++) {
        let link = vm.graph.links[i]
        if (link.source === vm.editableValue) {
          link.sourceD.dx = -(startW + vm.additionalValueWidth) / 2
        } else if (link.target === vm.editableValue) {
          link.targetD.dx = -(startW + vm.additionalValueWidth) / 2
        }
      }
      this.d3Controller.tick()
    },
    valueInput(e) {
      const vm = this
      // console.log(vm.editableValue.name, Utils.testNumber(e))
      if (vm.editableValue.name === 'Number') {
        if (Utils.testNumber(e) !== null) {
          this.inputValue = e
          this.setTextValue()
          vm.setWidthValue(vm.editableText._groups[0][0].getComputedTextLength())
        } else {
          this.inputValue = this.inputValue.slice(0, this.inputValue.length - 1)
        }
      } else {
        this.inputValue = e
        this.setTextValue()
        vm.setWidthValue(vm.editableText._groups[0][0].getComputedTextLength())
      }
    },
    resetTextValue() {
      this.d3Controller.graphSelection.selectAll('.value').selectAll('.text-value').text(d => {
        return d.value
      })
    },
    getAdditionalWidth(text, start, boundaries, max) {
      if (text > start - boundaries && text < max - boundaries) {
        return (text - (start - boundaries))
      } else if (text < start - boundaries) {
        return 0
      } else {
        return max - start
      }
    },

    startEditValue(counter) {
      this.removePies()
      this.inputValue = this.d3Controller.selected_node.value
      let fobject = this.d3Controller.graphSelection.select('#g' + counter)
      if ((this.d3Controller.selected_node.name === 'String' || this.d3Controller.selected_node.name === 'Number') && !this.d3Controller.selected_node.list.isList) {
        let removed = this.d3Controller.graphSelection.select('#valueEditObject').remove()
        fobject.append(function () {
          return removed.node();
        });
        this.d3Controller.graphSelection.selectAll('#valueEditObject').raise()
        this.d3Controller.graphSelection.select('#valueEditObject')
            .attr('x', -(120 + this.d3Controller.selected_node.additionalWidth) / 2)
            .attr('y', 22)
        document.getElementById('valueEdit').focus()
        this.additionalValueWidth = this.d3Controller.selected_node.additionalWidth
        this.editableText = fobject.select('.text-value')
      } else if (this.d3Controller.selected_node.name === 'Boolean' && !this.d3Controller.selected_node.list.isList) {
        let removed = this.d3Controller.graphSelection.select('#valueBoolEditObject').remove()
        fobject.append(function () {
          return removed.node();
        });
        this.d3Controller.graphSelection.selectAll('#valueBoolEditObject').raise()
        this.d3Controller.graphSelection.select('#valueBoolEditObject')
            .attr('x', (120) / 2)
            .attr('y', 22)
      } else if (this.d3Controller.selected_node.name === 'Date' && !this.d3Controller.selected_node.list.isList) {
        let removed = this.d3Controller.graphSelection.select('#valueDateEditObject').remove()
        fobject.append(function () {
          return removed.node();
        });
        this.d3Controller.graphSelection.selectAll('#valueDateEditObject').raise()
        this.d3Controller.graphSelection.select('#valueDateEditObject')
            .attr('x', -(150) / 2)
            .attr('y', 22)
        document.getElementById('valueDateEdit').focus()
      } else if (this.d3Controller.selected_node.list.isList) {
        this.valueList = []
        const vm = this
        // axios
        //     .get(`${ScienceAPI}/getList/` + this.d3Controller.selected_node.list.listName)
        //     .then(function (response) {
        //         // handle success
        //         const data = response.data.data
        //         for (let i = 0; i < data.length; i++) {
        //             vm.valueList.push(data[i]['1'])
        //         }
        //     })
        //     .catch(function (error) {
        //         // handle error
        //         console.log(error)
        //     })
        //     .finally(function () {
        //         // always executed
        //     })
        // let removed = this.d3Controller.graphSelection.select('#valueListEdit').remove()
        // fobject.append(function() {
        //     return removed.node();
        // });
        vm.d3Controller.graphSelection.selectAll('#valueListEdit').raise()
        vm.d3Controller.graphSelection.select('#valueListEdit')
            .attr('x', -(200) / 2)
            .attr('y', 22 - 250)
        document.getElementById('valueListEdit').focus()
        vm.editableText = vm.d3Controller.select(this).select('.text-value')
      }

      this.editableValue = this.d3Controller.selected_node
    },

    getAvaliableAttrs(type) {
      let arrayCol = []
      arrayCol.push({label: 'Назад', name: 'Назад', icon: {name: 'fas fa-arrow-left'}, color: '#000', clickable: false})
      if (type === 'node') {
        let nameType = this.d3Controller.selected_node.name
        if (nameType !== null) {
          for (let key of Object.keys(this.ontology.nodes)) {
            if (key === nameType) {
              let alreadyUsed = []
              for (let link of this.graph.links) {
                if (link.source.index === this.d3Controller.selected_node.index && link.target.element === 'attribute') {
                  if (link.target.attribute !== null) alreadyUsed.push(link.target.attribute.name)
                }
              }
              for (let attr of Object.keys(this.ontology.nodes[key].properties)) {
                if (!alreadyUsed.includes(attr)) {
                  let element = Object.assign({}, this.ontology.nodes[key].properties[attr])
                  element['name'] = attr
                  element['label'] = attr
                  element['icon'] = null
                  element['clickable'] = false
                  arrayCol.push(element)
                }
              }
            }
          }
        }
      } else {
        let nameType = this.d3Controller.selected_link.name
        if (nameType !== null) {
          for (let key of Object.keys(this.ontology.links)) {
            if (key === nameType) {
              let alreadyUsed = []
              for (let link of this.graph.links) {
                if (link.source.index === this.d3Controller.selected_link.index && link.target.element === 'attribute') {
                  if (link.target.attribute !== null) alreadyUsed.push(link.target.attribute.name)
                }
              }
              for (let attr of Object.keys(this.ontology.links[key].properties)) {
                if (!alreadyUsed.includes(attr) && attr !== 'hidden') {
                  let element = Object.assign({}, this.ontology.links[key].properties[attr])
                  element['name'] = attr
                  element['label'] = attr
                  element['icon'] = null
                  element['clickable'] = false
                  arrayCol.push(element)
                }
              }
            }
          }
        }
      }
      return arrayCol
    },

    getAvaliableDirections() {
      let arrayCol = []
      if (this.d3Controller.selected_link.directed) {
        arrayCol = [{
          label: this.$t('Изменить направление'),
          name: 'Изменить направление',
          icon: {name: 'fas fa-long-arrow-alt-right'},
          color: '#000',
          chosen: true,
          clickable: true
        },
          {
            label: this.$t('Без направления'),
            name: 'Без направления',
            icon: {name: 'fas fa-times'},
            color: '#000',
            chosen: false,
            clickable: true
          },
          {label: this.$t('Назад'), name: 'Назад', icon: {name: 'fas fa-arrow-left'}, color: '#000', clickable: false}]
      } else {
        arrayCol = [{
          label: this.$t('Изменить направление'),
          name: 'Изменить направление',
          icon: {name: 'fas fa-long-arrow-alt-right'},
          color: '#000',
          chosen: false,
          clickable: true
        },
          {
            label: this.$t('Без направления'),
            name: 'Без направления',
            icon: {name: 'fas fa-times'},
            color: '#000',
            chosen: true,
            clickable: true
          },
          {label: this.$t('Назад'), name: 'Назад', icon: {name: 'fas fa-arrow-left'}, color: '#000', clickable: false}]
      }
      return arrayCol
    },

    getAvaliableLinks(name) {
      let arrayCol = []
      let linksTypeDone = []
      let key = this.d3Controller.selected_node.name + ':' + name
      if (key in this.ontology.ruleList) {
        for (let i = 0; i < this.ontology.ruleList[key].length; i++) {
          let el = {}
          if (!linksTypeDone.includes(this.ontology.ruleList[key][i]['type'])) {
            el['name'] = this.ontology.ruleList[key][i]['type']
            el['label'] = this.ontology.links[this.ontology.ruleList[key][i]['type']].label
            el['color'] = '#000'
            el['clickable'] = false
            arrayCol.push(el)
            linksTypeDone.push(this.ontology.ruleList[key][i]['type'])
          }

        }
      }
      key = name + ':' + this.d3Controller.selected_node.name
      if (key in this.ontology.ruleList) {
        for (let i = 0; i < this.ontology.ruleList[key].length; i++) {
          let el = {}
          if (!linksTypeDone.includes(this.ontology.ruleList[key][i]['type'])) {
            el['name'] = this.ontology.ruleList[key][i]['type']
            el['label'] = this.ontology.links[this.ontology.ruleList[key][i]['type']].label
            el['color'] = '#000'
            el['clickable'] = false
            arrayCol.push(el)
            linksTypeDone.push(this.ontology.ruleList[key][i]['type'])
          }

        }
      }
      arrayCol.push({label: this.$t('Назад'), name: 'Назад', icon: {name: 'fas fa-arrow-left'}, color: '#000', clickable: false})
      return arrayCol
    },

    getAvaliableOperators() {
      let arrayCol = []
      if (this.d3Controller.selected_node.element === 'attribute') {
        for (let operator of Object.keys(basic.operators)) {
          if (this.d3Controller.selected_node.part === 'out') {
            if ((this.d3Controller.selected_node.name + ':' + this.d3Controller.selected_node.part + ':'
                + operator + ':in' in basic.ruleList)) {
              arrayCol.push(operator)
            }
          } else if (this.d3Controller.selected_node.part === 'in') {
            if ((this.d3Controller.selected_node.name + ':' + this.d3Controller.selected_node.part + ':'
                + operator + ':out' in basic.ruleList)) {
              arrayCol.push(operator)
            }
          }
        }
      }
      return arrayCol
    },

    getAvaliableLogics() {
      let arrayCol = []
      if (this.d3Controller.selected_node.element === 'operator') {
        for (let logic of Object.keys(basic.logics)) {
          if (this.d3Controller.selected_node.part === 'out') {
            if ((this.d3Controller.selected_node.name + ':' + this.d3Controller.selected_node.part + ':'
                + logic + ':in' in basic.ruleList)) {
              arrayCol.push({label: logic, name: logic, icon: null, color: '#000', clickable: false})
            }
          } else if (this.d3Controller.selected_node.part === 'in') {
            if ((this.d3Controller.selected_node.name + ':' + this.d3Controller.selected_node.part + ':'
                + logic + ':out' in basic.ruleList)) {
              arrayCol.push({label: logic, name: logic, icon: null, color: '#000', clickable: false})
            }
          }
        }
      }
      arrayCol.push({label: this.$t('Назад'), name: 'Назад', icon: {name: 'fas fa-arrow-left'}, color: '#000', clickable: false})
      return arrayCol
    },

    getAvaliableTypes(type) {
      let arrayCol = []
      if (type === 'node') {
        let nameType = this.d3Controller.selected_node.name
        if (nameType === null) {
          arrayCol.push({
            label: this.$t('Удалить'),
            name: 'Удалить',
            icon: {name: 'fas fa-trash-alt'},
            color: '#000',
            clickable: false
          })
          for (let key of Object.keys(this.ontology.nodes)) {
            let element = Object.assign({}, this.ontology.nodes[key])
            element['label'] = this.ontology.nodes[key].label
            element['name'] = key
            element['clickable'] = false
            arrayCol.push(element)
          }
        } else {
          if (this.d3Controller.selected_node.element === 'ontology') {
            let nodesNode = []
            arrayCol.push({label: this.$t('Назад'), name: 'Назад', icon: {name: 'fas fa-arrow-left'}, color: '#000'})
            for (let key of Object.keys(this.ontology.ruleList)) {
              let node1 = key.split(':')[0]
              let node2 = key.split(':')[1]
              if (node1 === nameType && !nodesNode.includes(node2)) {
                for (let name of Object.keys(this.ontology.nodes)) {
                  if (name === node2) {
                    let element = Object.assign({}, this.ontology.nodes[name])
                    element['label'] = this.ontology.nodes[name].label
                    element['name'] = name
                    element['clickable'] = false
                    arrayCol.push(element)
                    nodesNode.push(node2)
                    break
                  }
                }
              } else if (node2 === nameType && !nodesNode.includes(node1)) {
                for (let name of Object.keys(this.ontology.nodes)) {
                  if (name === node1) {
                    let element = Object.assign({}, this.ontology.nodes[name])
                    element['label'] = this.ontology.nodes[name].label
                    element['name'] = name
                    element['clickable'] = false
                    arrayCol.push(element)
                    nodesNode.push(node1)
                    break
                  }
                }
              }
            }
          }
        }
      }
      return arrayCol
    },

    getValueType() {
      for (let link of this.graph.links) {
        if (this.compareNodes(link.target, this.d3Controller.selected_node)) {
          if (link.source.element === 'attribute') {
            return link.source.attribute.type
          }
          // else if (link.target.element === 'function') {
          //     return link.target.attribute.type
          // }
        }
      }
    },

    compareNodes(n1, n2) {
      if (n1.element === n2.element && n1.index === n2.index) return true
      else return false
    },
    spliceLinksForNode(node) {
      const vm = this
      let toSplice = this.graph.links.filter(
          function (l) {
            return (l.source === node) || (l.target === node)
          })
      toSplice.map(
          function (l) {
            vm.graph.links.splice(vm.graph.links.indexOf(l), 1)
          })
    },
    deleteElement() {
      const vm = this
      this.sessionController.addLogStore('deleteElement', 'Удаление элемента')
      if (vm.d3Controller.selected_node) {
        let nodeToDelete = []
        if (vm.d3Controller.selected_node.element === 'ontology') {
          for (let link of vm.graph.links) {
            if (link.source.counter === this.d3Controller.selected_node.counter && link.target.element === 'attribute') {
              nodeToDelete.push(link.target.counter)
            }
          }
          vm.graph.nodes.splice(vm.graph.nodes.indexOf(vm.d3Controller.selected_node), 1)
        } else if (vm.d3Controller.selected_node.element === 'value') {
          vm.graph.values.splice(vm.graph.values.indexOf(vm.d3Controller.selected_node), 1)
        } else if (vm.d3Controller.selected_node.element === 'operator') {
          vm.graph.operators.splice(vm.graph.operators.indexOf(vm.d3Controller.selected_node), 1)
        } else if (vm.d3Controller.selected_node.element === 'logic') {
          vm.graph.logics.splice(vm.graph.logics.indexOf(vm.d3Controller.selected_node), 1)
        } else if (vm.d3Controller.selected_node.element === 'attribute') {
          vm.graph.attributes.splice(vm.graph.attributes.indexOf(vm.d3Controller.selected_node), 1)
        } else if (vm.d3Controller.selected_node.element === 'function') {
          vm.graph.functions.splice(vm.graph.functions.indexOf(vm.d3Controller.selected_node), 1)
        }
        vm.spliceLinksForNode(vm.d3Controller.selected_node)
        vm.$emit('select-element', {})
        for (let i = 0; i < this.graph.nodes.length; i++) {
          if (nodeToDelete.includes(this.graph.nodes[i].counter)) {
            this.spliceLinksForNode(this.graph.nodes[i])
            this.graph.nodes.splice(i, 1)
          }
        }
        for (let i = 0; i < this.graph.attributes.length; i++) {
          if (nodeToDelete.includes(this.graph.attributes[i].counter)) {
            this.spliceLinksForNode(this.graph.attributes[i])
            this.graph.attributes.splice(i, 1)
          }
        }
        for (let i = 0; i < this.graph.operators.length; i++) {
          if (nodeToDelete.includes(this.graph.operators[i].counter)) {
            this.spliceLinksForNode(this.graph.operators[i])
            this.graph.operators.splice(i, 1)
          }
        }
        for (let i = 0; i < this.graph.logics.length; i++) {
          if (nodeToDelete.includes(this.graph.logics[i].counter)) {
            this.spliceLinksForNode(this.graph.logics[i])
            this.graph.logics.splice(i, 1)
          }
        }
        for (let i = 0; i < this.graph.values.length; i++) {
          if (nodeToDelete.includes(this.graph.values[i].counter)) {
            this.spliceLinksForNode(this.graph.values[i])
            this.graph.values.splice(i, 1)
          }
        }
        for (let i = 0; i < this.graph.functions.length; i++) {
          if (nodeToDelete.includes(this.graph.functions[i].counter)) {
            this.spliceLinksForNode(this.graph.functions[i])
            this.graph.functions.splice(i, 1)
          }
        }
      } else if (vm.d3Controller.selected_link) {
        let counter = -1
        let linkToDelete = []
        let nodeToDelete = []
        if (vm.d3Controller.selected_link.source.element === 'ontology' && vm.d3Controller.selected_link.target.element === 'attribute') {
          nodeToDelete.push(vm.d3Controller.selected_link.target.counter)
        }
        for (let link of vm.graph.links) {
          if ((vm.compareNodes(link.source, vm.d3Controller.selected_link.source) && vm.compareNodes(link.target, vm.d3Controller.selected_link.target)) ||
              (vm.compareNodes(link.source, vm.d3Controller.selected_link.target) && vm.compareNodes(link.target, vm.d3Controller.selected_link.source))) {
            if (link !== vm.d3Controller.selected_link) {
              counter++
            }
          }
          if (link.source.element === vm.d3Controller.selected_link.element && link.source.name === vm.d3Controller.selected_link.name) {
            linkToDelete.push(link)
          }
        }
        for (let link of linkToDelete) {
          vm.spliceLinksForNode(link.target)
          vm.graph.attributes.splice(vm.graph.attributes.indexOf(link.target), 1)
        }
        for (let link of vm.graph.links) {
          if ((vm.compareNodes(link.source, vm.d3Controller.selected_link.source) && vm.compareNodes(link.target, vm.d3Controller.selected_link.target)) ||
              (vm.compareNodes(link.source, vm.d3Controller.selected_link.target) && vm.compareNodes(link.target, vm.d3Controller.selected_link.source))) {
            if (link !== vm.d3Controller.selected_link) {
              if (link.sameLink.current > vm.d3Controller.selected_link.sameLink.current) link.sameLink = {
                current: link.sameLink.current - 1,
                all: counter
              }
              else link.sameLink = {current: link.sameLink.current, all: counter}
            }
          }
        }
        vm.graph.links.splice(vm.graph.links.indexOf(vm.d3Controller.selected_link), 1)
        for (let i = 0; i < this.graph.attributes.length; i++) {
          if (nodeToDelete.includes(this.graph.attributes[i].counter)) {
            this.spliceLinksForNode(this.graph.attributes[i])
            this.graph.attributes.splice(i, 1)
          }
        }
      }
      vm.d3Controller.selected_link = null
      vm.d3Controller.selected_node = null
      vm.restart('delete', null)
      if (this.graph.nodes.length < 2) { 
        vm.isCheckScheme = false;
      } else {
        vm.schemeController.checkScheme(this.graph) 
        .then(()=> { 
          vm.isCheckScheme = true 
        }) 
        .catch(()=> { 
          vm.isCheckScheme = false 
        })
      }
    },


    drawLink(toNode, linkType) {
      this.createLinkObj = {
        node: toNode,
        link: linkType
      }
      this.d3Controller.dragLineAction = true
      const vm = this

      if (this.d3Controller.selected_node && vm.d3Controller.dragLineAction) {
        // reposition drag line
        this.d3Controller.drag_line
            .attr('class', 'drag-link')
            .attr('style', 'stroke: #999;stroke-width: 5;')
            .attr('x1', this.d3Controller.selected_node.x + this.d3Controller.selected_node.dx)
            .attr('y1', this.d3Controller.selected_node.y + this.d3Controller.selected_node.dy)
            .attr('x2', this.d3Controller.selected_node.x + this.d3Controller.selected_node.dx)
            .attr('y2', this.d3Controller.selected_node.y + this.d3Controller.selected_node.dy)
      } else if (this.d3Controller.selected_link) {
        this.d3Controller.drag_line
            .attr('class', 'drag-link')
            .attr('style', 'stroke: #999;stroke-width: 5;')
            .attr('x1', (vm.d3Controller.selected_link.source.x + vm.d3Controller.selected_link.target.x) / 2)
            .attr('y1', (vm.d3Controller.selected_link.source.y + vm.d3Controller.selected_link.target.y) / 2)
            .attr('x2', (vm.d3Controller.selected_link.source.x + vm.d3Controller.selected_link.target.x) / 2)
            .attr('y2', (vm.d3Controller.selected_link.source.y + vm.d3Controller.selected_link.target.y) / 2)
      }

      if (this.graph.nodes.length < 2) { 
        vm.isCheckScheme = false;
      } else {
        vm.schemeController.checkScheme(this.graph)
          .then(()=> { 
            this.isCheckScheme = true
          })
          .catch(()=> {
            vm.isCheckScheme = false
          })
      }
    },

    listMenuInput() {
      if (this.listMenu.type === 'operator') {
        this.drawLink({name: this.listMenu.value, element: 'operator', nodeType: 'node'}, 'toOperator')
      }
    },

    drawList(counter, coords, type, action) {
      let fobject = this.d3Controller.select('#g' + counter)
      let arcOuterRadius = 90
      this.listMenu.value = ''

      if (type === 'node') {
        if (this.d3Controller.selected_node.part === 'in') {
          arcOuterRadius = 60
          fobject = this.d3Controller.select('#g' + counter).select('.in-node')
        }
        if (this.d3Controller.selected_node.part === 'out') {
          arcOuterRadius = 60
          fobject = this.d3Controller.select('#g' + counter).select('.out-node')
        }
      }

      if (action === 'toOperator') {
        this.listMenu.type = 'operator'
        this.listMenu.show = true
        let array = this.getAvaliableOperators()
        this.listMenu.array = array

        let coordX = arcOuterRadius + 16

        if (coords[0] < 0) coordX = -coordX
        let removed = this.d3Controller.graphSelection.select('#listMenuObject').remove()
        fobject.append(function () {
          return removed.node();
        });
        this.d3Controller.graphSelection.selectAll('#listMenuObject').raise()
        this.d3Controller.graphSelection.select('#listMenuObject')
            .attr('x', coordX)
            .attr('y', coords[1])
        this.listMenu.value = ''

      }


    },

    drawArcs(counter, type, action, piesType) {
      this.listMenu.show = false
      const vm = this
      let arcOuterRadius = 90
      let arcInnerRadius = 38

      this.editableValue = null

      let avaliableTypes = []
      if (action === 'changeNodeType') avaliableTypes = this.getAvaliableTypes(type)
      else if (action === 'attrMenu' && vm.d3Controller.selected_node.part === 'out') avaliableTypes = [{
        label: this.$t('К оператору'),
        name: 'К оператору',
        icon: {name: 'fas fa-greater-than-equal'},
        color: '#000',
        clickable: false
      },
        {label: this.$t('Удалить'), name: 'Удалить', icon: {name: 'fas fa-trash-alt'}, color: '#000', clickable: false}]
      else if (action === 'attrMenu' && vm.d3Controller.selected_node.part === 'in') avaliableTypes = [{
        label: this.$t('К оператору'),
        name: 'К оператору',
        icon: {name: 'fas fa-greater-than-equal'},
        color: '#000',
        clickable: false
      },
        {label: this.$t('Удалить'), name: 'Удалить', icon: {name: 'fas fa-trash-alt'}, color: '#000', clickable: false}]
      else if (action === 'logicMenu' && vm.d3Controller.selected_node.part === 'out') avaliableTypes = [{
        label: this.$t('К логической функции'),
        name: 'К логической функции',
        icon: {name: 'fas fa-sign-in-alt'},
        color: '#000',
        clickable: false
      },
        {label: this.$t('Удалить'), name: 'Удалить', icon: {name: 'fas fa-trash-alt'}, color: '#000', clickable: false}]
      else if (action === 'logicMenu' && vm.d3Controller.selected_node.part === 'in') avaliableTypes = [{
        label: this.$t('К оператору'),
        name: 'К оператору',
        icon: {name: 'fas fa-greater-than-equal'},
        color: '#000',
        clickable: false
      },
        {label: this.$t('Удалить'), name: 'Удалить', icon: {name: 'fas fa-trash-alt'}, color: '#000', clickable: false}]
      else if (action === 'valueMenu' && vm.d3Controller.selected_node.part === 'in') avaliableTypes = [{
        label: this.$t('К оператору'),
        name: 'К оператору',
        icon: {name: 'fas fa-greater-than-equal'},
        color: '#000',
        clickable: false
      },
        {label: this.$t('Редактировать'), name: 'Редактировать', icon: {name: 'fas fa-edit'}, color: '#000', clickable: false},
        {label: this.$t('Удалить'), name: 'Удалить', icon: {name: 'fas fa-trash-alt'}, color: '#000', clickable: false}]
      else if (action === 'operatorMenu' && vm.d3Controller.selected_node.part === 'out') avaliableTypes = [{
        label: this.$t('К значению'),
        name: 'К значению',
        icon: {name: 'fas fa-font'},
        color: '#000',
        clickable: false
      },
        {
          label: this.$t('К логической функции'),
          name: 'К логической функции',
          icon: {name: 'fas fa-sign-in-alt'},
          color: '#000',
          clickable: false
        },
        {label: this.$t('К атрибуту'), name: 'К атрибуту', icon: {name: 'fas fa-tag'}, color: '#000', clickable: false},
        {label: this.$t('Удалить'), name: 'Удалить', icon: {name: 'fas fa-trash-alt'}, color: '#000', clickable: false}]
      else if (action === 'operatorMenu' && vm.d3Controller.selected_node.part === 'in') avaliableTypes = [{
        label: this.$t('К атрибуту'),
        name: 'К атрибуту',
        icon: {name: 'fas fa-tag'},
        color: '#000',
        clickable: false
      },
        {label: this.$t('Удалить'), name: 'Удалить', icon: {name: 'fas fa-trash-alt'}, color: '#000', clickable: false}]
      else if (action === 'nodeMenu') avaliableTypes = [{
        label: this.$t('Добавить атрибут'),
        name: 'Добавить атрибут',
        icon: {name: 'fas fa-tag'},
        color: '#000',
        clickable: false
      },
        {
          label: this.$t('Добавить связь'),
          name: 'Добавить связь',
          icon: {name: 'fas fa-share-alt'},
          color: '#000',
          clickable: false
        },
        {label: this.$t('Удалить'), name: 'Удалить', icon: {name: 'fas fa-trash-alt'}, color: '#000', clickable: false}]
      else if (action === 'linkMenu') {
        if (this.d3Controller.selected_link.source.element === 'ontology' && this.d3Controller.selected_link.target.element === 'ontology') {
          avaliableTypes = [{
            label: this.$t('Добавить атрибут'),
            name: 'Добавить атрибут',
            icon: {name: 'fas fa-tag'},
            color: '#000',
            clickable: false
          },
            {
              label: vm.d3Controller.selected_link.name.length > 0 ? this.$t('Сделать пустым') : this.$t('Вернуть тип'),
              name: vm.d3Controller.selected_link.name.length > 0 ? 'Сделать пустым' : 'Вернуть тип',
              icon: vm.d3Controller.selected_link.name.length > 0 ? {name: 'fas fa-times-circle'} : {name: 'fas fa-circle'},
              color: '#000',
              clickable: false
            },
            // {name: 'Класс', icon:  {name: 'fas fa-grip-lines'}, color: '#000', clickable: false},
            {label: this.$t('Удалить'), name: 'Удалить', icon: {name: 'fas fa-trash-alt'}, color: '#000', clickable: false}]

          if (!vm.d3Controller.selected_link.exclusive) avaliableTypes.push( {
            label: this.$t('Направление'),
            name: 'Направление',
            icon: {name: 'fas fa-arrows-alt-h'},
            color: '#000',
            clickable: false
          })
        } else {
          avaliableTypes = [{
            label: this.$t('Удалить'),
            name: 'Удалить',
            icon: {name: 'fas fa-trash-alt'},
            color: '#000',
            clickable: false
          }]
        }
      } else if (action === 'directionMenu') avaliableTypes = this.getAvaliableDirections()
      else if (action === 'addLink') avaliableTypes = this.getAvaliableTypes(type)
      else if (action === 'addAttr') avaliableTypes = this.getAvaliableAttrs(type)
      else if (action === 'addLogic') avaliableTypes = this.getAvaliableLogics(type)

      if (piesType !== null) avaliableTypes = piesType


      // get nodes after they are mounted
      // ADD GROUP OF ELEMENTS BEFORE THE MIDDLE POINT

      let fobject = this.d3Controller.select('#g' + counter)
      if (type === 'link') {
        fobject = this.d3Controller.select('#gLink' + counter)
      }
      if (type === 'node') {
        if (this.d3Controller.selected_node.part === 'in') {
          arcOuterRadius = 60
          arcInnerRadius = 24
          fobject = this.d3Controller.select('#g' + counter).select('.in-node')
          fobject.raise();
        }
        if (this.d3Controller.selected_node.part === 'out') {
          arcOuterRadius = 60
          arcInnerRadius = 24
          fobject = this.d3Controller.select('#g' + counter).select('.out-node')
          fobject.raise();
        }
      }
      this.removePies()
      // let transHeight = (this.chartHeight - d3.select('#middleButton' + j).node().height.baseVal.value - 240) / 4
      // let transWidth = (this.chartWidth - d3.select('#middleButton' + j).node().width.baseVal.value - 15) / 2

      let pieG = fobject
          .insert("g", '#middleButton' + counter)
          .attr('width', '2em')
          .attr('height', '2em')
          .attr('class', 'pies')
          .attr('id', 'pies')
      // CREATE ARC
      const arcMain = this.d3Controller.arc()
          .outerRadius(arcOuterRadius)
          .innerRadius(arcInnerRadius)
          .padAngle(0.03)
          .cornerRadius(8)
      // CREATE PIE
      let arcs = this.d3Controller.pie()
          .value(() => 100 / avaliableTypes.length)(avaliableTypes);

      // DRAW ARCS ON A PIE
      let block = pieG.selectAll(".arc")
          .data(arcs)
      block.select('path').attr('d', arcMain)
      let newBlock = block
          .enter()
          .insert("g", '#middleButton' + counter)
          .classed("arc", true)

      // ADD SLICES OF DOUGHNUT
      newBlock.insert("path", '#middleButton' + counter)
          .attr("d", arcMain)
          .attr("id", (d, i) => "arc-" + i)
          .attr("stroke", (d) => {
            if (d.data.clickable) {
              if (d.data.chosen) return "red"
            }
            return "gray"
          })
          .attr("stroke-width", (d) => {
            if (d.data.clickable) {
              if (d.data.chosen) return "3px"
            }
            return "1px"
          })
          .attr("fill", (d) => d.data.color)

      let newBlock2 = this.d3Controller.select('#pies').selectAll("path")
      let clickCallback = null
      if (action === 'changeNodeType') {
        clickCallback = function (d) {
          if (d.data.name === 'Удалить') {
            vm.deleteElement()
          } else {
            for (let node of vm.graph.nodes) {
              if (node.counter === counter) {
                node.name = d.data.name
                node.color = d.data.color
                node.short = d.data.short + node.counter
              }
            }
            vm.restart('')
            vm.drawArcs(counter, type, 'nodeMenu', null)
          }
        }
      } else if (action === 'nodeMenu') {
        clickCallback = function (d) {
          if (d.data.name === 'Добавить связь') {
            vm.drawArcs(counter, type, 'addLink', null)
          } else if (d.data.name === 'Добавить атрибут') {
            vm.drawArcs(counter, type, 'addAttr', null)
          } else if (d.data.name === 'Удалить') {
            vm.deleteElement()
          }
        }
      } else if (action === 'linkMenu') {
        clickCallback = function (d) {
          if (d.data.name === 'Добавить атрибут') {
            vm.drawArcs(counter, type, 'addAttr', null)
          } else if (d.data.name === 'Удалить') {
            vm.deleteElement()
          } else if (d.data.name === 'Направление') {
            vm.drawArcs(counter, type, 'directionMenu', null)
          } else if (d.data.name === 'Сделать пустым') {
            vm.d3Controller.selected_link.lastName = vm.d3Controller.selected_link.name
            vm.d3Controller.selected_link.name = ''
            vm.restart('')
            vm.drawArcs(counter, type, action, null)
          } else if (d.data.name === 'Вернуть тип') {
            vm.d3Controller.selected_link.name = vm.d3Controller.selected_link.lastName
            vm.restart('')
            vm.drawArcs(counter, type, action, null)
          }
        }
      } else if (action === 'attrMenu') {
        clickCallback = function (d) {
          if (d.data.name === 'К оператору') {
            vm.drawList(counter, [arcMain.centroid(d)[0], arcMain.centroid(d)[1]], type, 'toOperator')
          } else if (d.data.name === 'Удалить') {
            vm.deleteElement()
          }
        }
      } else if (action === 'logicMenu') {
        clickCallback = function (d) {
          if (d.data.name === 'К оператору') {
            vm.drawList(counter, [arcMain.centroid(d)[0], arcMain.centroid(d)[1]], type, 'toOperator')
          } else if (d.data.name === 'Удалить') {
            vm.deleteElement()
          }
        }
      } else if (action === 'operatorMenu') {
        clickCallback = function (d) {
          if (d.data.name === 'К значению') {
            let valueType = vm.getValueType()
            vm.drawLink({name: valueType, element: 'value', nodeType: 'node'}, 'toValue')
          } else if (d.data.name === 'К логической функции') {
            vm.drawArcs(counter, type, 'addLogic', null)
          } else if (d.data.name === 'К атрибуту') {
            let valueType = vm.getValueType()
            vm.drawLink({name: 'attr' + valueType, element: 'attribute', nodeType: 'node'}, 'toAttribute')
          } else if (d.data.name === 'Удалить') {
            vm.deleteElement()
          }
        }
      } else if (action === 'valueMenu') {
        clickCallback = function (d) {
          if (d.data.name === 'К оператору') {
            vm.drawList(counter, [arcMain.centroid(d)[0], arcMain.centroid(d)[1]], type, 'toOperator')
          } else if (d.data.name === 'Редактировать') {
            vm.startEditValue(counter)
          } else if (d.data.name === 'Удалить') {
            vm.deleteElement()
          }
        }
      } else if (action === 'directionMenu') {
        clickCallback = function (d) {
          if (d.data.name === 'Назад') {
            vm.drawArcs(counter, type, 'linkMenu', null)
          } else if (d.data.name === 'Без направления') {
            if (vm.d3Controller.selected_link.directed) {
              vm.d3Controller.selected_link.directed = false
              vm.restart('')
              vm.drawArcs(counter, type, action, null)
            }
          } else if (d.data.name === 'Изменить направление') {
            if (!vm.d3Controller.selected_link.directed) {
              vm.d3Controller.selected_link.directed = true
            } else {
              let target = null
              let source = null

              for (let i = 0; i < vm.graph.links.length; i++) {
                if (vm.graph.links[i].index === vm.d3Controller.selected_link.index) {
                  for (let j = 0; j < vm.graph.nodes.length; j++) {
                    if (vm.graph.nodes[j].index === vm.d3Controller.selected_link.source.index) target = vm.graph.nodes[j]
                    if (vm.graph.nodes[j].index === vm.d3Controller.selected_link.target.index) source = vm.graph.nodes[j]
                  }
                  vm.graph.links[i].source = source
                  vm.graph.links[i].target = target
                  break
                }
              }
            }
            vm.restart('')
            vm.drawArcs(counter, type, action, null)
          }
        }
      } else if (action === 'addLink') {
        clickCallback = function (d) {
          if (d.data.name === 'Назад') {
            vm.drawArcs(counter, type, 'nodeMenu', null)
          } else {
            let links = vm.getAvaliableLinks(d.data.name)
            if (links.length > 2) {
              vm.createLinkObj.node.name = d.data.name
              vm.drawArcs(counter, type, 'linkChoice', links)
            } else {
              vm.drawLink({name: d.data.name, element: 'ontology', nodeType: type}, links[0].name)
            }
          }
        }
      } else if (action === 'linkChoice') {
        clickCallback = function (d) {
          if (d.data.name === 'Назад') {
            vm.drawArcs(counter, type, 'addLink', null)
          } else {
            vm.drawLink({name: vm.createLinkObj.node.name, element: 'ontology', nodeType: 'node'}, d.data.name)
          }
        }
      } else if (action === 'addAttr') {
        clickCallback = function (d) {
          if (d.data.name === 'Назад') {
            if (type === 'node') {
              vm.drawArcs(counter, type, 'nodeMenu', null)
            } else {
              vm.drawArcs(counter, type, 'linkMenu', null)
            }
          } else {
            vm.drawLink({
                  name: {name: d.data.name, type: d.data.type, isList: d.data.isList},
                  element: 'attribute',
                  nodeType: type
                },
                'toAttribute')
          }
        }
      } else if (action === 'addLogic') {
        clickCallback = function (d) {
          if (d.data.name === 'Назад') {
            vm.drawArcs(counter, type, 'operatorMenu', null)
          } else {
            vm.drawLink({name: d.data.name, element: 'logic', nodeType: type},
                'toLogic')
          }
        }
      }

      newBlock2.on('click', clickCallback)


      // ADD ICON
      if (action !== 'addLogic' && action !== 'addAttr' && action !== 'linkChoice') {
        this.foIcons = newBlock
            // .append('foreignObject')
            .insert('foreignObject', '#middleButton' + counter)
            .attr('width', '2em')
            .attr('height', '2em')
            .attr('x', (d) => arcMain.centroid(d)[0] - 12)
            .attr('y', (d) => arcMain.centroid(d)[1] - 14)
            .on('click', clickCallback)
        this.foIcons
            .append('xhtml:i')
            // .attr('class', () => 'fas fa-home')
            .attr('class', (d) => d.data.icon.name + ' fa-lg')
            .attr('color', 'white')
            .attr("xlink:href", (d, i) => "#arc-" + i)
            .attr('x', (d) => arcMain.centroid(d)[0] - 12)
            .attr('y', (d) => arcMain.centroid(d)[1] - 14)
      } else {
        this.foIcons = newBlock
            // .append('foreignObject')
            .insert('foreignObject', '#middleButton' + counter)
            .attr('width', (d) => {
              if (d.data.name === 'Назад') return '2em'
              return 0
            })
            .attr('height', (d) => {
              if (d.data.name === 'Назад') return '2em'
              return 0
            })
            .attr('x', (d) => arcMain.centroid(d)[0] - 12)
            .attr('y', (d) => arcMain.centroid(d)[1] - 14)
            .on('click', clickCallback)
        this.foIcons
            .append('xhtml:i')
            // .attr('class', () => 'fas fa-home')
            .attr('class', (d) => {
              if (d.data.name === 'Назад') return d.data.icon.name + ' fa-lg'
              return ''
            })
            .attr('color', 'white')
            .attr("xlink:href", (d, i) => "#arc-" + i)
            .attr('x', (d) => arcMain.centroid(d)[0] - 12)
            .attr('y', (d) => arcMain.centroid(d)[1] - 14)

        this.d3Controller.graphSelection.append('text')
            .attr('class', 'test-attr-text')
            .attr('font-size', '12px')
            .attr("fill", "white")
            .attr('font-weight', 'bold')
            .attr('text-anchor', 'middle')
            .attr('style', 'pointer-events: none;-webkit-touch-callout: none;-webkit-user-select: none;-khtml-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;')
            .attr('font-size', 12 + 'px')
            .text('');

        this.foIcons = newBlock
            // .append('foreignObject')
            .insert('g', '#middleButton' + counter)
            .attr(
                "transform", (d) => {
                  let tan = arcMain.centroid(d)[0] / arcMain.centroid(d)[1]
                  let angle = Math.atan(tan)
                  if (avaliableTypes.length > 5) {
                    tan = arcMain.centroid(d)[1] / arcMain.centroid(d)[0]
                    angle = -Math.atan(tan)
                  }
                  return `translate(${arcMain.centroid(d)[0]}, ${arcMain.centroid(d)[1]}) rotate(${-180 / Math.PI * angle})`
                }
            )
            .attr('x', (d) => arcMain.centroid(d)[0])
            .attr('y', (d) => arcMain.centroid(d)[1] - this.d3Controller.chartHeight / 2 - 10 - 2)
            .on('click', clickCallback)
        this.foIcons
            .append('text')
            .text((d) => {
              if (d.data.label !== this.$t('Назад')) {
                if (avaliableTypes.length > 5) {
                  let sizeTrue = false
                  let fontSize = 14
                  while (!sizeTrue) {
                    let text = vm.d3Controller.graphSelection.select('.test-attr-text').attr('font-size', fontSize).text(d.data.label)
                    let size = text.node().getBBox()
                    if (size.width > arcOuterRadius - arcInnerRadius - 4) {
                      fontSize--
                    } else {
                      sizeTrue = true
                      return d.data.label
                    }
                    if (fontSize === 10) {
                      for (let cutLen = 4; cutLen < d.data.label.length; cutLen++) {
                        let cutText = d.data.label.slice(0, d.data.label.length - cutLen) + '...'
                        let text = vm.d3Controller.graphSelection.select('.test-attr-text').attr('font-size', fontSize).text(cutText)
                        let size = text.node().getBBox()
                        if (size.width <= arcOuterRadius - arcInnerRadius - 4) {
                          return cutText
                        }
                      }
                    }
                  }
                } else {
                  let a = 2 * (arcOuterRadius + arcInnerRadius) / 2 * Math.sin(Math.PI / avaliableTypes.length)

                  if (avaliableTypes.length == 1) {
                    a = 2 * (arcOuterRadius + arcInnerRadius) / 2
                  }
                  let sizeTrue = false
                  let fontSize = 14
                  while (!sizeTrue) {
                    let text = vm.d3Controller.graphSelection.select('.test-attr-text').attr('font-size', fontSize).text(d.data.label)
                    let size = text.node().getBBox()
                    if (size.width > a - 6) {
                      fontSize--
                    } else {
                      sizeTrue = true
                      return d.data.label
                    }
                    if (fontSize === 10) {
                      for (let cutLen = 4; cutLen < d.data.label.length; cutLen++) {
                        let cutText = d.data.label.slice(0, d.data.label.length - cutLen) + '...'
                        let text = vm.d3Controller.graphSelection.select('.test-attr-text').attr('font-size', fontSize).text(cutText)
                        let size = text.node().getBBox()
                        if (size.width <= a - 6) {
                          return cutText
                        }
                      }
                    }
                  }

                }
                return d.data.label
              }
              return ''
            })
            // .attr('class', () => 'fas fa-home')
            .attr('font-size', (d) => {
              if (avaliableTypes.length > 5) {
                let sizeTrue = false
                let fontSize = 14
                while (!sizeTrue) {
                  let text = vm.d3Controller.graphSelection.select('.test-attr-text').attr('font-size', fontSize).text(d.data.label)
                  let size = text.node().getBBox()
                  if (size.width > arcOuterRadius - arcInnerRadius - 4) {
                    fontSize--
                  } else {
                    sizeTrue = true
                    return fontSize
                  }
                  if (fontSize === 10) {
                    return fontSize
                  }
                }
              } else {
                let a = 2 * (arcOuterRadius + arcInnerRadius) / 2 * Math.sin(Math.PI / avaliableTypes.length)

                if (avaliableTypes.length == 1) {
                  a = 2 * (arcOuterRadius + arcInnerRadius) / 2
                }
                let sizeTrue = false
                let fontSize = 14
                while (!sizeTrue) {
                  let text = vm.d3Controller.graphSelection.select('.test-attr-text').attr('font-size', fontSize).text(d.data.label)
                  let size = text.node().getBBox()
                  if (size.width > a - 6) {
                    fontSize--
                  } else {
                    sizeTrue = true
                    return fontSize
                  }
                  if (fontSize === 10) {
                    return fontSize
                  }
                }
              }
              return '12px'
            })
            .attr("fill", "white")
            .attr('x', 0)
            .attr('y', 0)
            .attr('font-weight', 'bold')
            .attr('text-anchor', 'middle')
        this.d3Controller.graphSelection.selectAll('.test-attr-text').remove()
      }


      setTimeout(function () {
        newBlock.on('mouseover', (d, i) => {
          let arc = vm.d3Controller.graphSelection.select('#arc-' + i)
          let group = vm.d3Controller.select(arc.node().parentNode)
          arc.style('opacity', '0.5')
          vm.d3Controller.graphSelection.append('text')
              .attr('class', 'test-attr-text')
              .attr("fill", "white")
              .attr('text-anchor', 'middle')
              .attr('style', 'pointer-events: none;-webkit-touch-callout: none;-webkit-user-select: none;-khtml-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;')
              .attr('font-size', 16)
              .text('');

          let textG = group.append('g')
              .attr('class', 'text-tip')
              .attr('transform', () => {
                let text = vm.d3Controller.graphSelection.select('.test-attr-text').attr('font-size', 16).text(d.data.label)
                let size = text.node().getBBox()
                let tan = arcMain.centroid(d)[1] / arcMain.centroid(d)[0]
                let angle = Math.atan(tan)
                let s = 1
                if (arcMain.centroid(d)[0] < 0) s = -s
                let x = 0
                let y = 0
                x = s * (arcOuterRadius / 2) * Math.cos(angle) + arcMain.centroid(d)[0] + (s - 1) * size.width / 2
                if (Math.cos(angle) > -0.001 && Math.cos(angle) < 0.001) {
                  x = arcMain.centroid(d)[0] + -size.width / 2
                }
                y = arcMain.centroid(d)[1] + (arcOuterRadius / 2) * Math.sin(s * angle) + size.height / 2
                return `translate(${x}, ${y})`
              })

          textG
              .append('rect')
              .attr('style', 'fill:white;stroke:black;stroke-width:2px')
              .attr('rx', 6)
              .attr('ry', 6)
              .attr('x', () => {
                return -4
              })
              .attr('y', -20)
              .attr('width', () => {
                let text = vm.d3Controller.graphSelection.select('.test-attr-text').attr('font-size', 16).text(d.data.label)
                let size = text.node().getBBox()
                return size.width + 8
              })
              .attr('height', () => {
                let text = vm.d3Controller.graphSelection.select('.test-attr-text').attr('font-size', 16).text(d.data.label)
                let size = text.node().getBBox()
                return size.height + 14
              })

          textG
              .append('text')
              // .text(arc.data()[0].data.name)
              .text((d) => {
                return d.data.label
              })
              .attr('font-size', 16)
              .attr('x', () => {
                return 0
              })
              .attr('y', () => {
                return 0
              })
          textG.raise()
          vm.d3Controller.graphSelection.selectAll('.test-attr-text').remove()
        })
        newBlock.on('mouseout', (d, i) => {
          let arc = vm.d3Controller.graphSelection.select('#arc-' + i)
          let group = vm.d3Controller.select(arc.node().parentNode)
          arc.style('opacity', '1')
          group.select('.text-tip').remove()
        })
      }, 50)

      if (this.graph.nodes.length < 2) { 
        vm.isCheckScheme = false;
      } else {
        vm.schemeController.checkScheme(this.graph)
        .then(()=> { 
          this.isCheckScheme = true
        })
        .catch(()=> {
              vm.isCheckScheme = false
        })
      }
    },
    createLink() {
      let counterOfSameLink = 0
      for (let l of this.graph.links) {
        if (l.target === this.d3Controller.selected_node && l.source === this.d3Controller.mousedown_node) counterOfSameLink++
        else if (l.source === this.d3Controller.selected_node && l.target === this.d3Controller.mousedown_node) counterOfSameLink++
      }
      let linkSource = this.d3Controller.selected_node
      let linkTarget = this.d3Controller.mousedown_node
      let exclusive = true
      if ('dxdy' in linkTarget) {
        if (Object.keys(linkTarget.dxdy).length !== 0) {
          linkTarget.part = 'in'
          linkTarget.dx = linkTarget.dxdy['in'][0]
          linkTarget.dy = linkTarget.dxdy['in'][1]
        }
      }
      let isLinked = false
      if (this.d3Controller.selected_node !== null &&
          !(this.d3Controller.selected_node.element === 'ontology' && this.d3Controller.mousedown_node.element === 'attribute')) {
        if (this.d3Controller.selected_node.element === 'ontology' && this.d3Controller.mousedown_node.element === 'ontology') {
          if (!(this.d3Controller.selected_node.name + ':' + this.d3Controller.mousedown_node.name in this.ontology.ruleList)) {
            linkSource = this.d3Controller.mousedown_node
            linkTarget = this.d3Controller.selected_node
          } else {
            let flag = false
            for (let l of this.ontology.ruleList[this.d3Controller.selected_node.name + ':' + this.d3Controller.mousedown_node.name]) {
              if (l.type === this.createLinkObj.link) {
                flag = true
                break
              }
            }
            if (!flag) {
              linkSource = this.d3Controller.mousedown_node
              linkTarget = this.d3Controller.selected_node
            }

            if (this.d3Controller.mousedown_node.name + ':' + this.d3Controller.selected_node.name in this.ontology.ruleList) {
              flag = false
              for (let l of this.ontology.ruleList[this.d3Controller.selected_node.name + ':' + this.d3Controller.mousedown_node.name]) {
                if (l.type === this.createLinkObj.link) {
                  flag = true
                  break
                }
              }
              if (flag) {
                exclusive = false
              }
            }

          }
        } else {
          if (!(this.d3Controller.selected_node.name + ':' + this.d3Controller.selected_node.part + ':'
              + this.d3Controller.mousedown_node.name + ':' + this.d3Controller.mousedown_node.part in basic.ruleList)) {
            linkSource = this.d3Controller.mousedown_node
            linkTarget = this.d3Controller.selected_node
            if (Object.keys(linkSource.dxdy).length !== 0) {
              linkSource.part = 'out'
              linkSource.dx = linkSource.dxdy['out'][0]
              linkSource.dy = linkSource.dxdy['out'][1]
            }
          }
        }

      }
      
      if (!isLinked) {
        this.linksCounter++
        this.d3Controller.setCounters(this.nodesCounter, this.linksCounter)
        // add link
        let link = {}
        if (this.createLinkObj.node.nodeType === 'link') {
          link = {
            element: 'link-attribute',
            name: 'toAttribute',
            short: 'r' + this.linksCounter,
            source: this.d3Controller.selected_link,
            sameLink: {current: 0, all: 0},
            target: this.d3Controller.mousedown_node,
            sourceD: {dx: 0, dy: 0},
            targetD: {dx: this.d3Controller.mousedown_node.dx, dy: this.d3Controller.mousedown_node.dy},
            sourcePart: null,
            targetPart: 'in',
            directed: exclusive,
            exclusive: exclusive,
            counter: this.linksCounter,
            label: 'toAttribute' + ' ' + this.linksCounter,
            linkType: 'common',
            form: {}
          }
        } else {
          link = {
            element: 'link',
            name: this.createLinkObj.link,
            sameLink: {current: counterOfSameLink, all: counterOfSameLink},
            short: 'r' + this.linksCounter,
            source: linkSource,
            target: linkTarget,
            sourceD: {dx: linkSource.dx, dy: linkSource.dy},
            targetD: {dx: linkTarget.dx, dy: linkTarget.dy},
            sourcePart: linkSource.part,
            targetPart: linkTarget.part,
            directed: exclusive,
            exclusive: exclusive,
            counter: this.linksCounter,
            label: this.createLinkObj.link + ' ' + this.linksCounter,
            linkType: 'common',
            form: {}
          }
        }
        this.graph.links.push(link)
        this.d3Controller.mousedown_node = null

        for (let l of this.graph.links) {
          if ((this.compareNodes(l.source, link.source) && this.compareNodes(l.target, link.target)) ||
              (this.compareNodes(l.source, link.target) && this.compareNodes(l.target, link.source))) {
            if (l !== link) {
              l.sameLink = {
                current: l.sameLink.current,
                all: counterOfSameLink
              }
            }
          }
        }

        // select new link
        this.$emit('select-element', {element: link, type: 'link'})
        this.d3Controller.resetMouseVars()
        this.d3Controller.selected_link = null
        this.d3Controller.selected_node = null
        this.sessionController.addLogStore('addLink', 'Добавление связи')
      }

      this.schemeController.checkScheme(this.graph)
        .then(()=> { 
            this.isCheckScheme = true
        })
        .catch(()=> {
            this.isCheckScheme = false
        })

      this.restart('addLink', null)
      //this.dataTypeViolationTest()
    },

    removeEditors() {
      let removed = this.d3Controller.graphSelection.select('#valueEditObject').remove()
      this.d3Controller.graphSelection.append(function () {
        return removed.node();
      });
      removed = this.d3Controller.graphSelection.select('#valueDateEditObject').remove()
      this.d3Controller.graphSelection.append(function () {
        return removed.node();
      });
      removed = this.d3Controller.graphSelection.select('#valueBoolEditObject').remove()
      this.d3Controller.graphSelection.append(function () {
        return removed.node();
      });
      removed = this.d3Controller.graphSelection.select('#valueListEditObject').remove()
      this.d3Controller.graphSelection.append(function () {
        return removed.node();
      });
    },

    removePies() {
      this.listMenu.show = false
      let removed = this.d3Controller.graphSelection.select('#listMenuObject').remove()
      this.d3Controller.graphSelection.append(function () {
        return removed.node();
      });
      this.d3Controller.graphSelection.select('#pies').remove()
    },

    restart(action) {
      const vm = this

      this.removePies()
      this.removeEditors()
      this.d3Controller.updateGraph(this.graph)


      this.d3Controller.removeElements('.node')
      this.d3Controller.removeElements('.link')
      this.d3Controller.removeElements('.marker')
      this.d3Controller.removeElements('.node-link')
      this.d3Controller.removeElements('.link-center')
      this.d3Controller.removeElements('.text-link')

      vm.d3Controller.nodeInMouseDown = function nodeInMouseDown(d) {
        //if (vm.d3Controller.event().defaultPrevented) return;
        if (vm.d3Controller.event().button === 0 && !vm.d3Controller.dragLineAction) {
          vm.$emit('select-element', {element: d, type: 'node'})
          vm.removePies()
          vm.d3Controller.selected_link = null
          if (vm.d3Controller.selected_node !== d) {
            vm.d3Controller.resetMouseVars()
            vm.d3Controller.selected_node = d
            vm.d3Controller.selected_node.dx = d.dxdy['in'][0]
            vm.d3Controller.selected_node.dy = d.dxdy['in'][1]
            vm.d3Controller.selected_node.part = 'in'
            vm.d3Controller.select(this).select('.strokeJoint').attr('style', 'pointer-events: fill; fill:#AC3B61;')
            if (d.element === 'attribute') vm.drawArcs(d.counter, 'node', 'attrMenu', null)
            else if (d.element === 'operator') vm.drawArcs(d.counter, 'node', 'operatorMenu', null)
            else if (d.element === 'value') vm.drawArcs(d.counter, 'node', 'valueMenu', null)
            else if (d.element === 'logic') vm.drawArcs(d.counter, 'node', 'logicMenu', null)
          } else if (vm.d3Controller.selected_node.part !== 'in') {
            vm.d3Controller.resetMouseVars()
            vm.d3Controller.selected_node = d
            vm.d3Controller.selected_node.dx = d.dxdy['in'][0]
            vm.d3Controller.selected_node.dy = d.dxdy['in'][1]
            vm.d3Controller.selected_node.part = 'in'
            vm.d3Controller.select(this).select('.strokeJoint').attr('style', 'pointer-events: fill; fill:#AC3B61;')
            if (d.element === 'attribute') vm.drawArcs(d.counter, 'node', 'attrMenu', null)
            else if (d.element === 'operator') vm.drawArcs(d.counter, 'node', 'operatorMenu', null)
            else if (d.element === 'value') vm.drawArcs(d.counter, 'node', 'valueMenu', null)
            else if (d.element === 'logic') vm.drawArcs(d.counter, 'node', 'logicMenu', null)
          } else {
            vm.d3Controller.resetMouseVars()
            vm.d3Controller.selected_node = null
          }
        } else if (vm.d3Controller.event().button === 0 && vm.d3Controller.dragLineAction) {
          let flag = true
          if (vm.createLinkObj.node.element === 'attribute' && d.name !== vm.createLinkObj.node.name) {
            flag = false
          }
          if (flag) {
            vm.d3Controller.mousedown_node = d
            vm.d3Controller.mousedown_node.dx = d.dxdy['in'][0]
            vm.d3Controller.mousedown_node.dy = d.dxdy['in'][1]
            vm.d3Controller.mousedown_node.part = 'in'
            vm.createLink()
            vm.d3Controller.mousedown_node = null
            vm.d3Controller.selected_node = null
            vm.d3Controller.selected_link = null
            vm.removePies()
            vm.d3Controller.dragLineAction = false
          }
        }
      }

      vm.d3Controller.nodeOutMouseDown = function nodeOutMouseDown(d) {
        //if (vm.d3Controller.event().defaultPrevented) return;
        if (vm.d3Controller.event().button === 0 && !vm.d3Controller.dragLineAction) {
          vm.$emit('select-element', {element: d, type: 'node'})
          vm.removePies()
          vm.d3Controller.selected_link = null
          if (vm.d3Controller.selected_node !== d) {
            vm.d3Controller.resetMouseVars()
            vm.d3Controller.selected_node = d
            vm.d3Controller.selected_node.dx = d.dxdy['out'][0]
            vm.d3Controller.selected_node.dy = d.dxdy['out'][1]
            vm.d3Controller.selected_node.part = 'out'
            vm.d3Controller.select(this).select('.strokeJoint').attr('style', 'pointer-events: fill; fill:#AC3B61;')
            if (d.element === 'attribute') vm.drawArcs(d.counter, 'node', 'attrMenu', null)
            else if (d.element === 'operator') vm.drawArcs(d.counter, 'node', 'operatorMenu', null)
            else if (d.element === 'value') vm.drawArcs(d.counter, 'node', 'valueMenu', null)
            else if (d.element === 'logic') vm.drawArcs(d.counter, 'node', 'logicMenu', null)
          } else if (vm.d3Controller.selected_node.part !== 'out') {
            vm.d3Controller.resetMouseVars()
            vm.d3Controller.selected_node = d
            vm.d3Controller.selected_node.dx = d.dxdy['out'][0]
            vm.d3Controller.selected_node.dy = d.dxdy['out'][1]
            vm.d3Controller.selected_node.part = 'out'
            vm.d3Controller.select(this).select('.strokeJoint').attr('style', 'pointer-events: fill; fill:#AC3B61;')
            if (d.element === 'attribute') vm.drawArcs(d.counter, 'node', 'attrMenu', null)
            else if (d.element === 'operator') vm.drawArcs(d.counter, 'node', 'operatorMenu', null)
            else if (d.element === 'value') vm.drawArcs(d.counter, 'node', 'valueMenu', null)
            else if (d.element === 'logic') vm.drawArcs(d.counter, 'node', 'logicMenu', null)
          } else {
            vm.d3Controller.resetMouseVars()
            vm.d3Controller.selected_node = null
          }
        } else if (vm.d3Controller.event().button === 0 && vm.d3Controller.dragLineAction) {
          vm.d3Controller.mousedown_node = d
          vm.d3Controller.mousedown_node.dx = d.dxdy['out'][0]
          vm.d3Controller.mousedown_node.dy = d.dxdy['out'][1]
          vm.d3Controller.mousedown_node.part = 'out'
          vm.createLink()
          vm.d3Controller.mousedown_node = null
          vm.d3Controller.selected_node = null
          vm.d3Controller.selected_link = null
          vm.removePies()
          vm.d3Controller.dragLineAction = false
        }
      }


      function linkMouseDown(d) {
        vm.d3Controller.resetMouseVars()
        vm.d3Controller.select('#link' + d.counter).attr('style', r => {
          if (r.linkType === 'negative') {
            return 'stroke:#AC3B61;stroke-width:6px;stroke-dasharray:0.5%;fill: none;'
          } else if (r.linkType === 'exclusive') {
            return 'stroke:#AC3B61;stroke-width:6px;fill: none;'
          } else {
            return 'stroke:#AC3B61;stroke-width:6px;fill: none;'
          }
        })
        const selected = d
        vm.d3Controller.graphSelection.selectAll('.marker').selectAll('polygon').attr('style', r => {
          if (!d.directed) return 'display: none;'
          if (r === selected) {
            if (r.linkType === 'negative') return 'fill:#AC3B61'
            else if (r.linkType === 'exclusive') return 'fill:#AC3B61'
            else return 'fill:#AC3B61'
          } else {
            if (r.linkType === 'negative') return 'fill:#550000'
            else if (r.linkType === 'exclusive') return 'fill:#999'
            else return 'fill:#999'
          }
        })
        vm.d3Controller.selected_node = null

        // vm.restart()
        if (vm.d3Controller.canBeEdited) {
          vm.$emit('select-element', {element: d, type: 'link'})
        }
        //vm.d3Controller.graphSelection.selectAll('.add-property').attr('style', 'display: none;')
        if (vm.d3Controller.event().button === 0 && !vm.d3Controller.dragLineAction) {
          vm.$emit('select-element', {element: d, type: 'link'})
          vm.removePies()
          vm.d3Controller.selected_node = null
          if (vm.d3Controller.selected_link !== d) {
            vm.d3Controller.selected_link = d
            vm.drawArcs(d.counter, 'link', 'linkMenu', null)
          } else {
            vm.d3Controller.resetMouseVars()
            vm.d3Controller.selected_link = null
          }

        }
      }

      function ontologyMouseDown(d) {
        //if (vm.d3Controller.event().defaultPrevented) return;
        vm.d3Controller.selected_link = null
        vm.removePies()
        vm.d3Controller.resetMouseVars()
        if (vm.d3Controller.event().button === 0 && !vm.d3Controller.dragLineAction) {
          vm.$emit('select-element', {element: d, type: 'node'})
          vm.removePies()
          vm.d3Controller.selected_link = null
          if (vm.d3Controller.selected_node !== d) {
            vm.d3Controller.selected_node = d
            if (d.name === null) vm.drawArcs(d.counter, 'node', 'changeNodeType', null)
            else vm.drawArcs(d.counter, 'node', 'nodeMenu', null)
          } else {
            vm.d3Controller.selected_node = null
          }
        }
        if (vm.d3Controller.event().button === 0 && vm.d3Controller.dragLineAction && d.name === vm.createLinkObj.node.name && vm.createLinkObj.node.element === 'ontology') {
          vm.d3Controller.mousedown_node = d
          vm.createLink()
          vm.d3Controller.mousedown_node = null
          vm.d3Controller.selected_node = null
          vm.d3Controller.selected_link = null
          vm.removePies()
          vm.d3Controller.dragLineAction = false
        }
      }

      this.d3Controller.createOntology(action, '#contextMenu', ontologyMouseDown, null)
      this.d3Controller.createValue(action, '#contextMenu', null, null)
      this.d3Controller.createAttribute(action, '#contextMenu', null, null)
      this.d3Controller.createOperator(action, '#contextMenu', null, null)
      this.d3Controller.createLogic(action, '#contextMenu', null, null)
      this.d3Controller.createFunction(action, '#contextMenu', null, null)
      this.d3Controller.createLinks(action, '.node', linkMouseDown, null, null, null)
      this.d3Controller.removeElements('#dragLine')
      this.d3Controller.createDragLine()

      this.d3Controller.enableDrag()


      if (this.d3Controller.event()) {
        // prevent browser's default behavior
        this.d3Controller.event().preventDefault()
      }

      this.d3Controller.restartSimulation()
      let counter = vm.graph.links.length + vm.graph.nodes.length + vm.graph.operators.length
          + vm.graph.functions.length + vm.graph.values.length + vm.graph.attributes.length
          + vm.graph.logics.length

      this.sessionController.addLogCurrentElementsStore(counter)
      this.$emit('restart', {action: action})
    },
    addNode() {
      // Prepare Data
      this.nodesCounter++
      this.d3Controller.setCounters(this.nodesCounter, this.linksCounter)
      this.sessionController.addLogStore('addNode', 'Добавление узла')

      this.graph.nodes.push({
        element: 'ontology',
        name: null,
        x: 200,
        y: 200,
        dxdy: {},
        dx: 0,
        label: '',
        dy: 0,
        part: null,
        counter: this.nodesCounter,
        short: '',
        neoID: -1,
        color: '',
      })

      this.restart('add')

       this.schemeController.checkScheme(this.graph)
        .then(()=> { 
            this.isCheckScheme = true
        })
        .catch(()=> {
            this.isCheckScheme = false
        })
    },
    placeForNewNode(nodeNumber, nPreviousNodes, angle, j) {
      if (angle > 2 * (Math.PI)) {
        let k = Math.floor(angle / (2 * Math.PI))
        angle = angle - (2 * Math.PI) * k
      }
      let translate = ''
      if (nodeNumber > 0 && nPreviousNodes > 0) {
        let lastNode = this.d3Controller.select('#g' + (nPreviousNodes))
        let previousNodes = this.d3Controller.graphSelection.selectAll('.nodes').nodes()
        // if j===-1 => take previous node. what is previous node? with highest id
        // else => take given node
        if (j === -1) {
          let ii = 0
          for (let i = 0; i < nPreviousNodes; i++) {
            if (previousNodes[i].id.replace('g', '') > ii) {
              ii = previousNodes[i].id.replace('g', '')
            }
          }
          lastNode = d3.select('#g' + ii)
        } else {
          lastNode = d3.select('#g' + j)
        }

        let pars0 = lastNode.attr('transform').split(',')[0].replace('translate(', '')
        let pars1 = lastNode.attr('transform').split(',')[1].replace(')', '').trim()
        let coefX = 150 * Math.sin(angle)
        let coefY = 150 * Math.cos(angle)
        if ((angle > 2 * Math.PI && angle <= 3 * Math.PI / 2)) {
          coefX = -coefX
        } else {
          coefY = -coefY
        }

        translate = `translate(${+pars0 + +coefX}, ${+pars1 + +coefY})`
      } else {
        this.x0Node = this.margin.left
        this.y0Node = this.margin.top
        translate = `translate(${this.x0Node}, ${(this.y0Node)})`
      }
      return translate
    }
  }
}
</script>

<style scoped>
@import url("~@fortawesome/fontawesome-free/css/all.css");

</style>
