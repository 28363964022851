<template>
    <div :style="{width: '100%', height: '100%'}">
        <el-card>
            <div style="display: flex">
                <div  v-for="item in predefine" :key="item" :class="item === modelValue ? 'color-picker-outer-selected' : 'color-picker-outer'" >
                    <div @click="handleChange(item)" :style="{'background-color': item, width: '100%', height: '100%'}">
                    </div>
                </div>
            </div>

        </el-card>
    </div>
</template>

<script>
    export default {
        name: 'ColorPicker',
        props: {
            predefine: {
                type: Array,
                required: true
            },
            modelValue: {
                type: String
            },
        },
        data() {
            return {
                color: ''
            };
        },
        mounted() {
            console.log('COLOR', this.modelValue)
        },
        methods: {
            handleChange(color) {
                this.color = color
                this.$emit('change', this.color)
                this.$emit('update:modelValue', this.color)
            }
        }
    };
</script>

<style scoped>
    label {
        display: block;
        margin-top: 10px;
    }

    >>> .el-card__body{
        padding: 10px;
    }

    .color-picker-outer{
        position: relative;
        display: block;
        box-sizing: border-box;
        border: 1px solid #aaa;
        border-radius: 2px;
        width: 16px;
        height: 16px;
        margin-right: 4px;
        margin-bottom: 4px;
        text-align: center;
    }

    .color-picker-outer-selected{
        position: relative;
        display: block;
        box-sizing: border-box;
        border: 3px solid #2287e4;
        border-radius: 2px;
        width: 16px;
        height: 16px;
        margin-right: 4px;
        margin-bottom: 4px;
        text-align: center;
    }

</style>
