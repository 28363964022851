<template>
  <div class="container">
    <el-input size="medium" pattern="^\$\d{1,3}(,\d{3})*(\.\d+)?$" :placeholder = "placeholderinput" style="width: 200px" v-model="valueInput" @input="$emit('input', $event)" />
  </div>
</template>

<script>
export default {
  name: "Input",
  props: ["label", "value", "options"],
  emits: ["input"],
  data () {
    return {
      valueInput: this.value,
      placeholderinput:''
    }
  },
  mounted() {
    this.placeholderinput = this.options
  }
};
</script>

<style scoped>
.container {
  /*display: flex;*/
  /*flex-direction: column;*/
  /*width: 20rem;*/
}
/*.container label {*/
/*  font-weight: 800;*/
/*}*/
/*.container input {*/
/*  height: 20px;*/
/*  padding: 10px;*/
/*  background: #f5f7f9;*/
/*  border-radius: 10px;*/
/*}*/
</style>
