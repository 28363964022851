<template>
  <div
    v-on:keyup.c="showAdminClearCache = !showAdminClearCache"
    class="hello"
    :style="{ display: 'block', width: 'auto', height: '100%', overflow: 'hidden', bottom: 0 }"
  >
    <div :style="{ 'z-index': 2001, position: 'absolute', right: 0, top: height / 2 + 'px' }">
      <el-button style="opacity: 0.7; padding: 18px 8px" @click="$emit('move', 'constructor')">
        <i class="fas fa-step-backward fa-lg"></i>
      </el-button>
    </div>

    <div v-loading="isLoadingConstructor" style="height: 100%">
      <div
        :style="{
          height: '42px',
          textAlign: 'left',
          backgroundColor: '#123c69',
          'overflow-x': 'hidden',
          float: 'left',
          width: '100%',
        }"
      >
        <el-col :style="{ marginLeft: '8px', width: '100%', marginTop: '6px' }">
          <el-button
            v-if="sizeConstructor <= 12"
            type="primary"
            size="mini"
            @click="resetConstructor"
          >
            <i class="el-icon-folder-add"></i>
          </el-button>
          <el-button v-else type="primary" size="mini" @click="resetConstructor">
            {{ $t('Новая схема') }}
          </el-button>
          <el-select
            v-model="selectSchemeToLoad"
            size="mini"
            id="valueListEdit"
            value-key="_id"
            @change="loadScheme"
            :no-match-text="$t('Ничего не найдено')"
            :placeholder="$t('Список схем')"
          >
            <el-option
              v-for="item in presentSchemes"
              :key="item._id"
              :label="item.name"
              :value="item"
              style="display: flex; justify-content: space-between; align-items: center"
            >
              <span style="width:90%" @click="loadScheme">{{ item.name }}</span>
              <i class="el-icon-delete" @click="showDeleteDialog(item._id)"></i>
            </el-option>
          </el-select>
          <el-input
            size="mini"
            :style="{
              width: (sizeConstructor > 12 ? 350 : 250) + 'px',
              'margin-left': '1%',
              'margin-right': '1%',
            }"
            :placeholder="$t('Название схемы')"
            v-model="currentSchemeName"
            :value="selectSchemeToLoad.name"
          ></el-input>
          <a v-if="sizeConstructor > 12">
            <el-button
              size="mini"
              v-if="selectSchemeToLoad._id && selectSchemeToLoad.name === currentSchemeName"
              type="primary"
              @click="saveScheme('completedSchemeForm')"
              >{{ $t('Сохранить схему') }}</el-button
            >
            <el-button v-else type="primary" size="mini" @click="addScheme('completedSchemeForm')"
              >{{ $t('Сохранить схему') }}</el-button
            >
          </a>
          <a v-else>
            <el-button
              size="mini"
              v-if="selectSchemeToLoad._id && selectSchemeToLoad.name === currentSchemeName"
              type="primary"
              @click="saveScheme('completedSchemeForm')"
              ><i class="fas fa-save"></i
            ></el-button>
            <el-button v-else size="mini" type="primary" @click="addScheme('completedSchemeForm')"
              ><i class="fas fa-save"></i
            ></el-button>
          </a>

          <a>
            <el-button v-if="showAdminClearCache" size="mini" type="primary" @click="clearCache"
              ><i class="fas fa-redo"></i
            ></el-button>
          </a>
          <!--          <el-button type="primary" size="mini">-->
          <!--            <i class="fas fa-question"></i>-->
          <!--          </el-button>-->
        </el-col>
      </div>
      <div :style="{ width: '100%', height: height + 'px', 'background-color': '#eee' }">
        <example-d3-pie
          ref="constructor"
          v-if="openScheme"
          :data="$data"
          @drop-cart-select="$emit('drop-cart-select')"
          @show-notification="showNotification($event)"
          @visualize-scheme="startVisualizator($event)"
          @restart="restartMaker($event)"
        ></example-d3-pie>
      </div>
    </div>

    <el-dialog
      :title="$t('Вы уверены, что хотите удалить схему')"
      v-model="dialogDeleteVisible"
      width="30%"
    >
      <span class="dialog-footer">
        <el-button @click="dialogDeleteVisible = false">{{ $t('Отмена') }}</el-button>
        <el-button type="success" @click="deleteScheme('completedSchemeForm')">{{ $t('Удалить') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ExampleD3Pie from './ExampleD3Pie';
import ConstructorController from '../../controllers/constructor.controller';
import SchemeController from '../../controllers/scheme.controller';
import basic from '../../store/basic';
import { ElNotification } from 'element-plus';
import CaseSessionController from '../../controllers/caseSession.controller';
import OntologyController from '../../controllers/ontology.controller';
import CashController from '../../controllers/cash.controller';

export default {
  name: 'constructor',
  components: {
    ExampleD3Pie,
  },
  data() {
    return {
      width: Math.max(document.documentElement.clientWidth, window.innerWidth || 0),
      height: Math.max(document.documentElement.clientHeight, window.innerHeight || 0) - 110 - 42,
      openScheme: true,
      showAdminClearCache: false,
      presentSchemes: [],
      selectSchemeToLoad: { _id: null, name: null },
      currentSchemeName: '',
      sizeConstructor: 12,
      ontology: {
        nodes: {},
        links: {},
        shortList: {},
      },
      basic: null,
      itemToDelete: 0,
      isLoadingConstructor: false,
      loadedSchemeID: -1,
      dialogDeleteVisible: false,
      schemeController: null,
      constructorController: null,
      notification: {
        schemeSaved: null,
        schemeDeleted: null,
        simpleError: null,
        cartElementAlready: null,
      },
      sessionController: null,
      ontologyController: null,
      cashController: null,
    };
  },
  watch: {},
  mounted() {
    const vm = this;
    this.ontologyController = new OntologyController();
    this.cashController = new CashController();
    this.ontology = this.ontologyController.getOntology();
    this.basic = basic;
    // for (let id in this.ontology.nodes) {
    //   let node = this.ontology.nodes[id]
    //   if (node.icon.length > 0) node.iconPath = `url(${require('../assets/' +  node.icon )})`
    //   else node.iconPath = ''
    // }
    this.schemeController = new SchemeController('constructor');
    this.schemeController.createScheme();
    this.constructorController = new ConstructorController();
    this.sessionController = new CaseSessionController();
    this.schemeController.loadAllSchemes().then(function(response) {
      // handle success
      vm.presentSchemes = response.data;
      vm.selectSchemeToLoad = { name: 'Схема' + '-' + (vm.presentSchemes.length + 1), _id: null };
      vm.currentSchemeName = 'Схема' + '-' + (vm.presentSchemes.length + 1);

      let cash = vm.cashController.getCashStore();
      // console.log('LOAD D3 CASH', cash.constructorS.scheme);
      if (cash.constructorS.scheme !== null) {
        vm.currentSchemeName = cash.constructorS.scheme.name;
        if (cash.constructorS.scheme._id !== null) {
          vm.selectSchemeToLoad = cash.constructorS.scheme;
        }
        vm.loadD3(cash.constructorS.scheme);
      }
    });
    this.notification.schemeSaved = () => {
      ElNotification({
        title: this.$t('Выполнено'),
        type: 'success',
        duration: 3000,
        position: 'bottom-right',
        message: this.$t('Схема сохранена'),
      });
    };

    this.notification.schemeDeleted = () => {
      ElNotification({
        title: this.$t('Выполнено'),
        type: 'success',
        duration: 3000,
        position: 'bottom-right',
        message: this.$t('Схема удалена'),
      });
    };

    this.notification.cartElementAlready = () => {
      ElNotification({
        title: this.$t('Ошибка'),
        type: 'warning',
        duration: 5000,
        position: 'bottom-right',
        message: this.$t('Уникальный элемент уже добавлен'),
      });
    };

    this.notification.simpleError = (obj) => {
      ElNotification({
        title: this.$t('Ошибка'),
        type: 'warning',
        duration: 5000,
        position: 'bottom-right',
        message: obj.text,
      });
    };
  },
  methods: {
    showNotification(obj) {
      this.notification[obj.name](obj.agrs);
    },
    showDeleteDialog(id) {
      this.dialogDeleteVisible = true;
      this.itemToDelete = id;
    },
    clearCache() {
      let cash = this.cashController.getCashStore();
      cash.constructorS.scheme = null;
      this.cashController.setCashStore(cash);
    },
    startVisualizator() {
      if (this.$refs.constructor.graph.nodes.length > 0)
        this.$emit('visualize-scheme', this.$refs.constructor.graph);
    },
    updateScheme() {
      let cash = this.cashController.getCashStore();
      cash.constructorS.scheme = this.$refs.constructor.graph;
      cash.constructorS.scheme._id = this.selectSchemeToLoad._id;
      cash.constructorS.scheme.name = this.selectSchemeToLoad.name;
      this.cashController.setCashStore(cash);
    },
    loadAllSchemes() {
      const vm = this;
      this.schemeController.loadAllSchemes().then(function(response) {
        vm.presentSchemes = response.data;
      });
    },
    loadD3(scheme) {
      const vm = this;
      this.isLoadingConstructor = true;
      let newNodes = scheme.nodes;
      let newAttributes = scheme.attributes;
      let newValues = scheme.values;
      let newOperators = scheme.operators;
      let newLogics = scheme.logics;
      let newFunctions = scheme.functions;
      let newLinks = scheme.links;
      let loadedLinks = this.schemeController.loadLinks(
        newLinks,
        newNodes,
        newAttributes,
        newValues,
        newOperators,
        newLogics,
        newFunctions,
      );
      scheme.links = loadedLinks;
      let maxLinkNumber = 0;
      let maxNodeNumber = 0;
      for (let node of newNodes) {
        if (node.counter > maxNodeNumber) maxNodeNumber = node.counter;
      }
      for (let link of newLinks) {
        if (link.counter > maxLinkNumber) maxLinkNumber = link.counter;
      }
      this.$refs.constructor.nodesCounter = maxNodeNumber + 1;
      this.$refs.constructor.linksCounter = maxLinkNumber + 1;

      this.$refs.constructor.loadGraph(scheme);
      this.$refs.constructor.init();
      this.$refs.constructor.restart('delete', null);
      for (let i = 0; i < this.$refs.constructor.graph.links.length; i++) {
        if (this.$refs.constructor.graph.links[i].element === 'link-attribute') {
          for (let j = 0; j < this.$refs.constructor.graph.links.length; j++) {
            if (
              this.$refs.constructor.graph.links[i].source.index ===
              this.$refs.constructor.graph.links[j].index
            ) {
              this.$refs.constructor.graph.links[i].source = this.$refs.constructor.graph.links[j];
            }
          }
        }
      }
      setTimeout(function() {
        vm.$refs.constructor.restart('delete', null);
        vm.isLoadingConstructor = false;
      }, 50);
    },
    loadScheme() {
      if (Object.keys(this.selectSchemeToLoad).length !== 0) {
        this.completedSchemeForm = {
          name: this.selectSchemeToLoad.name,
          query: this.selectSchemeToLoad.query,
        };
        this.currentSchemeName = this.selectSchemeToLoad.name;
        this.loadD3(this.selectSchemeToLoad);
        this.updateScheme();
      }
    },
    deleteScheme() {
      this.dialogDeleteVisible = false;
      this.isLoadingConstructor = true;
      const vm = this;
      vm.schemeController
        .deleteScheme({
          id: this.itemToDelete,
        })
        .then(function(response) {
          // handle success
          vm.isLoadingConstructor = false;
          if (response.status === 500) {
            if (response.data.error === 'Graph not connected') {
              this.showNotification({ name: 'simpleError', agrs: { text: this.$t('Ошибка удаления') } });
            }
          } else {
            vm.loadAllSchemes();
            vm.resetConstructor();
            vm.notification.schemeDeleted();
          }
        });
      this.itemToDelete = 0;
    },
    saveScheme() {
      this.isLoadingConstructor = true;
      const vm = this;
      let nodes = this.$refs.constructor.graph.nodes;
      if (nodes.length > 0) {
        console.log('SAVE SCHEME');
        console.log(vm.$refs.constructor.graph.nodes);

        vm.schemeController
          .changeScheme({
            scheme: {
              name: vm.currentSchemeName,
              nodes: vm.$refs.constructor.graph.nodes,
              attributes: vm.$refs.constructor.graph.attributes,
              values: vm.$refs.constructor.graph.values,
              operators: vm.$refs.constructor.graph.operators,
              logics: vm.$refs.constructor.graph.logics,
              functions: vm.$refs.constructor.graph.functions,
              links: vm.$refs.constructor.graph.links,
            },
            id: vm.selectSchemeToLoad._id,
          })
          .then((response) => {
            vm.isLoadingConstructor = false;
            if (response.status === 500) {
              if (response.data.error === 'Graph not connected') {
                this.showNotification({
                  name: 'simpleError',
                  agrs: { text: this.$t('Граф должен быть связанным') },
                });
              } else {
                this.showNotification({
                  name: 'simpleError',
                  agrs: { text: this.$t('Не верно составлен запрос') },
                });
              }
            } else {
              setTimeout(() => {
                vm.loadAllSchemes();
                vm.notification.schemeSaved();
                vm.openScheme = true;
                vm.updateScheme();
              }, 200);
            }
          });
      } else {
        vm.isLoadingConstructor = false;
        this.notification.simpleError({ text: this.$t('Схема пуста') });
      }
    },
    addScheme() {
      const vm = this;
      this.isLoadingConstructor = true;
      let nodes = this.$refs.constructor.graph.nodes;
      if (nodes.length > 0) {
        console.log('ADD SCHEME');
        console.log(vm.$refs.constructor.graph.nodes);

        vm.schemeController
          .addScheme({
            name: vm.currentSchemeName,
            nodes: vm.$refs.constructor.graph.nodes,
            attributes: vm.$refs.constructor.graph.attributes,
            values: vm.$refs.constructor.graph.values,
            operators: vm.$refs.constructor.graph.operators,
            logics: vm.$refs.constructor.graph.logics,
            functions: vm.$refs.constructor.graph.functions,
            links: vm.$refs.constructor.graph.links,
          })
          .then((response) => {
            vm.isLoadingConstructor = false;
            if (response.status === 500) {
              if (response.data.error === 'Graph not connected') {
                this.showNotification({
                  name: 'simpleError',
                  agrs: { text: this.$t('Граф должен быть связанным') },
                });
              } else {
                this.showNotification({
                  name: 'simpleError',
                  agrs: { text: this.$t('Не верно составлен запрос') },
                });
              }
            } else {
              vm.loadAllSchemes();
              vm.notification.schemeSaved();
              console.log('response.data', response.data);
              vm.selectSchemeToLoad = response.data.scheme;
              vm.openScheme = true;
              vm.updateScheme();
            }
          });
      } else {
        vm.isLoadingConstructor = false;
        this.notification.simpleError({ text: this.$t('Схема пуста') });
      }
    },
    resetConstructor() {
      this.schemeController.createScheme();
      this.dialogDeleteVisible = false;
      this.$refs.constructor.nodesCounter = 0;
      this.$refs.constructor.linksCounter = 0;
      this.$refs.constructor.graph.nodes = [];
      this.$refs.constructor.graph.attributes = [];
      this.$refs.constructor.graph.values = [];
      this.$refs.constructor.graph.operators = [];
      this.$refs.constructor.graph.logics = [];
      this.$refs.constructor.graph.links = [];
      this.selectSchemeToLoad = { _id: null, name: null };
      this.graphParts = {};
      this.openScheme = false;
      const vm = this;
      setTimeout(() => {
        vm.loadAllSchemes();
        vm.openScheme = true;
        vm.selectSchemeToLoad = { name: this.$t('Схема') + '-' + (vm.presentSchemes.length + 1), _id: null };
        vm.currentSchemeName = this.$t('Схема') + '-' + (vm.presentSchemes.length + 1);
        vm.updateScheme();
      }, 200);
    },
    restartMaker() {
      this.updateScheme();
    },
    cartElementSelected(element) {
      if (element !== null) this.addPick = {};
      this.$refs.constructor.cartElementSelected(element);
    },
  },
};
</script>

<style scoped>
.ml-1 {
  background: rgba(255, 255, 255, 1);
}
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

a {
  color: #42b983;
}

.card-header {
  font-size: 20px;
  font-weight: bold;
}

.bg-purple-dark {
  background: #99a9bf;
}

.bg-purple {
  background: #d3dce6;
}

.bg-purple-light {
  background: #e5e9f2;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.el-header {
  text-align: center;
}

.el-autocomplete-my {
  box-sizing: content-box;
  width: 100%;
}
.ic-type {
  width: 42px;
  height: 42px;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 0px;
  padding-top: 0;
}
.right-window {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18), 0 0 6px rgba(0, 0, 0, 0.07);
  height: 100%;
}

.cc-selector input {
  margin: 0;
  padding: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.cc-selector input:active + .drinkcard-cc {
  opacity: 0.9;
}

.cc-selector input:checked + .drinkcard-cc {
  -webkit-filter: none;
  -moz-filter: none;
  filter: none;
}

.cc-type-selector input:active + .typecard-cc {
  opacity: 1;
}

.cc-type-selector input:checked + .typecard-cc {
  -webkit-filter: none;
  -moz-filter: none;
  filter: none;
}

.typecard-cc {
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  -webkit-transition: all 100ms ease-in;
  -moz-transition: all 100ms ease-in;
  transition: all 100ms ease-in;
  -webkit-filter: brightness(1.2) grayscale(0.6) opacity(0.6);
  -moz-filter: brightness(1.2) grayscale(0.6) opacity(0.6);
  filter: brightness(1.2) grayscale(0.6) opacity(0.6);
}

.typecard-cc:hover {
  -webkit-filter: brightness(1) grayscale(0.1) opacity(1);
  -moz-filter: brightness(1) grayscale(0.1) opacity(1);
  filter: brightness(1) grayscale(0.1) opacity(1);
}
input[type='radio'] {
  visibility: hidden;
  height: 0;
  width: 0;
}

.operator-label {
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  margin-right: 0px;
  background-color: #21cb7a;
  color: white;
  width: 60px;
  padding: 4px 4px;
  border-radius: 3px;
}
</style>
