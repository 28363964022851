<template>
  <div class="root" >
    <div v-for="(paragraph, index) in typeSchema" :key="index" style="text-align: start; width: 100%; margin-bottom: 6px">
      <a v-for="(schema, index2) in paragraph" :key="index2" style="display: inline-block">
        <component
                :is="schema.type"
                :value="data[schema.state]"
                @input="updateData(schema.state, $event)"
                v-bind="schema"
                :data="data"
        >
        </component>
      </a>
    </div>
  </div>
</template>

<script>
import Input from "./Input.vue";
import SelectList from "./SelectList.vue";
import TextDisplay from "./TextDisplay.vue";
export default {
  name: "DynamicRenderer",
  emits: ["input"],
  props: {
    typeSchema: {
      type: Array,
    },
    data: {
      type: Object,
    },
  },
  components: {
    Input,
    SelectList,
    TextDisplay,
  },
  methods: {
    updateData(state, value) {
      this.$emit("input", { state, value: value });
    },
  },
};
</script>

<style scoped>
.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
}
</style>
