export default class Scheme {
    constructor(links, nodes, attributes, values, operators, logics, functions) {
        this.links = links;
        this.ontologyLinks = []
        for (let link of links.slice()) {
            if (link.source.element === 'ontology' && link.target.element === 'ontology') this.ontologyLinks.push(link)
        }
        this.nodes = nodes;
        this.attributes = attributes;
        this.values = values;
        this.operators = operators;
        this.logics = logics;
        this.functions = functions;
    }

    updateOntologyLinks() {
        this.ontologyLinks = []
        for (let link of this.links.slice()) {
            if (link.source.element === 'ontology' && link.target.element === 'ontology') this.ontologyLinks.push(link)
        }
    }

    copy () {
        let schemeCopy = new Scheme(this.links.slice(), this.nodes.slice(),
            this.attributes.slice(), this.values.slice(), this.operators.slice(), this.logics.slice())
        schemeCopy.sameFamilies = Object.assign({}, this.sameFamilies)
        schemeCopy.nodesCount = this.nodesCount.slice()
        schemeCopy.paths = this.paths.slice()
        schemeCopy.sortedPaths = this.sortedPaths.slice()
        schemeCopy.graphParts = Object.assign({}, this.graphParts)
        return schemeCopy
    }

    getNode (index) {
        for (let node of this.nodes) {
            if (node.index === index) {
                return node
            }
        }
    }
    getLink (index) {
        for (let link of this.ontologyLinks) {
            if (index === link.index) {
                return link
            }
        }
    }
}
