<template>
<!--    <constructor/>-->
    <div style="background-color: #6d7882; height: auto">
        <el-row>
            <el-col :span="constructorSize" :style="{'padding-right': constructorSize === 0 || constructorSize === 24 ? 0: 2 + 'px'}">
                <ConstructorPie v-loading="schemeCreateLoading" @drop-cart-select="$emit('drop-cart-select')" @visualize-scheme="startVisualizator" @move="move" ref="constructor"/>
            </el-col>
            <el-col :span="24 - constructorSize" :style="{'padding-left': constructorSize === 0 || constructorSize === 24 ? 0: 2 + 'px'}">
                <Visualization @add-to-cart="addToCart" @move="move" ref="visualizator"/>
            </el-col>
        </el-row>
    </div>



</template>
<script>
    import ConstructorPie from "./ConstructorPie";
    import Visualization from "./Visualization";
    import SchemeController from "../../controllers/scheme.controller";
    import CaseSessionController from "../../controllers/caseSession.controller";
    import CashController from "../../controllers/cash.controller";
    export default {
        components: {Visualization, ConstructorPie},
        data() {
            return {
                constructorSize: 12,
                schemeCreateLoading: false,
                schemeController: null,
                sessionController: null,
                cashController: null
            }
        },
        mounted() {
            this.schemeController = new SchemeController('constructor')
            this.cashController = new CashController()
            this.sessionController = new CaseSessionController()
        },
        methods: {
            cartElementSelected(element){
                this.$refs.constructor.cartElementSelected(element)
            },
            addToCart (element) {
                this.$emit('add-to-cart', element)
            },
            startVisualizator(graph){
                this.schemeCreateLoading = true
                const vm = this
                if (graph.nodes.length > 0) {
                    this.schemeController.visScheme(graph).then((response) => {
                        vm.schemeCreateLoading = false
                        let cash = vm.cashController.getCashStore()
                        cash.visualizator.scheme = response.data.scheme
                        vm.cashController.setCashStore(cash)
                        vm.$refs.visualizator.selectSchemeToLoad = response.data.scheme
                        vm.$refs.visualizator.startVis(true)
                    }).catch( (err) => {
                        console.log(err)
                        vm.schemeCreateLoading = false
                        if (err.response.data.error === 'Graph not connected') {
                            this.$refs.constructor.showNotification({name: 'simpleError',agrs: {text: this.$t('Граф должен быть связанным')}})
                        } else {
                            this.$refs.constructor.showNotification({name: 'simpleError', agrs: {text: this.$t('Не верно составлен запрос')}})
                        }
                    })
                } else {
                    this.$refs.constructor.showNotification({name: 'simpleError', agrs: {text: this.$t('Схема пуста')}})
                }
            },
            move(window){
                if (window === 'constructor' && this.constructorSize !== 0) {
                    this.constructorSize -= 12
                    this.$refs.constructor.sizeConstructor = this.constructorSize
                    this.$refs.visualizator.expandVis()
                } else if (window === 'vis' && this.constructorSize !== 24) {
                    this.constructorSize += 12
                    this.$refs.constructor.sizeConstructor = this.constructorSize
                }
            }
        }
    }
</script>

<style scoped>

</style>