<template>
  <div
    id="scheme-window"
    oncontextmenu="return false;"
    ref="window"
    :style="{  position: 'relative', left:0, right: 0, width: '100%', height: (height-2) + 'px', border: '1px solid #bbb' }"
  >
    <svg width="100%" height="100%"
         :style="{  position: 'absolute', left:0, right: 0, width: '100%', height: (height-2) + 'px'}">
      <g  id="gElement">
        <foreignObject  :style="menu" id="addToCart" >
          <div :style="menu" id="addToCart">
            <div @click="addToCart">
              <el-dropdown-item command="a">{{ $t('В избранное') }}</el-dropdown-item>
            </div>
          </div>
        </foreignObject>
      </g>
    </svg>
  </div>
</template>

<script>
import D3Controller from '../../controllers/d3.controller'
import OntologyController from "../../controllers/ontology.controller";
// import {Filter} from "sigma-webpack/plugins/sigma.plugins.filter/sigma.plugins.filter.js";


export default {
  name: 'scheme-window',
  props: {
    data: {}
  },
  data() {
    return {
      graph: {
        nodes: [],
        links: [],
        values: [],
        operators: [],
        attributes: [],
        logics: [],
        functions: []
      },
      forceProperties: {
        center: {
          x: 0.5,
          y: 0.5
        },
        charge: {
          enabled: true,
          strength: -200,
          distanceMin: 1,
          distanceMax: 2000
        },
        collide: {
          enabled: true,
          strength: 0.1,
          iterations: 1,
          radius: 35
        },
        forceX: {
          enabled: true,
          strength: 0.05,
          x: 0.5
        },
        forceY: {
          enabled: true,
          strength: 0.05,
          y: 0.5
        },
        link: {
          enabled: true,
          distance: 100,
          iterations: 1
        }
      },
      menu: {backgroundColor: 'white',display: 'none', height: 40 + 'px', width: (180)  + 'px','z-index':1000000},
      height: 400,
      nodesCounter: 0,
      linksCounter: 0,
      isLoading: false,
      instrument: 'cursor',
      ontology: null,
      pairSelect: false,
      firstNode: null,
      articleQuerySelect: false,
      currentNodeChoice: "1",
      controller: null,
      ontologyController: null
    }
  },
  computed: {
    nodes() {
      return this.graph.nodes
    },
    links() {
      return this.graph.links
    }
  },
  mounted: function () {
    this.controller = new D3Controller(this.$el.querySelector('svg'),  this.$refs.window.offsetWidth, this.height)
    this.ontologyController = new OntologyController()
    this.ontology = this.ontologyController.getOntology()
    this.controller.dragEnable = true
    this.controller.bindZoom([1 / 4, 4])
    this.init()
  },
  watch: {
  },
  methods: {
    init() {
      this.controller.bindSimulation(this.forceProperties)
      this.controller.setScale()
      this.controller.enableDrag()
    },
    loadGraph() {
      let scheme = this.data.schemeController.getSchemeStore()
      this.graph.links = scheme.links
      this.graph.nodes = scheme.nodes
      this.graph.attributes = scheme.attributes
      this.graph.values = scheme.values
      this.graph.operators = scheme.operators
      this.graph.logics = scheme.logics
      this.graph.functions = scheme.functions
    },
    // changeVGraph (vGraph) {
    //   console.log('vGraph', vGraph)
    //   this.currentNodeChoice = vGraph.currentNodeChoice
    //   this.resetMouseVars()
    //   if (vGraph.currentNodeChoice === "1") {
    //     if (vGraph.selectedNodes.first !== null) {
    //       this.controller.selectNode(vGraph.selectedNodes.first)
    //     }
    //   } else {
    //     if (vGraph.selectedNodes.second !== null) {
    //       this.controller.selectNode(vGraph.selectedNodes.second)
    //     }
    //   }
    // },
    addToCart () {
      this.$emit('add-to-cart')
    },
    restart (action) {

      this.controller.updateGraph(this.graph)

      this.controller.removeElements('.node')
      this.controller.removeElements('.link')
      this.controller.removeElements('.marker')
      this.controller.removeElements('.node-link')
      this.controller.removeElements('.text-link')

      function ontologyMouseDown(d) {
        console.log('CLICK')
        vm.resetMouseVars()
        vm.controller.select(this).select('.stroke').attr('style', d => {
          return 'pointer-events: fill; fill:' + vm.ontology.nodes[d.name].color + '; stroke:#AC3B61;stroke-width:6px'
        })
        if (vm.controller.event().button === 0) {
          // console.log('PARAMS ', vm.pairSelect, vm.articleQuerySelect)
          // vm.restart('')
          if (vm.data.currentVisualization === 'graph') {
            if (vm.currentNodeChoice === "1") {
              vm.$emit('select-element', {element: d, type: 'first'})
            } else {
              vm.$emit('select-element', {element: d, type: 'second'})
            }
          } else {
            vm.$emit('select-element', {element: d, type: 'node'})
          }
        } else if (vm.controller.event().button === 2) {
          vm.$emit('select-element', {element: d, type: 'node'})
          vm.menu.display = 'block'

          vm.controller.graphSelection.select('#addToCart')
                  .attr('x', d.x )
                  .attr('y', d.y )

        }
      }


      function linkMouseDown(d) {
        if (vm.data.currentVisualization !== 'graph') {
          const selected  = d
          if (vm.controller.event().button === 0) {
            vm.resetMouseVars()
            vm.controller.graphSelection.selectAll('.link').attr('style', r => {
              if (selected === r) {
                if (r.linkType === 'negative') {
                  return 'stroke:#AC3B61;stroke-width:6px;stroke-dasharray:0.5%;fill: none;'
                } else if (r.linkType === 'exclusive') {
                  return 'stroke:#AC3B61;stroke-width:6px;fill: none;'
                } else {
                  return 'stroke:#AC3B61;stroke-width:6px;fill: none;'
                }
              } else {
                if (r.linkType === 'negative') return 'stroke:#550000;stroke-width:6px;stroke-dasharray:0.5%;fill: none;'
                if (r.linkType === 'exclusive') return 'stroke:#000;stroke-width:6px;fill: none;'
                return 'stroke:#999;stroke-width:6px;fill: none;'
              }
            })
            vm.controller.graphSelection.selectAll('.marker').selectAll('polygon').attr('style', r => {
              if (!d.directed) return  'display: none;'
              if (r === selected) {
                if (r.linkType === 'negative') return 'fill:#AC3B61'
                else if (r.linkType === 'exclusive') return 'fill:#AC3B61'
                else return 'fill:#AC3B61'
              } else {
                if (r.linkType === 'negative') return 'fill:#550000'
                else if (r.linkType === 'exclusive') return 'fill:#999'
                else return 'fill:#999'
              }
            })
            vm.$emit('select-element', {element: d, type: 'link'})
          }
        }
      }

      const vm = this

      this.controller.createLinks(action, '#addToCart', linkMouseDown, null, null, null)
      this.controller.createOntology(action, '#addToCart', ontologyMouseDown, null)
      this.controller.createValue(action, '#addToCart', null, null)
      this.controller.createOperator(action, '#addToCart', null, null)
      this.controller.createAttribute(action, '#addToCart', null, null)
      this.controller.createLogic(action, '#addToCart', null, null)


      this.controller.enableDrag()


      // if (d3.event) {
      //   // prevent browser's default behavior
      //   d3.event.preventDefault()
      // }
      this.controller.setScale()
      this.controller.restartSimulation()
    },
    resetMouseVars () {
      this.controller.resetMouseVars()
      this.menu.display = 'none'
    },
  }
}
</script>
<style scoped src="../../assets/index.css"></style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  h1,
  h2 {
    font-weight: normal;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    display: inline-block;
    margin: 0 10px;
  }

  a {
    color: #42b983;
  }

  #container {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .cc-selector input {
    margin: 0;
    padding: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .cc-selector input:active + .drinkcard-cc {
    opacity: .9;
  }

  .cc-selector input:checked + .drinkcard-cc {
    -webkit-filter: none;
    -moz-filter: none;
    filter: none;
  }

  .ic {
    width: 32px;
    height: 32px;
    margin-top: 0;
    padding-top: 0;
    background-color: #27B8B2;
    border-radius: 25%;
  }

  .icon-cog {
    color: white;
    margin-top: 5px;
    margin-left: 0px;
  }

  .drinkcard-cc {
    cursor: pointer;
    margin-right: -14px;
    background-size: contain;
    background-repeat: no-repeat;
    display: inline-block;
    -webkit-transition: all 100ms ease-in;
    -moz-transition: all 100ms ease-in;
    transition: all 100ms ease-in;
    -webkit-filter: brightness(1.4) grayscale(.7) opacity(.8);
    -moz-filter: brightness(1.4) grayscale(.7) opacity(.8);
    filter: brightness(1.4) grayscale(.7) opacity(.8);
  }

  .drinkcard-cc:hover {
    -webkit-filter: brightness(1.1) grayscale(.5) opacity(.9);
    -moz-filter: brightness(1.1) grayscale(.5) opacity(.9);
    filter: brightness(1.1) grayscale(.5) opacity(.9);
  }

  #control-pane > div {
    margin: 10px;
    overflow-x: auto;
  }

  .drag_line {
    stroke: #999;
    stroke-width: 3;
    pointer-events: none;
  }

  .drag_line_hidden {
    stroke: #999;
    stroke-width: 0;
    pointer-events: none;
  }

  .link {
    stroke: #999;
    stroke-width: 3;
  }

  .link_selected {
    stroke: #ff7f0e;
  }

</style>
