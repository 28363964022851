<template>
  <div id="elementInfo" :style="cssProps">
    <el-card

            :class="element.class + ' card'"
            :id="element.name"
            @click="changeClass(element)"
            :style="{width: (width/6-24) + 'px', heightCart: heightCart + 'px'}">
      <template #header>
        <div class="clearfix">
          <i :class="icon + ' fa-lg'" style="color: white"></i>
          <span class="text" style="color: white; font-weight: bold">{{' : ' + element.name.split(' : ')[1]}}</span>
          <el-button @click="deleteElement(element)"
                     style="float: right; padding: 3px 0; color: white" type="text">
            ✕
          </el-button>
        </div>
      </template>

      <div class="text" v-if="element.type === 'NP'">
        <!-- {{element.text}} -->
        {{element.attributes['Имя']}}
        {{element.attributes['Имя (Name)']}}
      </div>
      <div class="text" v-else-if="element.type === 'LE'">
        <!-- {{element.text}} -->
        {{element.attributes['Наименование']}}
        {{element.attributes['Наименование (Name)']}}
      </div>
      <div class="text" v-else-if="element.type === 'Contract'">
        <!-- {{element.text}} -->
        {{element.attributes['Регистрационный_номер']}}
        {{element.attributes['Регистрационный номер (Registration number)']}}
      </div>
      <div class="text" v-else-if="element.type === 'Concurs'">
        <!-- {{element.text}} -->
        {{element.attributes['Регистрационный_номер']}}
        {{element.attributes['Регистрационный номер (Registration number)']}}
      </div>
      <div class="text" v-else-if="element.type === 'Subsidy'">
        <!-- {{element.text}} -->
        {{element.attributes['Регистрационный_номер']}}
        {{element.attributes['Регистрационный номер (Registration number)']}}
      </div>
      <div class="text" v-else>
        <!-- {{element.text}} -->
        {{element.attributes['Наименование']}}
        {{element.attributes['Наименование (Name)']}}
      </div>
    </el-card>

  </div>
</template>

<script>

import { ElCard } from 'element-plus'
// import { ElLoading } from 'element-plus'
import OntologyController from "../../controllers/ontology.controller";

export default {
  props: {
    element: {
      type: String,
      default: function () {
        return null
      }
    },
    icon: {
      type: String,
      default: function () {
        return null
      }
    },
  },
  name: 'ElementInfo',
  components: {
    ElCard
  },
    data () {
    return {
      width: Math.max(
              document.documentElement.clientWidth,
              window.innerWidth || 0
      ),
      heightCart: 100,
      backColor: "",
      ontology: null,
      visController: null,
      ontologyController: null
    }
  },
  mounted() {
    this.ontologyController = new OntologyController()
    this.ontology = this.ontologyController.getOntology()
    this.backColor = this.ontology.nodes[this.element.name.split(' : ')[0]].color
  },
  computed: {
    cssProps() {
      return {
        '--back-node-color': this.backColor
      }
    }
  },
  methods: {
    deleteElement(element) {
      this.$emit('delete', element)
    },
    changeClass(element){
      this.$emit('change', element)
    }
  }
}
</script>
<style scoped src="../../assets/index.css"></style>
<style scoped>
  >>> .card .el-card__header {
    padding: 10px 12px;
    background-color: var(--back-node-color);
  }

  >>> .card .el-card__body {
    padding: 12px
  }

  .element-cart-card-selected {
    box-shadow: 0 0 10px rgba(14, 33, 82, 0.5); /* Параметры тени */
    padding: 10px;
  }

  .element-cart-card {
  }

  .text {
    font-size: 14px;
  }

</style>
